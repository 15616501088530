@mixin custom-hint-text($color, $align) {
    color: $color;
    text-align: $align;
}

@mixin custom-hint($rotate) {
    position: absolute;
    top: unset;
    bottom: unset;
    left: unset;
    right: unset;
    transform: $rotate;
}

  .removed-storm-tooltip {
    text-align: center !important;
    width: 50%;
  }

.widget-hint-overflow {
    overflow: hidden;
}

#dialogBackground {
    background: rgba(0, 0, 0, 0.65);
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 11;
    height: 100%;

    .dialog-background-area {
        height: 100%;
    }
}

#hintOverlay {
    background: rgba(0, 0, 0, 0.65);
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 99999;
    visibility: hidden;

    .hintOverlayArea {
        height: 100%;

        .customer-edit-svg:after {
            content: url('./../images/svgIcon/customer_edit_white.svg');
        }

        .location-header-svg:after {
            content: url('./../images/svgIcon/location_header_white.svg');
        }
    }
}

.no-background-overlay {
    background: inherit !important;
}

.topNavOverlay,
.autoReviewOverlay,
.homePageSecondTabOverlay,
.homePageThirdTabOverlay,
.dataCommunationOverlay,
.dataEditingAuditOverlay,
.batteryStatusOverlay,
.batteryStatusCollapsedOverlay,
.alarmsWidgetOverlay,
.eventsWidgetOverlay,
.alarmsWidgetCollapsedOverlay,
.usersPageOverlay,
.dailyDialogOverlay,
.auditLogOverlay,
.editUserDetailsOverlay,
.editUserRoleOverlay,
.editUserCustomerOverlay,
.editUserLocationOverlay,
.editUserFeaturesOverlay,
.homePageFourthTabOverlay,
.leaderBoardTileOverlay,
.locationOverlay,
.locationSecondOverlay,
.dynamicdbOverlay,
.vaultOverlay,
.vaultSecondOverlay,
.vaultSecondListOverlay,
.vaultThirdOverlay,
.vaultDialogOverlay,
.dailySummaryOverviewOverlay,
.dailySummaryDetailOverlay,
.percentFullOverlay,
.uptimeOverviewOverlay,
.uptimeDetailOverlay,
.adminScreenOverlay,
.adminDetailsOverlay,
.location-dashBoard-edit-hint-overlay,
.location-dashBoard-edit-sg-hint-overlay,
.de-accept-dialog-overlay,
.view-location-details-screen-overlay,
.view-location-associated-entity-screen-overlay,
.view-location-confirmation-points-screen-overlay,
.view-location-files-screen-overlay,
.view-location-notes-screen-overlay,
.view-location-advanced-screen-overlay,
.schedule-dashboard-screen-overlay,
.schedule-dashboard-hint-overlay,
.schedule-dialog-hint-overlay,
.schedule-dialog-screen-overlay,
.block-editor-hint-overlay,
.flowMonitoringReportOverlay,
.view-location-flow-balance-screen-overlay,
.DataCommCollapsedOverlay,
.DataCommExpandedOverlay,
.notificationDashBoardOverlay,
.nd-common-locations,
.nd-common-alarms,
.nd-ANSR-alarms,
.nd-alarms-confirm,
.nd-daily-report,
.nd-notification-tree,
.customDashboardHintOverlay,
.customDashboardDialogHinrOverlay,
.nd-validation,
.customerEditorOverlay,
.edit-cus-details-overlay,
.edit-cus-features-overlay,
.edit-cus-users-overlay,
.edit-cus-locations-overlay,
.edit-cus-locaitons-filters-overlay,
.edit-cus-ftp-overlay,
.edit-cus-ftp-add-overlay,
.edit-cus-gis-overlay,
.edit-cus-gis-basic-overlay,
.edit-cus-gis-custom-overlay,
.edit-cus-rainfall-dry-day-overlay,
.edit-cus-rainfall-storm-event-overlay,
.edit-cus-rainfall-infiltration-overlay,
.edit-cus-rainfall-ddf-data-overlay,
.edit-cus-rainfall-ddf-graph-overlay,
.view-location-flow-balance-screen-overlay,
.view-location-flow-balance-screen-overlay,
.hintRollBack {
    display: none;
}

.hide {
    display: none !important;
}

@mixin location-details-mixin() {
    #ViewDataLocationDetails {
        .details-page {
            .mat-tab-group {
                pointer-events: none;

                .mat-tab-header {
                    position: relative;
                    z-index: 99999;
                    opacity: 1;
                    border: 0;

                    .mat-tab-list {
                        background: transparent !important;
                    }

                    .mat-tab-labels {
                        .mat-tab-label {
                            border-bottom: 0px;
                        }
                    }
                }

                .mat-tab-body.mat-tab-body-active {
                    z-index: unset;
                }
            }
        }
    }
}

.showTopHint {
    .showHintText {
        z-index: 99;
    }

    .data-communication-hint {
        .data-comm-filter-hint {
            z-index: 99999;
            position: relative;
            padding: 5px;
            background-color: #fff;
            border-radius: 5px;
            pointer-events: none;
        }

        .data-comm-full-hint {
            z-index: 99999;
            position: relative;
            padding: 5px;
            background-color: #fff;
            border-radius: 5px;
            pointer-events: none;
        }

        .data-comm-close-hint {
            z-index: 99999;
            position: relative;
            padding: 5px;
            background-color: #fff;
            border-radius: 5px;
            pointer-events: none;
        }

        .data-comm-title-hint {
            z-index: 99999;
            position: relative;
            padding: 5px;
            background-color: #fff;
            border-radius: 5px;
            pointer-events: none;
        }

        .data-comm-headers-hint {
            position: relative;
        }

        .data-comm-failed-hint {
            position: relative;
        }

        .data-comm-attempted-hint {
            position: relative;
        }

        .data-comm-item-hint {
            position: relative;
        }

        .data-comm-schedule-hint {
            position: relative;
        }

        .data-comm-collect-hint {
            position: relative;
        }

        .data-comm-date-hint {
            position: relative;
        }

        .data-comm-series-hint {
            position: relative;
        }

        .data-comm-locations-hint {
            position: relative;
        }

        .data-comm-footer-schedule-hint {
            position: relative;
        }

        .data-comm-footer-download-hint {
            position: relative;
        }

        .dataCommunationOverlay {
            display: block;
        }
    }

    .battery-status-hint {
        .battery-title-hint {
            position: relative;
        }

        .battery-filter-hint {
            position: relative;
        }

        .battery-fullscreen-hint {
            position: relative;
        }

        .battery-close-hint {
            position: relative;
        }

        .battery-locations-hint {
            position: relative;
        }

        .battery-download-hint {
            position: relative;
        }

        .battery-buy-hint {
            position: relative;
        }

        .battery-filter-status-hint {
            position: relative;
        }

        .battery-locations-filter-hint {
            position: relative;
        }

        .batteryStatusOverlay {
            display: block;
        }
    }

    .homePageIconHint {
        .customer-selector-hint {
            z-index: 99999;
            position: relative;
            padding: 0 5px;
            background-color: #fff;
            pointer-events: none;
        }

        .location-group-hint {
            z-index: 99999;
            position: relative;
            background-color: #fff;
            pointer-events: none;
        }

        .active-inactive-hint {
            z-index: 99999;
            position: relative;
            padding: 5px;
            color: #000 !important;
            opacity: 1 !important;
            background-color: #fff;
            border-radius: 5px;
            pointer-events: none;
        }

        .notification-hint {
            z-index: 99999;
            position: relative;
            padding: 5px;
            color: #000 !important;
            opacity: 1 !important;
            background-color: #fff;
            border-radius: 5px;
            pointer-events: none;
        }

        .user-hint {
            z-index: 99999;
            position: relative;
            padding: 5px;
            background-color: #fff;
            border-radius: 5px;
            pointer-events: none;
        }

        .pin-hint {
            z-index: 99999;
            position: relative;
            padding: 5px;
            background-color: #fff;
            border-radius: 5px;
            pointer-events: none;
        }

        .widgets-hint {
            z-index: 99999;
            position: relative;
            padding: 5px;
            background-color: #fff;
            border-radius: 5px;
            pointer-events: none;
        }

        .hintIcon {
            color: #fff;
            z-index: 99999;
            opacity: 1;
        }

        #addNewLocation {
            z-index: 99999 !important;
        }

        .googleMapRecenter {
            z-index: 99999 !important;
        }

        .locationSearch {
            color: #fff;
            z-index: 99999;
            position: relative;
            opacity: 1;
        }

        .blockage-filter-hint,
        .blockage-close-hint,
        .blockage-location-hint,
        .blockage-date-hint,
        .blockage-status-hint,
        .blockage-dtrend-hint,
        .blockage-filter-start-hint,
        .blockage-filter-end-hint,
        .blockage-filter-locations-hint,
        .blockage-filter-status-hint,
        .blockage-paginator-hint {
            position: relative;
        }

        .event-widget-hint,
        .add-event-hint,
        .event-filters-hint,
        .event-full-hint,
        .event-full-close-hint,
        .event-close-hint,
        .event-description-hint,
        .event-type-hint,
        .event-locations-hint,
        .event-started-at-hint,
        .event-duration-hint,
        .event-gis-hint,
        .event-edit-hint,
        .event-delete-hint,
        .event-filter-locations-hint,
        .event-filter-date-hint,
        .event-filter-type-hint,
        .event-filter-duration-hint,
        .event-paginator-hint {
            position: relative;
        }

        .ar-filter-hint,
        .ar-full-hint,
        .ar-full-close-hint,
        .ar-close-hint,
        .ar-start-hint,
        .ar-end-hint,
        .ar-locations-hint,
        .ar-reasons-hint,
        .ar-lc-hint,
        .ar-date-hint,
        .ar-status-hint,
        .ar-error-hint,
        .ar-reason-hint,
        .ar-graph-hint,
        .ar-paginator-hint {
            position: relative;
        }

        .topNavOverlay {
            display: block;
        }
    }

    .edit-cus-details {

        .edit-cus-name-hint,
        .edit-cus-short-name-hint,
        .edit-cus-ttl-hint,
        .edit-cus-units-hint,
        .edit-cus-date-format-hint,
        .edit-cus-time-format-hint,
        .edit-cus-timezone-hint,
        .edit-cus-work-order-hint,
        .edit-cus-idle-time-hint,
        .edit-cus-daylight-hint,
        .edit-cus-con-num-hint,
        .edit-cus-subs-hint,
        .edit-cus-orig-start-hint,
        .edit-cus-curr-start-hint,
        .edit-cus-curr-end-hint,
        .edit-cus-da-hint,
        .edit-cus-reg-man-hint,
        .edit-cus-sales-hint,
        .edit-cus-pm-hint,
        .edit-cus-inactive-on-hint,
        .edit-cus-rough-hint,
        .edit-cus-details-add-hint,
        .edit-cus-save-hint {
            position: relative;
        }

        .edit-cus-details-overlay {
            display: block;
        }
    }

    .edit-cus-features {

        .edit-cus-feats-hint,
        .edit-cus-feats-apply-hint {
            position: relative;
        }

        .edit-cus-features-overlay {
            display: block;
        }
    }

    .edit-cus-users {

        .edit-cus-users-search-hint,
        .edit-cus-users-assigned-hint,
        .edit-cus-users-check-hint,
        .edit-cus-users-username-hint,
        .edit-cus-users-email-hint,
        .edit-cus-users-name-hint,
        .edit-cus-users-role-hint,
        .edit-cus-users-login-hint,
        .edit-cus-users-feats-hint,
        .edit-cus-users-status-hint,
        .edit-cus-users-edit-hint,
        .edit-cus-users-add-hint,
        .edit-cus-users-paginator-hint,
        .edit-cus-users-save-hint {
            position: relative;
        }

        .edit-cus-users-overlay {
            display: block;
        }
    }

    .edit-cus-locations {

        .edit-cus-locs-search-hint,
        .edit-cus-locs-filter-hint,
        .edit-cus-locs-name-hint,
        .edit-cus-locs-series-hint,
        .edit-cus-locs-serial-hint,
        .edit-cus-locs-desc-hint,
        .edit-cus-locs-sensors-hint,
        .edit-cus-locs-group-hint,
        .edit-cus-locs-status-hint,
        .edit-cus-locs-activate-hint,
        .edit-cus-locs-composite-hint,
        .edit-cus-locs-wdo-hint,
        .edit-cus-locs-edit-hint,
        .edit-cus-locs-add-hint {
            position: relative;
        }

        .edit-cus-locations-overlay {
            display: block;
        }
    }

    .edit-cus-locaitons-filters {

        .edit-cus-filters-series-hint,
        .edit-cus-filters-group-hint,
        .edit-cus-filters-status-hint,
        .edit-cus-filters-sensors-hint,
        .edit-cus-filters-wdo-hint,
        .edit-cus-filters-composite-hint {
            position: relative;
        }

        .edit-cus-locaitons-filters-overlay {
            display: block;
        }
    }

    .edit-cus-ftp {

        .edit-cus-ftp-table-hint,
        .edit-cus-ftp-add-hint {
            position: relative;
        }

        .edit-cus-ftp-overlay {
            display: block;
        }
    }

    .edit-cus-ftp-add {
        .edit-cus-ftp-add-overlay {
            display: block;
        }
    }

    .edit-cus-gis {

        .edit-cus-gis-edit-hint,
        .edit-cus-gis-add-hint {
            position: relative;
        }

        .edit-cus-gis-overlay {
            display: block;
        }
    }

    .edit-cus-gis-basic {

        .edit-cus-gis-type-hint,
        .edit-cus-gis-mapping-hint,
        .edit-cus-gis-auth-hint,
        .edit-cus-gis-username-hint,
        .edit-cus-gis-password-hint,
        .edit-cus-gis-token-hint {
            position: relative;
        }

        .edit-cus-gis-basic-overlay {
            display: block;
        }
    }

    .edit-cus-gis-custom {

        .edit-cus-gis-type-hint,
        .edit-cus-gis-mapping-hint,
        .edit-cus-gis-auth-hint,
        .edit-cus-gis-params-hint,
        .edit-cus-gis-token-hint {
            position: relative;
        }

        .edit-cus-gis-custom-overlay {
            display: block;
        }
    }

    .edit-cus-rainfall-dry-day {

        .edit-cus-dry-tab-hint,
        .edit-cus-dry-one-hint,
        .edit-cus-dry-three-hint,
        .edit-cus-dry-five-hint,
        .edit-cus-dry-min-hint,
        .edit-cus-dry-max-hint {
            position: relative;
        }

        .edit-cus-rainfall-dry-day-overlay {
            display: block;
        }
    }

    .edit-cus-rainfall-storm-event {

        .edit-cus-storm-tab-hint,
        .edit-cus-storm-rain-hint,
        .edit-cus-storm-storm-hint {
            position: relative;
        }

        .edit-cus-rainfall-storm-event-overlay {
            display: block;
        }
    }

    .edit-cus-rainfall-infiltration {
        .edit-cus-base-method-hint {
            position: relative;
        }

        .edit-cus-rainfall-infiltration-overlay {
            display: block;
        }
    }

    .edit-cus-rainfall-ddf-data {

        .edit-cus-ddf-data-tab-hint,
        .edit-cus-ddf-data-upload-hint,
        .edit-cus-ddf-data-table-hint,
        .edit-cus-ddf-data-add-hint,
        .edit-cus-ddf-data-delete-hint,
        .edit-cus-ddf-data-graph-hint {
            position: relative;
        }

        .edit-cus-rainfall-ddf-data-overlay {
            display: block;
        }
    }

    .edit-cus-rainfall-ddf-graph {

        .edit-cus-ddf-graph-tab-hint,
        .edit-cus-ddf-graph-tooltip-hint,
        .edit-cus-ddf-graph-chart-hint {
            position: relative;
        }

        .edit-cus-rainfall-ddf-graph-overlay {
            display: block;
        }
    }

    .customer-editor-hint {

        .cus-filter-hint,
        .cus-filter-feature-hint,
        .cus-filter-inactive-hint,
        .cus-search-hint,
        .cus-name-hint,
        .cus-locs-hint,
        .cus-inactive-locs-hint,
        .cus-feats-hint,
        .cus-inactive-hint,
        .cus-edit-hint,
        .cus-add-hint,
        .cus-paginator-hint {
            position: relative;
        }

        .customerEditorOverlay {
            display: block;
        }
    }

    .auto-review-widget-hint {

        .ar-filter-hint,
        .ar-full-hint,
        .ar-full-close-hint,
        .ar-close-hint,
        .ar-start-hint,
        .ar-end-hint,
        .ar-locations-hint,
        .ar-reasons-hint,
        .ar-lc-hint,
        .ar-date-hint,
        .ar-status-hint,
        .ar-error-hint,
        .ar-reason-hint,
        .ar-graph-hint,
        .ar-paginator-hint {
            position: relative;
        }

        .autoReviewOverlay {
            display: block;
        }
    }

    .homePageSecondTab {
        .more-options-hint {
            position: relative;
        }

        .more-options-menu-hint {
            display: flex !important;
            width: 200px;
            background: #fff;
            position: absolute;
            align-items: baseline;
            padding: 8px 0;
            border-radius: 5px;
            flex-direction: column;

            button {
                width: 100% !important;
                font-weight: 400 !important;
                text-align: left !important;
                font-size: 14px !important;
            }
        }

        .layers-hint {
            z-index: 99999;
            position: relative;
            padding: 5px;
            background-color: #fff;
            border-radius: 5px;
            pointer-events: none;
        }

        .basemap-hint {
            z-index: 99999;
            position: relative;
            background-color: #fff;
            border-radius: 5px;
            pointer-events: none;
        }

        .add-monitor-hint {
            z-index: 99999;
            position: relative;
            background-color: #fff;
            border-radius: 5px;
            pointer-events: none;
        }

        .monitor-hint {
            z-index: 99999;
            position: relative;
            padding: 5px;
            background-color: #fff;
            border-radius: 5px;
            pointer-events: none;
        }

        .show-legend-hint {
            z-index: 99999;
            position: relative;
            background-color: #fff;
            border-radius: 5px;
            padding: 5px;
            pointer-events: none;
        }

        .feat-select-hint {
            z-index: 99999;
            position: relative;
            padding: 5px;
            background-color: #fff;
            border-radius: 5px;
            pointer-events: none;
        }

        .zoom-in-hint {
            z-index: 99999;
            position: relative;
            background-color: #fff;
            border-radius: 5px;
            pointer-events: none;
        }

        .transparency-hint {
            z-index: 99999;
            position: relative;
            background-color: #fff;
            border-radius: 5px;
            pointer-events: none;
        }

        .homePageSecondTabOverlay {
            display: block;
        }
    }

    .homePageThirdTab {
        .selection-menu-mock {
            display: flex !important;
            width: 200px;
            background: #fff;
            position: absolute;
            align-items: baseline;
            padding: 8px 0;
            border-radius: 5px;
            flex-direction: column;

            button {
                width: 100% !important;
                font-weight: 400 !important;
                text-align: left !important;
                font-size: 14px !important;
            }
        }

        .measure-menu-mock {
            display: flex !important;
            width: 200px;
            padding: 8px 0;
            border-radius: 5px;
            background: #fff;
            position: absolute;
            align-items: baseline;
            flex-direction: column;

            button {
                width: 100% !important;
                padding: 0 16px;
                height: 48px;
                border: none;
                background: #fff;
                display: flex;
                align-items: center;
                line-height: 48px;
                font-weight: 400 !important;
                text-align: left !important;
                font-size: 14px !important;

                mat-icon {
                    color: rgba(0, 0, 0, 0.54);
                    height: 24px;
                    width: 24px;
                }

                span {
                    margin-left: 10px;
                    line-height: 48px;
                }
            }
        }

        .select-feat-hint {
            z-index: 99999;
            position: relative;
        }

        .select-poly-hint {
            z-index: 99999;
            position: relative;
        }

        .clear-tool-selection-hint {
            position: relative;
        }

        .select-radius-hint {
            z-index: 99999;
            position: relative;
        }

        .measure-area-hint {
            z-index: 99999;
            position: relative;
        }

        .measure-length-hint {
            z-index: 99999;
            position: relative;
        }

        .measure-clear-hint {
            z-index: 99999;
            position: relative;
        }

        .tools-hint {
            z-index: 99999;
            position: relative;
            padding: 5px;
            background-color: #fff;
            border-radius: 5px;
            pointer-events: none;
        }

        .map-overview-hint {
            z-index: 99999;
            position: relative;
        }

        .map-scale-hint {
            z-index: 99999;
            position: relative;
        }

        .search-loc-hint {
            z-index: 99999;
            position: relative;
        }

        .homePageThirdTabOverlay {
            display: block;
        }
    }

    .homePageFourthTab {
        .edit-mon-hint {
            z-index: 99999;
            position: relative;
            padding: 5px;
            background-color: #fff;
            border-radius: 5px;
            pointer-events: none;
        }

        .child-search-hint {
            z-index: 99999;
            position: relative;
            padding: 5px;
            background-color: #fff;
            border-radius: 5px;
            pointer-events: none;
        }

        .monitor-name-hint {
            z-index: 99999;
            position: relative;
            padding: 5px;
            background-color: #fff;
            border-radius: 5px;
            pointer-events: none;
        }

        .add-mon-hint {
            z-index: 99999;
            position: relative;
            padding: 5px;
            background-color: #fff;
            border-radius: 5px;
            pointer-events: none;
        }

        .mon-search-hint {
            z-index: 99999;
            position: relative;
            padding: 5px;
            background-color: #fff;
            border-radius: 5px;
            pointer-events: none;
        }

        .homePageFourthTabOverlay {
            display: block;
        }
    }

    .hidden-hint {
        visibility: hidden;
    }

    .visible-hint {
        visibility: visible;
    }

    .homePageTileHint {
        #leaderBoardContainer {
            z-index: 99999 !important;
            position: relative;
        }

        .leaderBoardTileOverlay {
            display: block;
        }
    }

    .locationDashboardHint {

        .ld-series-hint,
        .ld-search-hint,
        .ld-date-hint,
        .ld-entity-hint,
        .ld-work-hint,
        .ld-approve-hint,
        .ld-edit-hint,
        .ld-filter-hint,
        .ld-options-hint,
        .ld-entity-hg-hint,
        .ld-entity-sg-hint,
        .ld-date-select-hint,
        .ld-average-hint,
        .ld-selected-hint,
        .ld-location-hint,
        .ld-rain-hint {
            position: relative;
        }

        .locationOverlay {
            display: block;
        }
    }

    .locationDashboardSecondHint {

        .hg-filter-hint,
        .hg-new-tab-hint,
        .hg-new-tab-close-hint,
        .hg-full-hint,
        .sg-full-hint,
        .ld-data-table-hint,
        .sg-select-hint,
        .sg-clear-hint,
        .sg-zoom-hint,
        .sg-flag-hint,
        .sg-unflag-hint,
        .sg-block-hint,
        .sg-snap-hint,
        .sg-curve-hint,
        .sg-tolerance-hint,
        .sg-tol-select-hint,
        .sg-generation-hint,
        .sg-sync-hint,
        .highcharts-range-selector-group,
        .highcharts-legend,
        .highcharts-navigator {
            position: relative;
        }

        .locationSecondOverlay {
            display: block;
        }
    }

    .audit-log-hint {
        .audit-title-hint {
            position: relative;
            background-color: #fff;
            z-index: 99999;
            padding: 5px;
            border-radius: 5px;
            pointer-events: none;
        }

        .audit-download-hint {
            position: relative;
            background-color: #fff;
            z-index: 99999;
            padding: 5px;
            border-radius: 5px;
            pointer-events: none;
        }

        .audit-filter-hint {
            position: relative;
            background-color: #fff;
            z-index: 99999;
            padding: 5px 0 5px 5px;
            border-radius: 5px;
            pointer-events: none;
        }

        .audit-search-hint {
            position: relative;
            background-color: #fff;
            z-index: 99999;
            border-radius: 5px;
            pointer-events: none;
        }

        .audit-date-hint {
            position: relative;
            background-color: #fff;
            z-index: 99999;
            border-radius: 5px;
            padding: 0 5px;
            pointer-events: none;
        }

        .audit-locations-hint {
            position: relative;
            background-color: #fff;
            z-index: 99999;
            border-radius: 5px;
            margin-left: 10px;
            padding: 0 5px;
            pointer-events: none;
        }

        .audit-loc-group-hint {
            position: relative;
            background-color: #fff;
            z-index: 99999;
            border-radius: 5px;
            padding: 0 5px;
            pointer-events: none;
        }

        .audit-item-hint {
            position: relative;
        }

        .auditLogOverlay {
            display: block;
        }
    }

    .location-dashBoard-edit-hint {

        .de-topbar-hint,
        .de-hg-entity-hint,
        .de-hg-zoom-hint,
        .de-hg-drag-hint,
        .de-hg-flag-hint,
        .de-hg-clear-hint,
        .de-hg-multiple-hint,
        .de-hg-copy-hint,
        .de-hg-interpolate-hint,
        .hg-filter-hint,
        .hg-new-tab-hint,
        .hg-new-tab-close-hint,
        .hg-full-hint {
            position: relative;
        }

        .location-dashBoard-edit-hint-overlay {
            display: block;
        }
    }

    .location-dashBoard-edit-hint_rollback {
        .hintRollBack {
            position: relative;
            color: #fff;
            display: block;

            .roll-back-hint-arrow {
                @include custom-hint(rotate(180deg) scaleX(1));
            }

            .roll-back-hint-text {
                @include custom-hint(unset);
                @include custom-hint-text(#fff,
                    left);
            }

            .range-hint-arrow {
                @include custom-hint(rotate(180deg) scaleX(1));
            }

            .range-hint-text {
                @include custom-hint(unset);
                @include custom-hint-text(#fff,
                    left);
            }

            .check-hint-arrow {
                @include custom-hint(rotate(180deg) scaleX(1));
            }

            .check-hint-text {
                @include custom-hint(unset);
                @include custom-hint-text(#fff,
                    left);
            }

            .timestamp-hint-arrow {
                @include custom-hint(rotate(180deg) scaleX(-1));
            }

            .timestamp-hint-text {
                @include custom-hint(unset);
                @include custom-hint-text(#fff,
                    left);
            }

            .depth-hint-arrow {
                @include custom-hint(rotate(0deg) scaleX(1));
            }

            .depth-hint-text {
                @include custom-hint(unset);
                @include custom-hint-text(#fff,
                    left);
            }

            .velocity-hint-arrow {
                @include custom-hint(rotate(180deg) scaleX(1));
            }

            .velocity-hint-text {
                @include custom-hint(unset);
                @include custom-hint-text(#fff,
                    left);
            }

            .quantity-hint-arrow {
                @include custom-hint(rotate(0deg) scaleX(-1));
            }

            .quantity-hint-text {
                @include custom-hint(unset);
                @include custom-hint-text(#fff,
                    left);
            }

            .rain-hint-arrow {
                @include custom-hint(rotate(180deg) scaleX(1));
            }

            .rain-hint-text {
                @include custom-hint(unset);
                @include custom-hint-text(#fff,
                    left);
            }

            .performed-hint-arrow {
                @include custom-hint(rotate(180deg) scaleX(1));
            }

            .performed-hint-text {
                @include custom-hint(unset);
                @include custom-hint-text(#fff,
                    left);
            }

            .comments-hint-arrow {
                @include custom-hint(rotate(180deg) scaleX(1));
            }

            .comments-hint-text {
                @include custom-hint(unset);
                @include custom-hint-text(#fff,
                    left);
            }

            .paging-hint-arrow {
                @include custom-hint(rotate(0deg) scaleX(-1));
            }

            .paging-hint-text {
                @include custom-hint(unset);
                @include custom-hint-text(#fff,
                    left);
            }

            .close-hint-arrow {
                @include custom-hint(rotate(0deg) scaleX(-1));
            }

            .close-hint-text {
                @include custom-hint(unset);
                @include custom-hint-text(#fff,
                    left);
            }

            .revert-hint-arrow {
                @include custom-hint(rotate(0deg) scaleX(1));
            }

            .revert-hint-text {
                @include custom-hint(unset);
                @include custom-hint-text(#fff,
                    left);
            }
        }
    }

    .scheduleDashboardHint {
        .schedule-title-hint {
            z-index: 99999;
            position: relative;
            background-color: #fff;
            pointer-events: none;
            padding: 5px;
            border-radius: 5px;
        }

        .add-schedule-hint {
            z-index: 99999;
            position: relative;
            background-color: #fff;
            pointer-events: none;
        }

        .filter-hint {
            z-index: 99999;
            position: relative;
            background-color: #fff !important;
            pointer-events: none;
            padding: 5px;
            opacity: 1 !important;
            border-radius: 5px;
        }

        .name-filter-hint {
            z-index: 99999;
            position: relative;
            background-color: #fff;
            pointer-events: none;
            padding: 5px;
            border-radius: 5px;
        }

        .week-days-hint {
            z-index: 99999;
            position: relative;
            background-color: #fff;
            pointer-events: none;
            padding: 5px;
            border-radius: 5px;
        }

        .frequency-hint {
            z-index: 99999;
            position: relative;
            background-color: #fff;
            pointer-events: none;
            padding: 5px;
            border-radius: 5px;
        }

        .edit-schedule-hint {
            z-index: 99999;
            position: relative !important;
            background-color: #fff;
            pointer-events: none;
            padding: 5px;
            border-radius: 5px;
        }

        .delete-hint {
            z-index: 99999;
            position: relative;
            background-color: #fff;
            pointer-events: none;
            padding: 5px;
            border-radius: 5px;
        }

        .schedule-dashboard-hint-overlay {
            display: block;
        }
    }

    .blockEditorDialogHint {
        .block-editor-hint-overlay {
            display: block;
        }
    }

    .scheduleDialogHint {
        .schedule-dialog-hint-overlay {
            display: block;
        }
    }

    .location-dashBoard-edit-sg-hint {

        .sg-full-hint,
        .sg-select-hint,
        .sg-clear-hint,
        .sg-zoom-hint,
        .sg-flag-hint,
        .sg-unflag-hint,
        .sg-block-hint,
        .sg-snap-hint,
        .sg-curve-hint,
        .sg-tolerance-hint,
        .sg-tol-select-hint,
        .sg-generation-hint,
        .sg-sync-hint,
        .highcharts-range-selector-group,
        .cancel-button-hint,
        .accept-button-hint {
            position: relative;
        }

        .location-dashBoard-edit-sg-hint-overlay {
            display: block;
        }
    }

    .de-dialog-hint {

        .de-dialog-entities-hint,
        .de-dialog-reason-hint,
        .de-dialog-comment-hint,
        .de-dialog-approve-hint,
        .de-dialog-cancel-hint,
        .de-dialog-accept-hint {
            position: relative;
        }

        .de-accept-dialog-overlay {
            display: block;
        }
    }

    .custom-dashboard-dialog-hint {
        .customDashboardDialogHinrOverlay {
            display: block;
        }
    }

    .custom-dashboard-hint {

        .cd-print-hint,
        .cd-add-hint,
        .cd-full-hint,
        .cd-edit-hint,
        .cd-delete-hint {
            position: relative;
        }

        .customDashboardHintOverlay {
            display: block;
        }
    }

    .edit-user-details {

        .activate-user-hint,
        .save-user-hint {
            background-color: #fff;
            position: relative;
        }

        .active-hint {
            position: relative;
        }

        .userDetailsHint {
            z-index: 99999;
            position: relative;
        }

        .activate-user-hint {
            padding: 5px;
        }

        .save-user-hint {
            color: #000;
            padding: 5px;
        }

        .editUserDetailsOverlay {
            display: block;
        }
    }

    .edit-user-role {
        .assignRolesUserHint {
            z-index: 99999;
            position: relative;
        }

        .roles-hint {
            z-index: 99999;
            padding: 5px;
            border-radius: 5px;
            background-color: #fff;
            position: relative;
        }

        .editUserRoleOverlay {
            display: block;
        }
    }

    .edit-user-customer {
        .assignCustomersUserHint {
            z-index: 99999;
            position: relative;
        }

        .customer-search-hint,
        .customer-list-hint {
            z-index: 99999;
            border-radius: 5px;
            background-color: #fff;
            position: relative;
        }

        .customer-list-hint {
            padding: 10px 5px;
        }

        .editUserCustomerOverlay {
            display: block;
        }
    }

    .edit-user-location {
        .select-all-hint {
            z-index: 99999;
            border-radius: 5px;
            background-color: #fff;
            position: relative;
            padding: 5px;
        }

        .customer-select-hint {
            z-index: 99999;
            border-radius: 5px;
            background-color: #fff;
            position: relative;
        }

        .location-list-hint {
            z-index: 99999;
            border-radius: 5px;
            background-color: #fff;
            position: relative;
            padding: 0 10px;
        }

        .assignLocationsUserHint {
            z-index: 99999;
            position: relative;
        }

        .editUserLocationOverlay {
            display: block;
        }
    }

    .edit-user-features {
        .assignFeaturesUserHint {
            z-index: 99999;
            position: relative;
        }

        .features-hint {
            z-index: 99999;
            border-radius: 5px;
            background-color: #fff;
            position: relative;
            padding: 5px 10px;
        }

        .editUserFeaturesOverlay {
            display: block;
        }
    }

    .users-page-hint {
        .search-users-hint {
            z-index: 99999;
            position: relative;
            background-color: #fff;
            border-radius: 5px;
            pointer-events: none;
        }

        .user-status-hint {
            z-index: 99999;
            position: relative;
            padding: 5px;
            background-color: #fff;
            border-radius: 5px;
            pointer-events: none;
        }

        .user-edit-hint {
            z-index: 99999;
            position: relative;
            background-color: #fff;
            border-radius: 5px;
            padding: 5px;
            pointer-events: none;
        }

        .user-add-hint {
            z-index: 99999;
            position: relative;
            pointer-events: none;
        }

        .usersPageOverlay {
            display: block;
        }
    }

    .view-location-details-hint {

        .edit-button-hint,
        .collect-button-hint,
        .schedule-collect-button-hint {
            z-index: 99999;
            position: relative;
            background-color: #fff;
            pointer-events: none;
        }

        .export-button-hint {
            z-index: 99999;
            position: relative;
            background-color: #fff;
            pointer-events: none;
        }

        .percent-full-hint,
        .daily-summary-hint,
        .uptime-hint,
        .details-hint {
            z-index: 99999;
            position: relative;
            background-color: #fff;
            padding: 5px;
            border-radius: 5px;
            color: rgba(0, 0, 0, 0.87);
            pointer-events: none;
        }

        @include location-details-mixin();

        .view-location-details-screen-overlay {
            display: block;
        }
    }

    .view-location-associated-entity-hint {

        .copy-entity-hint,
        .add-entity-hint {
            z-index: 99999;
            position: relative;
            background-color: #fff;
            pointer-events: none;
        }

        .ansr-edit-hint,
        .ansr-delete-hint,
        .ansr-hint {
            z-index: 99999;
            position: relative;
            background-color: #fff;
            padding: 5px;
            border-radius: 5px;
            color: rgba(0, 0, 0, 0.87);
            pointer-events: none;
        }

        @include location-details-mixin();

        .view-location-associated-entity-screen-overlay {
            display: block;
        }
    }

    .view-location-confirmation-points-hint {

        .add-confirmation-hint,
        .confirmation-edit-hint,
        .confirmation-delete-hint,
        .download-confirmation-hint {
            z-index: 99999;
            position: relative;
            border-radius: 5px;
            background-color: #fff;
            pointer-events: none;
        }

        .ansr-edit-hint,
        .ansr-delete-hint,
        .confirmation-hint {
            z-index: 99999;
            position: relative;
            background-color: #fff;
            padding: 5px;
            border-radius: 5px;
            color: rgba(0, 0, 0, 0.87);
            pointer-events: none;
        }

        @include location-details-mixin();

        .view-location-confirmation-points-screen-overlay {
            display: block;
        }
    }

    .view-location-files-hint {
        .show-hide-subfolders-hint .mat-expansion-indicator {
            z-index: 99999;
            position: relative;
            background-color: #fff;
            pointer-events: none;
            padding: 5px;
            border-radius: 5px;
        }

        .create-folder-hint,
        .file-share-hint,
        .file-download-hint,
        .file-import-hint,
        .file-delete-hint,
        .upload-file-hint {
            z-index: 99999;
            border-radius: 5px;
            position: relative;
            background-color: #fff;
            pointer-events: none;
            opacity: 1 !important;
        }

        .files-hint {
            z-index: 99999;
            position: relative;
            background-color: #fff;
            padding: 5px;
            border-radius: 5px;
            color: rgba(0, 0, 0, 0.87);
            pointer-events: none;
        }

        @include location-details-mixin();

        .view-location-files-screen-overlay {
            display: block;
        }
    }

    .view-location-advanced-hint {
        .advanced-hint {
            z-index: 99999;
            position: relative;
            background-color: #fff;
            padding: 5px;
            border-radius: 5px;
            color: rgba(0, 0, 0, 0.87);
            pointer-events: none;
        }

        .advanced-filter-hint {
            z-index: 99999;
            position: relative;
            background-color: #fff;
            border-radius: 5px;
            color: rgba(0, 0, 0, 0.87);
            pointer-events: none;
        }

        .diagnostics-hint {
            z-index: 99999;
            position: relative;
            background-color: #fff;
            border-radius: 5px;
            color: rgba(0, 0, 0, 0.87);
            pointer-events: none;
        }

        .date-picker-hint {
            z-index: 99999;
            position: relative;
            background-color: #fff;
            padding: 5px !important;
            border-radius: 5px !important;
            color: rgba(0, 0, 0, 0.87);
            pointer-events: none;
        }

        .date-time-hint {
            z-index: 99999;
            position: relative;
            background-color: #fff;
            padding: 5px;
            border-radius: 5px;
            color: rgba(0, 0, 0, 0.87);
            pointer-events: none;
        }

        .user-name-hint {
            z-index: 99999;
            position: relative;
            background-color: #fff;
            padding: 5px;
            border-radius: 5px;
            color: rgba(0, 0, 0, 0.87);
            pointer-events: none;
        }

        .result-hint {
            z-index: 99999;
            position: relative;
            background-color: #fff;
            padding: 5px;
            border-radius: 5px;
            color: rgba(0, 0, 0, 0.87);
            pointer-events: none;
        }

        @include location-details-mixin();

        .view-location-advanced-screen-overlay {
            display: block;
        }
    }

    .view-location-notes-hint {

        .add-note-hint,
        .filter-hint {
            z-index: 99999;
            position: relative;
            background-color: #fff;
            pointer-events: none;
        }

        .ansr-edit-hint,
        .ansr-delete-hint,
        .notes-hint {
            z-index: 99999;
            position: relative;
            background-color: #fff;
            padding: 5px;
            border-radius: 5px;
            color: rgba(0, 0, 0, 0.87);
            pointer-events: none;
        }

        .filter-hint {
            border-radius: 5px;
            padding: 3px 0 3px 6px;
            pointer-events: none;
        }

        @include location-details-mixin();

        .view-location-notes-screen-overlay {
            display: block;
        }
    }

    .view-location-flow-balance-hint {

        .generate-button-hint,
        .flow-balance-edit-hint {
            z-index: 99999;
            position: relative;
            background-color: #fff;
            pointer-events: none;
        }

        .flow-balance-hint {
            z-index: 99999;
            position: relative;
            background-color: #fff;
            padding: 5px;
            border-radius: 5px;
            color: rgba(0, 0, 0, 0.87);
            pointer-events: none;
        }

        @include location-details-mixin();

        .view-location-flow-balance-screen-overlay {
            display: block;
        }
    }

    .viewDataDynamicHint {
        #location-dashboard {
            .addNewGraph {
                z-index: 999;
            }

            .addNewGraphContainer {
                display: none;
            }
        }

        .homePageAction {
            right: 100px;
        }

        #addDynamicDb {
            z-index: 99999;
            position: relative;
        }

        .dynamicdbOverlay {
            display: block;
        }
    }

    .vaultSecondHint,
    .vaultSecondListHint {

        .vault-toggle-hint,
        .vault-top-move-hint,
        .vault-top-delete-hint,
        .vault-top-download-hint,
        .vault-top-import-hint,
        .vault-file-check-hint,
        .vault-file-icon-hint,
        .vault-file-share-hint,
        .vault-file-download-hint,
        .vault-file-import-hint,
        .vault-file-delete-hint {
            position: relative;
        }
    }

    .vaultSecondHint {
        .vaultSecondOverlay {
            display: block;
        }
    }

    .vaultSecondListHint {
        .vaultSecondListOverlay {
            display: block;
        }
    }

    .vaultThirdHint {

        .vault-upload-hint,
        .vault-export-hint {
            position: relative;
        }

        .upload-menu-mock,
        .export-menu-mock {
            display: flex !important;
            width: 150px;
            background: #fff;
            position: absolute;
            align-items: baseline;
            padding: 8px 0;
            border-radius: 5px;
            flex-direction: column;

            button {
                width: 100% !important;
                font-weight: 400 !important;
                text-align: left !important;
                font-size: 14px !important;
            }
        }

        .export-menu-mock {
            button {
                border-bottom: 1px solid #bbb;
            }

            button:last-child {
                border: none;
            }
        }

        .vault-upload-file-hint,
        .vault-upload-location-hint,
        .vault-upload-data-hint,
        .vault-export-new-hint,
        .vault-export-load-hint,
        .vault-export-recent-hint {
            position: relative;
        }

        .vaultThirdOverlay {
            display: block;
        }
    }

    .vaultDialogHint {
        .vaultDialogOverlay {
            display: block;
        }
    }

    .vaultPageHint {

        .vault-folders-hint,
        .vault-hide-folders-hint,
        .vault-breadcrumb-hint,
        .vault-search-hint,
        .vault-create-hint,
        .vault-hide-sub-hint,
        .vault-list-hint,
        .vault-delete-hint {
            position: relative;
        }

        .vaultOverlay {
            display: block;
        }
    }

    .data-editing-audit-hint {
        .data-audit-title-hint {
            position: relative;
            z-index: 99999;
            background-color: #fff;
            border-radius: 5px;
            padding: 5px;
            position: relative;
        }

        .data-audit-export-hint {
            position: relative;
            z-index: 99999;
            background-color: #fff;
            border-radius: 5px;
            position: relative;
            pointer-events: none;
        }

        .data-audit-filter-hint {
            position: relative;
            z-index: 99999;
            background-color: #fff;
            border-radius: 5px;
            padding: 5px 0 5px 5px;
            position: relative;
        }

        .data-audit-date-hint {
            position: relative;
        }

        .data-audit-entity-hint {
            position: relative;
        }

        .data-audit-location-hint {
            position: relative;
        }

        .data-audit-edited-on-hint {
            position: relative;
        }

        .data-audit-edited-by-hint {
            position: relative;
        }

        .data-audit-comment-hint {
            position: relative;
        }

        .data-audit-actions-hint {
            position: relative;
        }

        .data-audit-item-hint {
            position: relative;
        }

        .dataEditingAuditOverlay {
            display: block;
        }
    }

    .dailyDialogHint {
        .dailyDialogOverlay {
            display: block;
        }
    }

    .dailySummaryOverviewHint {
        .daily-locations-hint {
            position: relative;
        }

        .daily-entity-hint {
            position: relative;
        }

        .daily-date-picker-hint {
            position: relative;
        }

        .daily-title-hint {
            z-index: 9999;
            position: relative;
            background: #fff;
            padding: 5px;
            border-radius: 5px;
        }

        .daily-download-hint {
            z-index: 9999;
            position: relative;
            pointer-events: none;
            background-color: #fff;
        }

        .daily-hide-tile-hint {
            position: relative;
        }

        .daily-tiles-hint {
            position: relative;
        }

        .daily-table-hint {
            position: relative;
        }

        .daily-values-hint {
            position: relative;
        }

        .dailySummaryOverviewOverlay {
            display: block;
        }
    }

    .dailySummaryDetailsHint {
        .dailySummaryDetailsDownloadHint {
            z-index: 99999;
            position: relative;
            background: #fff;
        }

        .dailySummaryDetailsFilterHint {
            z-index: 99999;
            position: relative;
            color: #fff;
        }

        .dailySummaryDetailOverlay {
            display: block;
        }
    }

    .percentFullHint {
        .percentFullDownloadHint {
            z-index: 99999;
            position: relative;
            background: #fff;
        }

        .percentTitleHint,
        .percentFullFilterHint {
            z-index: 99999;
            position: relative;
        }

        .percentTitleHint {
            color: #fff;
        }

        .percentFullFilterHint {
            padding: 5px 0 5px 5px;
            border-radius: 5px;
            background-color: #fff;
        }

        .percent-date-hint {
            position: relative;
            background-color: #fff;
            z-index: 99999;
            border-radius: 5px;
            padding: 0 5px;
            pointer-events: none;
        }

        .percent-locations-hint {
            position: relative;
            background-color: #fff;
            z-index: 99999;
            border-radius: 5px;
            margin-left: 10px;
            padding: 0 5px;
            pointer-events: none;
        }

        .percent-yesterday-hint {
            position: relative;
            z-index: 99999;
            background: #fff;
            border-radius: 5px;
        }

        .percent-date-column-hint {
            position: relative;
            z-index: 99999;
            background: #fff;
            border-radius: 5px;
        }

        .percent-item-hint {
            position: relative;
        }

        .percentFullOverlay {
            display: block;
        }
    }

    .uptimeOverviewHint {
        .uptime-title-hint {
            position: relative;
            z-index: 99999;
            background-color: #fff;
            border-radius: 5px;
            padding: 5px;
        }

        .uptime-download-hint {
            position: relative;
            z-index: 99999;
            background-color: #fff;
            pointer-events: none;
            border-radius: 5px;
        }

        .uptime-filter-hint {
            position: relative;
            z-index: 99999;
            background-color: #fff;
            border-radius: 5px;
            padding: 5px 0 5px 5px;
        }

        .uptime-loc-filter-hint {
            position: relative;
        }

        .uptime-entity-filter-hint {
            position: relative;
        }

        .uptime-date-filter-hint {
            position: relative;
        }

        .uptime-samplerate-filter-hint {
            position: relative;
        }

        .uptime-location-hint {
            position: relative;
        }

        .uptime-entity-hint {
            position: relative;
        }

        .uptime-average-hint {
            position: relative;
        }

        .uptime-day-hint {
            position: relative;
        }

        .uptimeOverviewOverlay {
            display: block;
        }
    }

    .uptimeDetailsHint {
        .uptimeDetailsDownloadHint {
            z-index: 99999;
            position: relative;
            background: #fff;
        }

        .uptimeDetailsFilterHint {
            z-index: 99999;
            position: relative;
            color: #fff;
        }

        .uptimeDetailOverlay {
            display: block;
        }
    }

    .adminScreenHint {
        #addNewUser {
            z-index: 99999;
            position: relative;
        }

        .adminScreenOverlay {
            display: block;
        }
    }

    .mat-fab[disabled][disabled] {
        background-color: #5cb259;
        color: white;
    }
}

.content-width {
    width: 130px;
}

.manual-scale-width {
    width: 95px;
}

.more-option-width {
    width: 192px;
    text-align: left;
}

.flag-content-width {
    width: 69px;
}

.edit-points-width {
    width: 90px;
}

.drag-content-width {
    width: 108px;
}

.more-options-width {
    width: 50px;
}

.select-data-points {
    width: 101px;
}

.flag-data-content {
    width: 88px;
}

.uidepth-placholder {
    position: absolute;
    top: 35px;
    left: 35px;
    color: #fff;
}

.entites-placeholder {
    position: absolute;
    top: 220px;
    left: 20px;
    color: #fff;
}

.select-location-hint {
    position: absolute;
    top: 80px;
    left: 80px;
    transform: rotate(180deg) scaleX(-1);
}

.select-location-hint-content {
    position: absolute;
    top: 33px;
    left: 122px;
    color: #fff;
    width: 50px;
}

.entites-hint-arrow {
    position: absolute;
    top: 240px;
    left: 36px;
    transform: rotate(0deg);
}

.entites-hint-text {
    position: absolute;
    top: 307px;
    left: 73px;
    color: #fff;
}

.full-location-access-name {
    width: 207px;
}

.select-location {
    text-align: center;
    padding: 10px 0;
}

.w-80 {
    width: 80px;
}

.w-100 {
    width: 100px;
}

.w-120 {
    width: 120px;
}

.w-150 {
    width: 150px;
}

.w-180 {
    width: 180px;
}

.w-200 {
    width: 200px;
}

.w-250 {
    width: 250px;
}

.w-300 {
    width: 300px;
}

.w-350 {
    width: 350px;
}

.w-400 {
    width: 400px;
}

.w-450 {
    width: 450px;
}

.w-600 {
    width: 600px;
}

.w-600 {
    width: 600px;
}

.w-60vw {
    width: 60vw;
}

.w-30vw {
    width: 30vw;
}

.w-700 {
    width: 700px;
}

.w-75 {
    width: 75px;
}

.collector-width {
    width: 144px;
}

.percent-full-width {
    width: 224px;
}

.daily-summary-width {
    width: 245px;
}

.collect-desctiption {
    width: 95px;
}

.export-description {
    width: 150px;
}

.collect-entity {
    width: 125px;
}

.add-entity-width {
    width: 117px;
}

.crete-caclulated-entity {
    width: 134px;
}

.add-note-width {
    width: 105px;
}

.drag-data-hint-text-width {
    width: 105px;
}

.location-width {
    width: 230px;
}

.select-data-hint-width {
    width: 84px;
}

.flags-data-point {
    width: 133px;
}

.manually-edit-hint-text-width {
    width: 148px;
}

.vault-upload-text-width {
    width: 265px;
}

.more-options-width {
    width: 310px;
}

.flag-data-hint-text-width {
    width: 70px;
}

.details-percent-width {
    width: 150px;
}

.details-uptime-width {
    width: 231px;
}

.details-daily-summary-width {
    width: 180px;
}

.snap-curve-hint-text-width {
    width: 125px;
}

.ansr-edit-content {
    top: 383px;
    right: 185px;
    position: absolute;
    color: #fff;
}

.ansr-delete-content {
    top: 445px;
    right: 85px;
    position: absolute;
    color: #fff;
}

.sliicer-no-studies {
    text-align: center;
}

.page {
    top: 414px;
    position: absolute;
    left: 265px;
    background: #636262;
    width: 158px;
    height: 123px;
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.page1 {
    top: 540px;
    position: absolute;
    left: 266px;
    background: #636262;
    width: 152px;
    padding: 3px;
    font-size: 14px;
    color: #153e52;
    font-weight: 600;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.file-icons {
    top: 563px;
    position: absolute;
    left: 266px;
    background: #565656;
    width: 150px;
    padding: 5px;
}

.icons-space {
    background: #fff;
    border-radius: 6px;
}

.share-icon {
    position: absolute;
    top: 498px;
    left: 273px;
    transform: (rotate(180deg) scaleX(-1));
}

.share-icon-content {
    position: absolute;
    color: #fff;
    left: 313px;
    top: 479px;
}

.import-icon {
    position: absolute;
    top: 596px;
    transform: (rotate(0deg) scaleX(-1));
    left: 290px;
}

.import-icon-content {
    position: absolute;
    top: 656px;
    left: 392px;
    color: #fff;
}

.download-icon {
    position: absolute;
    top: 596px;
    transform: rotate(0deg);
    left: 351px;
}

.download-icon-content {
    position: absolute;
    top: 658px;
    left: 224px;
    color: #fff;
}

.delete-icon {
    position: absolute;
    top: 538px;
    transform: rotate(-90deg);
    left: 428px;
}

.delete-icon-content {
    position: absolute;
    top: 540px;
    left: 483px;
    color: #fff;
}

.folder-icon {
    font-size: 80px;
    text-align: center;
    position: absolute;
    top: 20px;
    left: 34px;
    width: 30px;
    color: #3f3f3f;
}

.homePageThirdTabOverlay {
    .tools-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .tools-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .search-loc-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .search-loc-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .map-btn-hint-arrow {
        @include custom-hint(rotate(230deg) scaleX(1));
    }

    .map-btn-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .measure-area-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .measure-area-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .measure-length-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .measure-length-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .measure-clear-hint-arrow {
        @include custom-hint(rotate(300deg) scaleX(-1));
    }

    .measure-clear-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .select-feat-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .select-feat-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .clear-tool-selection-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .clear-tool-selection-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .select-poly-hint-arrow {
        @include custom-hint(rotate(300deg) scaleX(-1));
    }

    .select-poly-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .select-radius-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .select-radius-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .map-overview-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .map-overview-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .map-scale-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .map-scale-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }
}

.auditLogOverlay {
    .audit-title-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .audit-title-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .audit-download-hint-arrow {
        @include custom-hint(rotate(120deg) scaleX(-1));
    }

    .audit-download-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .audit-filter-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .audit-filter-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .audit-search-hint-arrow {
        @include custom-hint(rotate(300deg) scaleX(-1));
    }

    .audit-search-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .audit-date-hint-arrow {
        @include custom-hint(rotate(195deg) scaleX(-1));
    }

    .audit-date-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .audit-locations-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .audit-locations-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .audit-loc-group-hint-arrow {
        @include custom-hint(rotate(300deg) scaleX(-1));
    }

    .audit-loc-group-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .audit-item-hint-arrow {
        @include custom-hint(rotate(300deg) scaleX(-1));
    }

    .audit-item-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.batteryStatusOverlay {
    .battery-title-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .battery-title-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .battery-filter-hint-arrow {
        @include custom-hint(rotate(150deg) scaleX(1));
    }

    .battery-filter-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .battery-fullscreen-hint-arrow {
        @include custom-hint(rotate(150deg) scaleX(1));
    }

    .battery-fullscreen-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .battery-close-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .battery-close-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .battery-locations-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .battery-locations-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .battery-download-hint-arrow {
        @include custom-hint(rotate(150deg) scaleX(1));
    }

    .battery-download-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .battery-buy-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .battery-buy-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .battery-filter-status-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .battery-filter-status-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .battery-locations-filter-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .battery-locations-filter-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.battery-sm-widget-hint {

    .battery-sm-title-hint,
    .battery-sm-filter-hint,
    .battery-sm-expand-hint,
    .battery-sm-close-hint,
    .battery-sm-location-hint,
    .battery-sm-series-hint,
    .battery-sm-voltage-hint,
    .battery-sm-date-hint,
    .battery-sm-buy-now-hint,
    .battery-sm-location-filter-hint,
    .battery-sm-status-hint {
        position: relative;
    }

    .batteryStatusCollapsedOverlay {
        display: block;
    }
}

.batteryStatusCollapsedOverlay {

    .battery-sm-location-hint-arrow,
    .battery-sm-voltage-hint-arrow {
        // Point straight up
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .battery-sm-voltage-hint-arrow {
        // Angle up/left
        @include custom-hint(rotate(330deg) scaleX(1));
    }

    .battery-sm-series-hint-arrow {
        // Angle up/left
        @include custom-hint(rotate(345deg) scaleX(1));
    }

    .battery-sm-buy-now-hint-arrow {
        // Point left
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .battery-sm-expand-hint-arrow {
        // Point angle down/left
        @include custom-hint(rotate(220deg) scaleX(-1));
    }

    .battery-sm-filter-hint-arrow {
        // Point angle down/left
        @include custom-hint(rotate(200deg) scaleX(-1));
    }

    .battery-sm-date-hint-arrow,
    .battery-sm-location-filter-hint-arrow,
    .battery-sm-status-filter-hint-arrow,
    .battery-sm-close-hint-arrow {
        // Point left
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .battery-sm-title-hint-arrow {
        // Point down
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .battery-sm-title-hint-text,
    .battery-sm-filter-hint-text,
    .battery-sm-expand-hint-text,
    .battery-sm-close-hint-text,
    .battery-sm-location-hint-text,
    .battery-sm-series-hint-text,
    .battery-sm-voltage-hint-text,
    .battery-sm-date-hint-text,
    .battery-sm-buy-now-hint-text,
    .battery-sm-location-filter-hint-text,
    .battery-sm-status-filter-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.alarms-sm-widget-hint {

    .alarm-sm-title-hint,
    .alarm-sm-expand-hint,
    .alarm-sm-close-hint,
    .alarm-sm-status-hint,
    .alarm-sm-type-hint,
    .alarm-sm-date-hint,
    .alarm-sm-location-hint,
    .alarm-sm-graph-hint,
    .alarm-sm-map-hint {
        position: relative;
    }

    .alarmsWidgetCollapsedOverlay {
        display: block;
    }
}

.alarmsWidgetCollapsedOverlay {

    .alarm-sm-status-hint-arrow,
    .alarm-sm-location-hint-arrow {
        // Point straight up
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .alarm-sm-graph-hint-arrow {
        // Horizontal arrow pointing left
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .alarm-sm-date-hint-arrow,
    .alarm-sm-location-hint-arrow {
        // Horizontal arrow pointing left
        @include custom-hint(rotate(300deg) scaleX(-1));
    }

    .alarm-sm-type-hint-arrow,
    .alarm-sm-expand-hint-arrow {
        // Angled pointing down/left
        @include custom-hint(rotate(200deg) scaleX(-1));
    }

    .alarm-sm-map-hint-arrow,
    .alarm-sm-title-hint-arrow {
        // Vertical pointing down
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .alarm-sm-close-hint-arrow {
        // Horizontal arrow pointing left
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .alarm-sm-title-hint-text,
    .alarm-sm-expand-hint-text,
    .alarm-sm-close-hint-text,
    .alarm-sm-status-hint-text,
    .alarm-sm-type-hint-text,
    .alarm-sm-date-hint-text,
    .alarm-sm-location-hint-text,
    .alarm-sm-graph-hint-text,
    .alarm-sm-map-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.event-widget-expanded-hint {

    .event-widget-hint,
    .add-event-hint,
    .event-filters-hint,
    .event-full-hint,
    .event-full-close-hint,
    .event-close-hint,
    .event-description-hint,
    .event-type-hint,
    .event-locations-hint,
    .event-started-at-hint,
    .event-duration-hint,
    .event-gis-hint,
    .event-edit-hint,
    .event-delete-hint,
    .event-filter-locations-hint,
    .event-filter-date-hint,
    .event-filter-type-hint,
    .event-filter-duration-hint,
    .event-paginator-hint {
        position: relative;
    }

    .eventsWidgetOverlay {
        display: block;
    }
}

.eventsWidgetOverlay {
    .add-event-widget-hint-arrow {
        @include custom-hint(rotate(120deg) scaleX(-1));
    }

    .add-event-widget-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .event-filters-widget-hint-arrow {
        @include custom-hint(rotate(120deg) scaleX(-1));
    }

    .event-filters-widget-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .event-full-widget-hint-arrow {
        @include custom-hint(rotate(240deg) scaleX(1));
    }

    .event-full-widget-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .event-full-close-widget-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .event-full-close-widget-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .event-close-widget-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .event-close-widget-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .event-description-widget-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .event-description-widget-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .event-type-widget-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .event-type-widget-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .event-locations-widget-hint-arrow {
        @include custom-hint(rotate(30deg) scaleX(-1));
    }

    .event-locations-widget-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .event-started-at-widget-hint-arrow {
        @include custom-hint(rotate(130deg) scaleX(1));
    }

    .event-started-at-widget-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .event-duration-widget-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .event-duration-widget-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .event-gis-widget-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .event-gis-widget-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .event-edit-widget-hint-arrow {
        @include custom-hint(rotate(130deg) scaleX(1));
    }

    .event-edit-widget-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .event-delete-widget-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .event-delete-widget-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .event-filter-locations-widget-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .event-filter-locations-widget-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .event-filter-date-widget-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .event-filter-date-widget-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .event-filter-type-widget-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .event-filter-type-widget-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .event-filter-duration-widget-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .event-filter-duration-widget-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .event-paginator-widget-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }

    .event-paginator-widget-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }
}

.alarms-widget-hint {

    .alarm-title-hint,
    .alarm-filter-hint,
    .alarm-collapse-hint,
    .alarm-close-hint,
    .alarm-status-hint,
    .alarm-type-hint,
    .alarm-date-hint,
    .alarm-location-hint,
    .alarm-location-card-hint,
    .alarm-graph-hint,
    .alarm-download-hint {
        position: relative;
    }

    .alarmsWidgetOverlay {
        display: block;
    }
}

.alarmsWidgetOverlay {

    .alarm-collapse-hint-arrow,
    .alarm-download-hint-arrow {
        // Angled pointing down/right
        @include custom-hint(rotate(135deg) scaleX(1));
    }

    .alarm-location-card-hint-arrow {
        // Point straight up
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .alarm-close-hint-arrow {
        // Vertical arrow pointing down
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .alarm-title-hint-arrow {
        // Horizontal arrow pointing left
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .alarm-status-hint-arrow,
    .alarm-location-hint-arrow,
    .alarm-graph-hint-arrow,
    .alarm-date-hint-arrow {
        // Diagonal arrow pointint up/left
        @include custom-hint(rotate(-45deg) scaleX(1));
    }

    .alarm-type-hint-arrow {
        // Diagonal arrow pointint up/left, flipped
        @include custom-hint(rotate(215deg) scaleX(-1));
    }

    .alarm-filter-hint-arrow {
        // Horizontal arrow pointing right
        @include custom-hint(rotate(90deg) scaleX(1));
    }

    .alarm-title-hint-text,
    .alarm-filter-hint-text,
    .alarm-collapse-hint-text,
    .alarm-close-hint-text,
    .alarm-status-hint-text,
    .alarm-type-hint-text,
    .alarm-date-hint-text,
    .alarm-location-hint-text,
    .alarm-location-card-hint-text,
    .alarm-graph-hint-text,
    .alarm-download-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.dataComm-sm-widget-hint {
    .DataCommCollapsedOverlay {
        position: relative;
        color: #fff;
        display: block;

        .dcomm-title-hint-arrow {
            @include custom-hint(rotate(262deg) scaleX(1));
        }

        .dcomm-title-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .dcomm-full-hint-arrow {
            @include custom-hint(rotate(245deg) scaleX(1));
        }

        .dcomm-full-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .dcomm-close-hint-arrow {
            @include custom-hint(rotate(270deg) scaleX(1));
        }

        .dcomm-close-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .dcomm-location_s_c-hint-arrow {
            @include custom-hint(rotate(270deg) scaleX(-1));
        }

        .dcomm-location_s_c-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .dcomm-location_c-hint-arrow {
            @include custom-hint(rotate(250deg) scaleX(1));
        }

        .dcomm-location_c-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .dcomm-schedule-hint-arrow {
            @include custom-hint(rotate(270deg) scaleX(-1));
        }

        .dcomm-schedule-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .dcomm-collect-all-hint-arrow {
            @include custom-hint(rotate(270deg) scaleX(1));
        }

        .dcomm-collect-all-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }
    }
}

.dataCommunationOverlay {
    .data-comm-filter-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .data-comm-filter-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .data-comm-full-hint-arrow {
        @include custom-hint(rotate(150deg) scaleX(1));
    }

    .data-comm-full-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .data-comm-close-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .data-comm-close-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .data-comm-title-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .data-comm-title-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .data-comm-headers-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .data-comm-headers-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .data-comm-failed-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .data-comm-failed-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .data-comm-attempted-hint-arrow {
        @include custom-hint(rotate(225deg) scaleX(-1));
    }

    .data-comm-attempted-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .data-comm-item-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .data-comm-item-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .data-comm-schedule-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }

    .data-comm-schedule-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .data-comm-collect-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .data-comm-collect-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .data-comm-date-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .data-comm-date-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .data-comm-series-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .data-comm-series-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .data-comm-locations-hint-arrow {
        @include custom-hint(rotate(45deg) scaleX(-1));
    }

    .data-comm-locations-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .data-comm-footer-schedule-hint-arrow {
        @include custom-hint(rotate(140deg) scaleX(1));
    }

    .data-comm-footer-schedule-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .data-comm-footer-download-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(1));
    }

    .data-comm-footer-download-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.homePageSecondTabOverlay {
    .layers-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .layers-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .basemap-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .basemap-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .add-monitor-hint-arrow {
        @include custom-hint(rotate(300deg) scaleX(-1));
    }

    .add-monitor-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .monitor-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .monitor-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .zoom-in-hint-arrow {
        @include custom-hint(rotate(300deg) scaleX(-1));
    }

    .zoom-in-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .transparency-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .transparency-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .feat-select-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .feat-select-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .show-legend-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .show-legend-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }
}

.homePageFourthTabOverlay {
    .edit-mon-hint-arrow {
        @include custom-hint(rotate(30deg) scaleX(-1));
    }

    .edit-mon-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .child-search-hint-arrow {
        @include custom-hint(rotate(300deg) scaleX(-1));
    }

    .child-search-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            right);
    }

    .monitor-name-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .monitor-name-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .add-mon-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .add-mon-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .mon-search-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .mon-search-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .prop-items-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .prop-items-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }
}

.editUserFeaturesOverlay {
    .assignFeaturesUserHint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .assignFeaturesUserHint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .features-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .features-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.editUserLocationOverlay {
    .assignLocationsUserHint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .assignLocationsUserHint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .select-all-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .select-all-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .customer-select-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .customer-select-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .location-list-hint-arrow {
        @include custom-hint(rotate(00deg) scaleX(1));
    }

    .location-list-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.editUserCustomerOverlay {
    .assignCustomersUserHint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .assignCustomersUserHint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .customer-search-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }

    .customer-search-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .customer-list-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .customer-list-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.editUserDetailsOverlay {
    .userDetailsHint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .userDetailsHint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .activate-user-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }

    .activate-user-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .active-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .active-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .save-user-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(1));
    }

    .save-user-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.editUserRoleOverlay {
    .assignRolesUserHint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .assignRolesUserHint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .roles-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .roles-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.usersPageOverlay {
    .search-users-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .search-users-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .user-status-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(1));
    }

    .user-status-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .user-edit-hint-arrow {
        @include custom-hint(rotate(120deg) scaleX(-1));
    }

    .user-edit-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .user-add-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .user-add-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.edit-cus-details-overlay {

    .edit-cus-name-hint-text,
    .edit-cus-short-name-hint-text,
    .edit-cus-ttl-hint-text,
    .edit-cus-units-hint-text,
    .edit-cus-date-format-hint-text,
    .edit-cus-time-format-hint-text,
    .edit-cus-timezone-hint-text,
    .edit-cus-work-order-hint-text,
    .edit-cus-idle-time-hint-text,
    .edit-cus-daylight-hint-text,
    .edit-cus-con-num-hint-text,
    .edit-cus-subs-hint-text,
    .edit-cus-orig-start-hint-text,
    .edit-cus-curr-start-hint-text,
    .edit-cus-curr-end-hint-text,
    .edit-cus-da-hint-text,
    .edit-cus-reg-man-hint-text,
    .edit-cus-sales-hint-text,
    .edit-cus-pm-hint-text,
    .edit-cus-inactive-on-hint-text,
    .edit-cus-rough-hint-text,
    .edit-cus-details-add-hint-text,
    .edit-cus-save-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .edit-cus-name-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .edit-cus-short-name-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .edit-cus-ttl-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .edit-cus-units-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-date-format-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-time-format-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-timezone-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-work-order-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-idle-time-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-daylight-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-con-num-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-subs-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-orig-start-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-curr-start-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-curr-end-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-da-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-reg-man-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-sales-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-pm-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-inactive-on-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-rough-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(1));
    }

    .edit-cus-details-add-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-save-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }
}

.edit-cus-features-overlay {

    .edit-cus-feats-hint-text,
    .edit-cus-feats-apply-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .edit-cus-feats-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .edit-cus-feats-apply-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }
}

.edit-cus-users-overlay {

    .edit-cus-users-search-hint-text,
    .edit-cus-users-assigned-hint-text,
    .edit-cus-users-check-hint-text,
    .edit-cus-users-username-hint-text,
    .edit-cus-users-email-hint-text,
    .edit-cus-users-name-hint-text,
    .edit-cus-users-role-hint-text,
    .edit-cus-users-login-hint-text,
    .edit-cus-users-feats-hint-text,
    .edit-cus-users-status-hint-text,
    .edit-cus-users-edit-hint-text,
    .edit-cus-users-add-hint-text,
    .edit-cus-users-paginator-hint-text,
    .edit-cus-users-save-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .edit-cus-users-search-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-users-assigned-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .edit-cus-users-check-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .edit-cus-users-username-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .edit-cus-users-email-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .edit-cus-users-name-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .edit-cus-users-role-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .edit-cus-users-login-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .edit-cus-users-feats-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .edit-cus-users-status-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .edit-cus-users-edit-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .edit-cus-users-add-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-users-paginator-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }

    .edit-cus-users-save-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }
}

.edit-cus-locations-overlay {

    .edit-cus-locs-search-hint-text,
    .edit-cus-locs-filter-hint-text,
    .edit-cus-locs-name-hint-text,
    .edit-cus-locs-series-hint-text,
    .edit-cus-locs-serial-hint-text,
    .edit-cus-locs-desc-hint-text,
    .edit-cus-locs-sensors-hint-text,
    .edit-cus-locs-group-hint-text,
    .edit-cus-locs-status-hint-text,
    .edit-cus-locs-activate-hint-text,
    .edit-cus-locs-composite-hint-text,
    .edit-cus-locs-wdo-hint-text,
    .edit-cus-locs-edit-hint-text,
    .edit-cus-locs-add-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .edit-cus-locs-search-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-locs-filter-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }

    .edit-cus-locs-name-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .edit-cus-locs-series-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .edit-cus-locs-serial-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .edit-cus-locs-desc-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .edit-cus-locs-sensors-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .edit-cus-locs-group-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .edit-cus-locs-status-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .edit-cus-locs-activate-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .edit-cus-locs-composite-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .edit-cus-locs-wdo-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .edit-cus-locs-edit-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .edit-cus-locs-add-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }
}

.edit-cus-locaitons-filters-overlay {

    .edit-cus-filters-series-hint-text,
    .edit-cus-filters-group-hint-text,
    .edit-cus-filters-status-hint-text,
    .edit-cus-filters-sensors-hint-text,
    .edit-cus-filters-wdo-hint-text,
    .edit-cus-filters-composite-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .edit-cus-filters-series-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .edit-cus-filters-group-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .edit-cus-filters-status-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .edit-cus-filters-sensors-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .edit-cus-filters-wdo-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .edit-cus-filters-composite-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }
}

.edit-cus-ftp-overlay {

    .edit-cus-ftp-table-hint-text,
    .edit-cus-ftp-add-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .edit-cus-ftp-table-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .edit-cus-ftp-add-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }
}

.edit-cus-ftp-add-overlay {

    .edit-cus-ftp-protocol-hint,
    .edit-cus-ftp-mode-hint,
    .edit-cus-ftp-host-hint,
    .edit-cus-ftp-username-hint,
    .edit-cus-ftp-password-hint,
    .edit-cus-ftp-port-hint,
    .edit-cus-ftp-folder-hint,
    .edit-cus-ftp-time-hint,
    .edit-cus-ftp-import-hint,
    .edit-cus-ftp-close-hint,
    .edit-cus-ftp-save-hint {
        position: relative;
    }

    .edit-cus-ftp-protocol-hint-text,
    .edit-cus-ftp-mode-hint-text,
    .edit-cus-ftp-host-hint-text,
    .edit-cus-ftp-username-hint-text,
    .edit-cus-ftp-password-hint-text,
    .edit-cus-ftp-port-hint-text,
    .edit-cus-ftp-folder-hint-text,
    .edit-cus-ftp-time-hint-text,
    .edit-cus-ftp-import-hint-text,
    .edit-cus-ftp-close-hint-text,
    .edit-cus-ftp-save-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .edit-cus-ftp-protocol-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-ftp-mode-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-ftp-host-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-ftp-username-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-ftp-password-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-ftp-port-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-ftp-folder-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-ftp-time-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-ftp-import-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-ftp-close-hint-arrow {
        @include custom-hint(rotate(60deg) scaleX(-1));
    }

    .edit-cus-ftp-save-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }
}

.edit-cus-gis-overlay {

    .edit-cus-gis-edit-hint-text,
    .edit-cus-gis-add-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .edit-cus-gis-edit-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .edit-cus-gis-add-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }
}

.edit-cus-gis-basic-overlay {

    .edit-cus-gis-type-hint-text,
    .edit-cus-gis-mapping-hint-text,
    .edit-cus-gis-auth-hint-text,
    .edit-cus-gis-username-hint-text,
    .edit-cus-gis-password-hint-text,
    .edit-cus-gis-params-hint-text,
    .edit-cus-gis-token-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .edit-cus-gis-type-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-gis-mapping-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-gis-auth-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }

    .edit-cus-gis-username-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-gis-password-hint-arrow {
        @include custom-hint(rotate(45deg) scaleX(-1));
    }

    .edit-cus-gis-token-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(1));
    }
}

.edit-cus-gis-custom-overlay {

    .edit-cus-gis-type-hint-text-custom,
    .edit-cus-gis-mapping-hint-text-custom,
    .edit-cus-gis-auth-hint-text-custom,
    .edit-cus-gis-username-hint-text-custom,
    .edit-cus-gis-password-hint-text-custom,
    .edit-cus-gis-params-hint-text-custom,
    .edit-cus-gis-token-hint-text-custom {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .edit-cus-gis-type-hint-arrow-custom {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-gis-mapping-hint-arrow-custom {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-gis-auth-hint-arrow-custom {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }

    .edit-cus-gis-token-hint-arrow-custom {
        @include custom-hint(rotate(90deg) scaleX(1));
    }

    .edit-cus-gis-params-hint-arrow-custom {
        @include custom-hint(rotate(315deg) scaleX(1));
    }
}

.edit-cus-rainfall-dry-day-overlay {

    .edit-cus-dry-tab-hint-text,
    .edit-cus-dry-one-hint-text,
    .edit-cus-dry-three-hint-text,
    .edit-cus-dry-five-hint-text,
    .edit-cus-dry-min-hint-text,
    .edit-cus-dry-max-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .edit-cus-dry-tab-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-dry-one-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-dry-three-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-dry-five-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-dry-min-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-dry-max-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }
}

.edit-cus-rainfall-storm-event-overlay {

    .edit-cus-storm-tab-hint-text,
    .edit-cus-storm-rain-hint-text,
    .edit-cus-storm-storm-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .edit-cus-storm-tab-hint-arrow {
        @include custom-hint(rotate(240deg) scaleX(1));
    }

    .edit-cus-storm-rain-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .edit-cus-storm-storm-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }
}

.edit-cus-rainfall-infiltration-overlay {
    .edit-cus-base-method-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .edit-cus-base-method-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }
}

.edit-cus-rainfall-ddf-data-overlay {

    .edit-cus-ddf-data-tab-hint-text,
    .edit-cus-ddf-data-upload-hint-text,
    .edit-cus-ddf-data-table-hint-text,
    .edit-cus-ddf-data-add-hint-text,
    .edit-cus-ddf-data-delete-hint-text,
    .edit-cus-ddf-data-graph-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .edit-cus-ddf-data-tab-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(1));
    }

    .edit-cus-ddf-data-upload-hint-arrow {
        @include custom-hint(rotate(30deg) scaleX(-1));
    }

    .edit-cus-ddf-data-table-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(1));
    }

    .edit-cus-ddf-data-add-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .edit-cus-ddf-data-delete-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .edit-cus-ddf-data-graph-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }
}

.edit-cus-rainfall-ddf-graph-overlay {

    .edit-cus-ddf-graph-tab-hint-text,
    .edit-cus-ddf-graph-tooltip-hint-text,
    .edit-cus-ddf-graph-options-hint-text,
    .edit-cus-ddf-graph-chart-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .edit-cus-ddf-graph-tab-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .edit-cus-ddf-graph-tooltip-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .edit-cus-ddf-graph-chart-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }

    .edit-cus-ddf-graph-options-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }
}



.topNavOverlay {
    .customer-selector-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }

    .customer-selector-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            right);
    }

    .location-group-hint-arrow {
        @include custom-hint(translateX(35px) rotate(32deg) scaleX(-1));
    }

    .location-group-hint-text {
        @include custom-hint(translateX(20px));
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            right);
    }

    .active-inactive-hint-arrow {
        @include custom-hint(translateX(30px) rotate(35deg) scaleX(-1));
    }

    .active-inactive-hint-text {
        @include custom-hint(translateX(20px));
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .notification-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .notification-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .user-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .user-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .pin-hint-arrow {
        @include custom-hint(rotate(105deg) scaleX(-1));
    }

    .pin-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .widgets-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .widgets-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .auto-review-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .auto-review-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .blockage-predict-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .blockage-predict-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .data-comm-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .data-comm-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .battery-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .battery-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .alarm-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .alarm-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .event-widget-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .event-widget-hint-text {
        @include custom-hint(unset);
        padding: 5px;

        border-radius: 5px;
        @include custom-hint-text(#fff, left);
    }

    .blockage-filter-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .blockage-filter-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .blockage-close-hint-arrow {
        @include custom-hint(rotate(120deg) scaleX(-1));
    }

    .blockage-close-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .blockage-location-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .blockage-location-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .blockage-date-hint-arrow {
        @include custom-hint(rotate(140deg) scaleX(1));
    }

    .blockage-date-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .blockage-status-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .blockage-status-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .blockage-dtrend-hint-arrow {
        @include custom-hint(rotate(315deg) scaleX(1));
    }

    .blockage-dtrend-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .blockage-paginator-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .blockage-paginator-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .blockage-filter-start-hint-arrow {
        @include custom-hint(rotate(145deg) scaleX(1));
    }

    .blockage-filter-start-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .blockage-filter-end-hint-arrow {
        @include custom-hint(rotate(300deg) scaleX(1));
    }

    .blockage-filter-end-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .blockage-filter-locations-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .blockage-filter-locations-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .blockage-filter-status-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .blockage-filter-status-hint-text {
        @include custom-hint(unset);
        padding: 5px;
        border-radius: 5px;
        @include custom-hint-text(#fff,
            left);
    }

    .add-event-hint-arrow {
        @include custom-hint(rotate(120deg) scaleX(-1));
    }

    .add-event-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .event-filters-hint-arrow {
        @include custom-hint(rotate(120deg) scaleX(-1));
    }

    .event-filters-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .event-full-hint-arrow {
        @include custom-hint(rotate(240deg) scaleX(1));
    }

    .event-full-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .event-full-close-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .event-full-close-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .event-close-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .event-close-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .event-description-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .event-description-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .event-type-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .event-type-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .event-locations-hint-arrow {
        @include custom-hint(rotate(30deg) scaleX(-1));
    }

    .event-locations-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .event-started-at-hint-arrow {
        @include custom-hint(rotate(130deg) scaleX(1));
    }

    .event-started-at-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .event-duration-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .event-duration-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .event-gis-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .event-gis-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .event-edit-hint-arrow {
        @include custom-hint(rotate(330deg) scaleX(1));
    }

    .event-edit-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .event-delete-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .event-delete-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .event-filter-locations-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .event-filter-locations-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .event-filter-date-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .event-filter-date-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .event-filter-type-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .event-filter-type-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .event-filter-duration-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .event-filter-duration-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .event-paginator-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .event-paginator-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .ar-filter-hint-text,
    .ar-full-hint-text,
    .ar-close-hint-text,
    .ar-full-close-hint-text,
    .ar-start-hint-text,
    .ar-locations-hint-text,
    .ar-end-hint-text,
    .ar-reasons-hint-text,
    .ar-date-hint-text,
    .ar-lc-hint-text,
    .ar-reason-hint-text,
    .ar-status-hint-text,
    .ar-error-hint-text,
    .ar-graph-hint-text,
    .ar-paginator-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .ar-filter-hint-arrow {
        @include custom-hint(rotate(160deg) scaleX(1));
    }

    .ar-full-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .ar-full-close-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .ar-close-hint-arrow {
        @include custom-hint(rotate(240deg) scaleX(-1));
    }

    .ar-start-hint-arrow {
        @include custom-hint(rotate(210deg) scaleX(-1));
    }

    .ar-end-hint-arrow {
        @include custom-hint(rotate(190deg) scaleX(-1));
    }

    .ar-locations-hint-arrow {
        @include custom-hint(rotate(210deg) scaleX(-1));
    }

    .ar-reasons-hint-arrow {
        @include custom-hint(rotate(265deg) scaleX(1));
    }

    .ar-lc-hint-arrow {
        @include custom-hint(rotate(200deg) scaleX(-1));
    }

    .ar-date-hint-arrow {
        @include custom-hint(rotate(200deg) scaleX(-1));
    }

    .ar-status-hint-arrow {
        @include custom-hint(rotate(220deg) scaleX(-1));
    }

    .ar-error-hint-arrow {
        @include custom-hint(rotate(210deg) scaleX(-1));
    }

    .ar-reason-hint-arrow {
        @include custom-hint(rotate(265deg) scaleX(1));
    }

    .ar-graph-hint-arrow {
        @include custom-hint(rotate(250deg) scaleX(-1));
    }

    .ar-paginator-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .addCustomerHintArrow {
        @include custom-hint(rotate(90deg));
    }

    .addCustomerHintTest {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            right);
    }

    .addLocationGroupHintArrow {
        @include custom-hint(rotate(90deg));
    }

    .addLocationGroupHintTest {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            right);
    }

    .addInactiveHintArrow {
        @include custom-hint(rotate(-15deg) scaleX(1));
    }

    .addInactiveHintTest {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .addNewLocationHintArrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .addNewLocationHintTest {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .searchLocationHintArrow {
        @include custom-hint(rotate(70deg) scaleX(1));
    }

    .searchLocationHintTest {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            right);
        width: 450px;
    }

    .addRecenterHintArrow {
        @include custom-hint(rotate(90deg));
    }

    .addRecenterHintTest {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            right);
        width: 400px;
    }

    .mapLeagendHintArrow {
        @include custom-hint(rotate(90deg));
    }

    .mapLeagendHintTest {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.customerEditorOverlay {

    .cus-filter-hint-text,
    .cus-filter-feature-hint-text,
    .cus-filter-inactive-hint-text,
    .cus-search-hint-text,
    .cus-name-hint-text,
    .cus-locs-hint-text,
    .cus-inactive-locs-hint-text,
    .cus-feats-hint-text,
    .cus-inactive-hint-text,
    .cus-edit-hint-text,
    .cus-add-hint-text,
    .cus-paginator-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .cus-filter-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }

    .cus-filter-feature-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .cus-filter-inactive-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .cus-search-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .cus-name-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .cus-locs-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .cus-inactive-locs-hint-arrow {
        @include custom-hint(rotate(330deg) scaleX(1));
    }

    .cus-feats-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .cus-inactive-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }

    .cus-edit-hint-arrow {
        @include custom-hint(rotate(30deg) scaleX(-1));
    }

    .cus-add-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .cus-paginator-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }
}

.autoReviewOverlay {

    .ar-filter-hint-text,
    .ar-full-hint-text,
    .ar-close-hint-text,
    .ar-full-close-hint-text,
    .ar-start-hint-text,
    .ar-locations-hint-text,
    .ar-end-hint-text,
    .ar-reasons-hint-text,
    .ar-date-hint-text,
    .ar-lc-hint-text,
    .ar-reason-hint-text,
    .ar-status-hint-text,
    .ar-error-hint-text,
    .ar-graph-hint-text,
    .ar-paginator-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .ar-filter-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }

    .ar-full-hint-arrow {
        @include custom-hint(rotate(75deg) scaleX(1));
    }

    .ar-full-close-hint-arrow {
        @include custom-hint(rotate(120deg) scaleX(-1));
    }

    .ar-close-hint-arrow {
        @include custom-hint(rotate(120deg) scaleX(-1));
    }

    .ar-start-hint-arrow {
        @include custom-hint(rotate(110deg) scaleX(-1));
    }

    .ar-end-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .ar-locations-hint-arrow {
        @include custom-hint(rotate(300deg) scaleX(1));
    }

    .ar-reasons-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .ar-lc-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .ar-date-hint-arrow {
        @include custom-hint(rotate(115deg) scaleX(-1));
    }

    .ar-status-hint-arrow {
        @include custom-hint(rotate(320deg) scaleX(-1));
    }

    .ar-error-hint-arrow {
        @include custom-hint(rotate(320deg) scaleX(1));
    }

    .ar-reason-hint-arrow {
        @include custom-hint(rotate(255deg) scaleX(1));
    }

    .ar-graph-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .ar-paginator-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }
}

.leaderBoardTileOverlay {
    .alarmLeaderHintArrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .alarmLeaderHintTest {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .batteryLeaderHintArrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .batteryLeaderHintTest {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .blockagePDLeaderHintArrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .blockagePDLeaderHintTest {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .autoReviewLeaderHintArrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .autoReviewLeaderHintTest {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .collectionLeaderHintArrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .collectionLeaderHintTest {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    div {
        width: 200px !important;
    }
}

.schedule-dashboard-hint-overlay {
    .schedule-title-hint-arrow {
        @include custom-hint(rotate(270deg) scaleY(1));
    }

    .schedule-title-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .add-schedule-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }

    .add-schedule-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .filter-hint-arrow {
        @include custom-hint(rotate(180deg) scaleY(1));
    }

    .filter-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .name-filter-hint-arrow {
        @include custom-hint(rotate(0deg) scaleY(1));
    }

    .name-filter-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .week-days-hint-arrow {
        @include custom-hint(rotate(0deg) scaleY(1));
    }

    .week-days-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .frequency-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .frequency-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .edit-schedule-hint-arrow {
        @include custom-hint(rotate(90deg) scaleY(1));
    }

    .edit-schedule-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .delete-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .delete-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.block-editor-hint-overlay {
    .block-editor-dialog-condition-location-hint-arrow {
        @include custom-hint(rotate(210deg) scaleX(-1));
    }
    .block-editor-dialog-condition-location-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }
    .block-editor-dialog-condition-entity-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }
    .block-editor-dialog-condition-entity-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }
    .block-editor-dialog-condition-operator-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }
    .block-editor-dialog-condition-operator-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }
    .block-editor-dialog-condition-value-hint-arrow {
        @include custom-hint(rotate(210deg) scaleX(-1));
    }
    .block-editor-dialog-condition-value-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .block-editor-dialog-entity-hint-arrow {
        @include custom-hint(rotate(135deg) scaleX(1));
    }
    .block-editor-dialog-entity-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }
    .block-editor-dialog-action-hint-arrow {
        @include custom-hint(rotate(210deg) scaleX(-1));
    }
    .block-editor-dialog-action-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }
    .block-editor-dialog-valueentity-hint-arrow {
        @include custom-hint(rotate(135deg) scaleX(1));
    }
    .block-editor-dialog-valueentity-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }
    .block-editor-dialog-entityvalue-hint-arrow {
        @include custom-hint(rotate(40deg) scaleX(-1));
    }
    .block-editor-dialog-entityvalue-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }
    .block-editor-dialog-date-range-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(1));
    }

    .block-editor-dialog-date-range-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .block-editor-dialog-header-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }

    .block-editor-dialog-header-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .block-editor-dialog-add-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .block-editor-dialog-add-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .block-editor-dialog-apply-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .block-editor-dialog-apply-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .block-editor-dialog-close-hint-arrow {
        @include custom-hint(rotate(40deg) scaleX(-1));
    }

    .block-editor-dialog-close-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.schedule-dialog-hint-overlay {
    .schedule-dialog-name-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
      }
      .schedule-dialog-name-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
      }

      .schedule-dialog-search-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
      }
      .schedule-dialog-search-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
      }

      .schedule-dialog-cancel-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
      }
      .schedule-dialog-cancel-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
      }

    .schedule-dialog-locations-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }

    .schedule-dialog-locations-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .schedule-dialog-days-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }

    .schedule-dialog-days-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .schedule-dialog-collect-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(1));
    }

    .schedule-dialog-collect-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .schedule-dialog-frequency-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .schedule-dialog-frequency-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .schedule-dialog-event-hint-arrow {
        @include custom-hint(rotate(60deg) scaleX(1));
    }

    .schedule-dialog-event-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .schedule-dialog-save-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .schedule-dialog-save-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.dataEditingAuditOverlay {
    .data-audit-title-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .data-audit-title-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .data-audit-export-hint-arrow {
        @include custom-hint(rotate(120deg) scaleX(-1));
    }

    .data-audit-export-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .data-audit-filter-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .data-audit-filter-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .data-audit-date-hint-arrow {
        @include custom-hint(rotate(205deg) scaleX(-1));
    }

    .data-audit-date-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .data-audit-entity-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .data-audit-entity-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .data-audit-location-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .data-audit-location-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .data-audit-edited-on-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .data-audit-edited-on-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .data-audit-edited-by-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .data-audit-edited-by-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .data-audit-comment-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .data-audit-comment-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .data-audit-actions-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .data-audit-actions-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .data-audit-item-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .data-audit-item-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.uptimeOverviewOverlay {
    .uptime-title-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .uptime-title-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .uptime-download-hint-arrow {
        @include custom-hint(rotate(100deg) scaleX(-1));
    }

    .uptime-download-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .uptime-filter-hint-arrow {
        @include custom-hint(rotate(130deg) scaleX(-1));
    }

    .uptime-filter-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .uptime-loc-filter-hint-arrow {
        @include custom-hint(rotate(205deg) scaleX(-1));
    }

    .uptime-loc-filter-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .uptime-entity-filter-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .uptime-entity-filter-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .uptime-date-filter-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .uptime-date-filter-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .uptime-location-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .uptime-location-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .uptime-entity-hint-arrow {
        @include custom-hint(rotate(300deg) scaleX(-1));
    }

    .uptime-entity-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .uptime-average-hint-arrow {
        @include custom-hint(rotate(300deg) scaleX(-1));
    }

    .uptime-average-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .uptime-day-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .uptime-day-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.dailyDialogOverlay {
    .daily-type-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }

    .daily-type-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .daily-dialog-locations-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(1));
    }

    .daily-dialog-locations-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .daily-dialog-entity-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(1));
    }

    .daily-dialog-entity-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .daily-dialog-date-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .daily-dialog-date-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .daily-dialog-cancel-hint-arrow {
        @include custom-hint(rotate(45deg) scaleX(-1));
    }

    .daily-dialog-cancel-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .daily-dialog-export-hint-arrow {
        @include custom-hint(rotate(315deg) scaleX(1));
    }

    .daily-dialog-export-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.locationSecondOverlay {
    .hg-filter-hint-arrow {
        @include custom-hint(rotate(150deg) scaleX(1));
    }

    .hg-filter-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .hg-new-tab-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(1));
    }

    .hg-new-tab-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .hg-new-tab-close-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(1));
    }

    .hg-new-tab-close-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .hg-full-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .hg-full-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .sg-full-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .sg-full-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .ld-data-table-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .ld-data-table-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .sg-select-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }

    .sg-select-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .sg-clear-hint-arrow {
        @include custom-hint(rotate(30deg) scaleX(-1));
    }

    .sg-clear-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .sg-zoom-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .sg-zoom-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .sg-flag-hint-arrow {
        @include custom-hint(rotate(30deg) scaleX(-1));
    }

    .sg-flag-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .sg-unflag-hint-arrow {
        @include custom-hint(rotate(150deg) scaleX(1));
    }

    .sg-unflag-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .sg-block-hint-arrow {
        @include custom-hint(rotate(30deg) scaleX(-1));
    }

    .sg-block-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .sg-snap-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .sg-snap-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .sg-curve-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(1));
    }

    .sg-curve-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .sg-tolerance-hint-arrow {
        @include custom-hint(rotate(30deg) scaleX(-1));
    }

    .sg-tolerance-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .sg-tol-select-hint-arrow {
        @include custom-hint(rotate(30deg) scaleX(-1));
    }

    .sg-tol-select-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .sg-generation-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .sg-generation-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .sg-sync-hint-arrow {
        @include custom-hint(rotate(330deg) scaleX(1));
    }

    .sg-sync-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .hg-zoom-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .hg-zoom-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .hg-tracer-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .hg-tracer-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .highcharts-legend-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .highcharts-legend-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .highcharts-navigator-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .highcharts-navigator-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.locationOverlay {
    .ld-series-hint-arrow {
        @include custom-hint(rotate(20deg) scaleY(-1));
    }

    .ld-series-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .ld-location-hint-arrow {
        @include custom-hint(rotate(0deg) scaleY(-1));
    }

    .ld-location-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .ld-selected-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .ld-selected-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .ld-rain-hint-arrow {
        @include custom-hint(rotate(340deg) scaleX(1));
    }

    .ld-rain-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .ld-search-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .ld-search-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .ld-date-hint-arrow {
        @include custom-hint(rotate(0deg) scaleY(-1));
    }

    .ld-date-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .ld-entity-hint-arrow {
        @include custom-hint(rotate(20deg) scaleX(-1));
    }

    .ld-entity-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .ld-work-hint-arrow {
        @include custom-hint(rotate(140deg) scaleX(-1));
    }

    .ld-work-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .ld-approve-hint-arrow {
        @include custom-hint(rotate(20deg) scaleX(-1));
    }

    .ld-approve-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .ld-edit-hint-arrow {
        @include custom-hint(rotate(140deg) scaleX(1));
    }

    .ld-edit-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .ld-filter-hint-arrow {
        @include custom-hint(rotate(20deg) scaleX(-1));
    }

    .ld-filter-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .ld-options-hint-arrow {
        @include custom-hint(rotate(140deg) scaleX(1));
    }

    .ld-options-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .ld-entity-hg-hint-arrow {
        @include custom-hint(rotate(180deg) scaleY(-1));
    }

    .ld-entity-hg-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .ld-entity-sg-hint-arrow {
        @include custom-hint(rotate(180deg) scaleY(-1));
    }

    .ld-entity-sg-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .ld-date-select-hint-arrow {
        @include custom-hint(rotate(180deg) scaleY(-1));
    }

    .ld-date-select-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .ld-average-hint-arrow {
        @include custom-hint(rotate(180deg) scaleY(-1));
    }

    .ld-average-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

// help winndow for specific mode
.location-dashBoard-edit-hint-overlay {

    .de-topbar-hint-text,
    .de-hg-entity-hint-text,
    .de-hg-zoom-hint-text,
    .de-hg-drag-hint-text,
    .de-hg-flag-hint-text,
    .de-hg-clear-hint-text,
    .de-hg-multiple-hint-text,
    .de-hg-copy-hint-text,
    .de-hg-interpolate-hint-text,
    .de-hg-filter-hint-text,
    .de-hg-new-tab-hint-text,
    .de-hg-new-tab-close-hint-text,
    .de-highcharts-range-selector-group-text,
    .de-hg-full-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .de-topbar-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(1));
    }

    .de-hg-entity-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .de-hg-zoom-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .de-hg-drag-hint-arrow {
        @include custom-hint(rotate(360deg) scaleX(1));
    }

    .de-hg-flag-hint-arrow {
        @include custom-hint(rotate(160deg) scaleX(1));
    }

    .de-hg-clear-hint-arrow {
        @include custom-hint(rotate(200deg) scaleX(-1));
    }

    .de-hg-multiple-hint-arrow {
        @include custom-hint(rotate(225deg) scaleX(-1));
    }

    .de-hg-copy-hint-arrow {
        @include custom-hint(rotate(20deg) scaleX(-1));
    }

    .de-hg-interpolate-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .de-hg-filter-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .de-hg-new-tab-hint-arrow {
        @include custom-hint(rotate(130deg) scaleX(1));
    }

    .de-hg-new-tab-close-hint-arrow {
        @include custom-hint(rotate(130deg) scaleX(1));
    }

    .de-hg-full-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .de-highcharts-range-selector-group-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }
}

.de-accept-dialog-overlay {

    .de-dialog-entities-hint-text,
    .de-dialog-reason-hint-text,
    .de-dialog-comment-hint-text,
    .de-dialog-approve-hint-text,
    .de-dialog-cancel-hint-text,
    .de-dialog-accept-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .de-dialog-entities-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .de-dialog-reason-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(1));
    }

    .de-dialog-comment-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .de-dialog-approve-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .de-dialog-cancel-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(1));
    }

    .de-dialog-accept-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }
}

.location-dashBoard-edit-sg-hint-overlay {

    .de-sg-full-hint-text,
    .de-sg-select-hint-text,
    .de-sg-clear-hint-text,
    .de-sg-zoom-hint-text,
    .de-sg-flag-hint-text,
    .de-sg-unflag-hint-text,
    .de-sg-block-hint-text,
    .de-sg-snap-hint-text,
    .de-sg-curve-hint-text,
    .de-sg-tolerance-hint-text,
    .de-sg-tol-select-hint-text,
    .de-sg-generation-hint-text,
    .de-sg-sync-hint-text,
    .de-cancel-hint-text,
    .de-accept-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .de-sg-full-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .de-sg-select-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }

    .de-sg-clear-hint-arrow {
        @include custom-hint(rotate(30deg) scaleX(-1));
    }

    .de-sg-zoom-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .de-sg-flag-hint-arrow {
        @include custom-hint(rotate(30deg) scaleX(-1));
    }

    .de-sg-unflag-hint-arrow {
        @include custom-hint(rotate(150deg) scaleX(1));
    }

    .de-sg-block-hint-arrow {
        @include custom-hint(rotate(30deg) scaleX(-1));
    }

    .de-sg-snap-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .de-sg-curve-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .de-sg-tolerance-hint-arrow {
        @include custom-hint(rotate(30deg) scaleX(-1));
    }

    .de-sg-tol-select-hint-arrow {
        @include custom-hint(rotate(30deg) scaleX(-1));
    }

    .de-sg-generation-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .de-sg-sync-hint-arrow {
        @include custom-hint(rotate(330deg) scaleX(1));
    }

    .de-cancel-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }

    .de-accept-hint-arrow {
        @include custom-hint(rotate(30deg) scaleX(-1));
    }
}

// view location details
.view-location-details-screen-overlay {
    .edit-button-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .edit-button-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .collect-button-hint-arrow {
        @include custom-hint(rotate(0deg) scaleY(1));
    }

    .collect-button-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            right);
    }

    .schedule-collect-button-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .export-button-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .export-button-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            right);
    }

    .schedule-collect-button-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            right);
    }

    .percent-full-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .percent-full-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .daily-summary-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .daily-summary-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .uptime-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .uptime-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .details-hint-arrow {
        @include custom-hint(rotate(-90deg) scaleX(-1));
    }

    .details-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.view-location-associated-entity-screen-overlay {
    .copy-entity-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .copy-entity-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .add-entity-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .add-entity-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .ansr-edit-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(1));
    }

    .ansr-hint-arrow {
        @include custom-hint(rotate(-90deg) scaleX(-1));
    }

    .ansr-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .ansr-edit-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(1));
    }

    .ansr-edit-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .ansr-delete-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .ansr-delete-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.view-location-confirmation-points-screen-overlay {
    .download-confirmation-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .download-confirmation-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .add-confirmation-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }

    .add-confirmation-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .confirmation-edit-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(1));
    }

    .confirmation-edit-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .confirmation-delete-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .confirmation-delete-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .confirmation-hint-arrow {
        @include custom-hint(rotate(-90deg) scaleX(-1));
    }

    .confirmation-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.view-location-files-screen-overlay {
    .file-share-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .file-share-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .create-folder-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .create-folder-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            right);
    }

    .show-hide-subfolders-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .show-hide-subfolders-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            right);
    }

    .upload-file-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .upload-file-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            right);
    }

    .files-hint-arrow {
        @include custom-hint(rotate(-90deg) scaleX(-1));
    }

    .files-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .file-share-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }

    .file-share-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            right);
    }

    .file-download-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .file-download-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            right);
    }

    .file-import-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .file-import-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            right);
    }

    .file-delete-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .file-delete-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            right);
    }
}

.view-location-notes-screen-overlay {
    .add-note-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }

    .add-note-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .filter-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .filter-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .notes-hint-arrow {
        @include custom-hint(rotate(-90deg) scaleX(-1));
    }

    .notes-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.view-location-advanced-screen-overlay {
    .advanced-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .advanced-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            right);
    }

    .advanced-filter-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }

    .advanced-filter-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            right);
    }

    .diagnostics-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .diagnostics-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            right);
    }

    .date-picker-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .date-picker-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            right);
    }

    .date-time-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .date-time-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            right);
    }

    .user-name-hint-arrow {
        @include custom-hint(rotate(00deg) scaleX(1));
    }

    .user-name-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            right);
    }

    .result-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .result-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            right);
    }
}

.view-location-flow-balance-screen-overlay {
    .generate-button-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }

    .generate-button-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .flow-balance-edit-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .flow-balance-edit-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            right);
    }

    .flow-balance-hint-arrow {
        @include custom-hint(rotate(-90deg) scaleX(-1));
    }

    .flow-balance-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.customDashboardDialogHinrOverlay {
    .cd-hg-hint-text,
    .cd-sg-hint-text,
    .cd-dialog-date-hint-text,
    .cd-dialog-name-hint-text,
    .cd-dialog-rolling-hint-text,
    .cd-dialog-entity-hint-text,
    .cd-dialog-color-hint-text,
    .cd-dialog-delete-hint-text,
    .cd-dialog-add-more-hint-text,
    .cd-dialog-y-entity-hint-text,
    .cd-dialog-x-entity-hint-text,
    .cd-dialog-data-averag-hint-text,
    .cd-dialog-close-hint-text,
    .cd-dialog-save-hint-text,
    .cd-dialog-location-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
    }

    .cd-hg-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }
    .cd-sg-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }
    .cd-dialog-date-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }
    .cd-dialog-name-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }
    .cd-dialog-rolling-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .cd-dialog-entity-hint-arrow {
        @include custom-hint(rotate(75deg) scaleX(1));
    }
    .cd-dialog-color-hint-arrow {
        @include custom-hint(rotate(140deg) scaleX(1));
    }
    .cd-dialog-delete-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }
    .cd-dialog-add-more-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(1));
    }
    .cd-dialog-y-entity-hint-arrow {
        @include custom-hint(rotate(50deg) scaleX(-1));
    }
    .cd-dialog-x-entity-hint-arrow {
        @include custom-hint(rotate(50deg) scaleX(-1));
    }
    .cd-dialog-data-averag-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }
    .cd-dialog-close-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(1));
    }
    .cd-dialog-save-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }
    .cd-dialog-location-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }

}

.customDashboardHintOverlay {

    .cd-print-hint-text,
    .cd-add-hint-text,
    .cd-full-hint-text,
    .cd-edit-hint-text,
    .cd-delete-hint-text,
    .cd-zoom-hint-text,
    .cd-tracer-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .cd-print-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }

    .cd-add-hint-arrow {
        @include custom-hint(rotate(140deg) scaleX(1));
    }

    .cd-full-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }

    .cd-edit-hint-arrow {
        @include custom-hint(rotate(35deg) scaleX(-1));
    }

    .cd-delete-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .cd-zoom-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .cd-tracer-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }
}

.dynamicdbOverlay {
    .addNewDynamicDbHintArrow {
        @include custom-hint(rotate(180deg));
    }

    .addNewDynamicDbHintText {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            right);
    }
}

.vaultOverlay {
    .vault-folders-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .vault-folders-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .vault-hide-folders-hint-arrow {
        @include custom-hint(rotate(210deg) scaleX(-1));
    }

    .vault-hide-folders-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .vault-breadcrumb-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .vault-breadcrumb-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .vault-search-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .vault-search-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .vault-create-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(1));
    }

    .vault-create-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .vault-hide-sub-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .vault-hide-sub-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .vault-list-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .vault-list-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .vault-delete-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .vault-delete-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.vaultSecondListOverlay {
    .vault-file-share-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(1));
    }

    .vault-file-share-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .vault-file-download-hint-arrow {
        @include custom-hint(rotate(20deg) scaleX(-1));
    }

    .vault-file-download-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .vault-file-import-hint-arrow {
        @include custom-hint(rotate(210deg) scaleX(-1));
    }

    .vault-file-import-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .vault-file-delete-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .vault-file-delete-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.vaultSecondOverlay {
    .vault-file-share-hint-arrow {
        @include custom-hint(rotate(120deg) scaleX(-1));
    }

    .vault-file-share-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .vault-file-download-hint-arrow {
        @include custom-hint(rotate(60deg) scaleX(-1));
    }

    .vault-file-download-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .vault-file-import-hint-arrow {
        @include custom-hint(rotate(210deg) scaleX(-1));
    }

    .vault-file-import-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .vault-file-delete-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .vault-file-delete-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.vaultSecondOverlay,
.vaultSecondListOverlay {
    .vault-toggle-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .vault-toggle-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .vault-file-check-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }

    .vault-file-check-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .vault-file-icon-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .vault-file-icon-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .vault-top-move-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(1));
    }

    .vault-top-move-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .vault-top-delete-hint-arrow {
        @include custom-hint(rotate(20deg) scaleX(-1));
    }

    .vault-top-delete-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .vault-top-download-hint-arrow {
        @include custom-hint(rotate(135deg) scaleX(1));
    }

    .vault-top-download-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .vault-top-import-hint-arrow {
        @include custom-hint(rotate(145deg) scaleX(1));
    }

    .vault-top-import-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.vaultThirdOverlay {
    .vault-upload-file-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .vault-upload-file-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .vault-upload-location-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }

    .vault-upload-location-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .vault-upload-data-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(1));
    }

    .vault-upload-data-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .vault-export-new-hint-arrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .vault-export-new-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .vault-export-load-hint-arrow {
        @include custom-hint(rotate(35deg) scaleX(1));
    }

    .vault-export-load-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .vault-export-recent-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .vault-export-recent-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.vaultDialogOverlay {
    .vault-dialog-download-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
      }
      .vault-dialog-download-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
      }

      .vault-dialog-type-hint-arrow {
        @include custom-hint(rotate(135deg) scaleX(-1));
      }
      .vault-dialog-type-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
      }

      .vault-dialog-locations-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
      }
      .vault-dialog-locations-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
      }

      .vault-dialog-rounding-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
      }
      .vault-dialog-rounding-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
      }

      .vault-dialog-inactive-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
      }
      .vault-dialog-inactive-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
      }

      .vault-dialog-date-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
      }
      .vault-dialog-date-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
      }

      .vault-dialog-value-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
      }
      .vault-dialog-value-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
      }

      .vault-dialog-multiple-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
      }
      .vault-dialog-multiple-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
      }

      .vault-dialog-average-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
      }
      .vault-dialog-average-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff, left);
      }

    .vault-dialog-entity-hint-arrow {
        @include custom-hint(rotate(120deg) scaleX(-1));
    }

    .vault-dialog-entity-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .vault-dialog-timestamp-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(-1));
    }

    .vault-dialog-timestamp-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .vault-dialog-file-type-hint-arrow {
        @include custom-hint(rotate(75deg) scaleX(1));
    }

    .vault-dialog-file-type-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .vault-dialog-entity-col-hint-arrow {
        @include custom-hint(rotate(60deg) scaleX(1));
    }

    .vault-dialog-entity-col-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .vault-dialog-settings-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .vault-dialog-settings-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .vault-dialog-close-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .vault-dialog-close-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .vault-dialog-export-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .vault-dialog-export-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.dailySummaryOverviewOverlay {
    .daily-locations-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .daily-locations-hint-arrow {
        @include custom-hint(rotate(220deg) scaleX(-1));
    }

    .daily-entity-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .daily-entity-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(1));
    }

    .daily-date-picker-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .daily-date-picker-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(1));
    }

    .daily-title-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .daily-title-hint-arrow {
        @include custom-hint(rotate(320deg) scaleX(1));
    }

    .daily-hide-tile-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .daily-hide-tile-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .daily-tiles-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .daily-tiles-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .daily-table-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .daily-table-hint-arrow {
        @include custom-hint(rotate(135deg) scaleX(1));
    }

    .daily-values-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .daily-values-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .daily-download-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .daily-download-hint-arrow {
        @include custom-hint(rotate(90deg) scaleX(1));
    }
}

.dailySummaryDetailOverlay {
    .dailySummaryDetailsFilterHintArrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .dailySummaryDetailsFilterHintText {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            right);
    }

    .dailySummaryDetailsDownloadHintArrow {
        @include custom-hint(rotate(90deg));
    }

    .dailySummaryDetailsDownloadHintText {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            right);
    }
}

.percentFullOverlay {
    .percentTitleHintArrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .percentTitleHintText {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .percentFullFilterHintArrow {
        @include custom-hint(rotate(180deg) scaleX(1));
    }

    .percentFullFilterHintText {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .percentFullDownloadHintArrow {
        @include custom-hint(rotate(120deg) scaleX(-1));
    }

    .percentFullDownloadHintText {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .percent-yesterday-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .percent-yesterday-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .percent-date-column-hint-arrow {
        @include custom-hint(rotate(0deg) scaleX(1));
    }

    .percent-date-column-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .percent-item-hint-arrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .percent-item-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .percent-locations-hint-arrow {
        @include custom-hint(rotate(145deg) scaleX(1));
    }

    .percent-locations-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .percent-date-hint-arrow {
        @include custom-hint(rotate(330deg) scaleX(1));
    }

    .percent-date-hint-text {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.uptimeDetailOverlay {
    .uptimeDetailsFilterHintArrow {
        @include custom-hint(rotate(0deg) scaleX(-1));
    }

    .uptimeDetailsFilterHintText {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            right);
    }

    .uptimeDetailsDownloadHintArrow {
        @include custom-hint(rotate(90deg));
    }

    .uptimeDetailsDownloadHintText {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            right);
    }
}

.adminScreenOverlay {
    .addNewUserHintArrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .addNewUserHintText {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.adminDetailsHint {
    .customerAssociationContiner {
        .mat-tab-header {
            z-index: 99999;
        }
    }

    .userDetailsHint,
    .assignRolesUserHint,
    .assignCustomersUserHint,
    .assignLocationsUserHint,
    .assignFeaturesUserHint {
        z-index: 99999;
        position: relative;
        color: #fff;
    }

    .adminDetailsOverlay {
        display: block;
    }
}

.adminDetailsOverlay {
    .userDetailsHintArrow {
        @include custom-hint(rotate(0deg));
    }

    .userDetailsHintText {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .assignRolesUserHintArrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .assignRolesUserHintText {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .assignCustomersUserHintArrow {
        @include custom-hint(rotate(0deg));
    }

    .assignCustomersUserHintText {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .assignLocationsUserHintArrow {
        @include custom-hint(rotate(180deg) scaleX(-1));
    }

    .assignLocationsUserHintText {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .assignFeaturesUserHintArrow {
        @include custom-hint(rotate(0deg));
    }

    .assignFeaturesUserHintText {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.hint-flow-monitoring-report {

    .fmrHintFFlowMonitoringReport,
    .fmrHintLocationGroup,
    .fmrHintSearch,
    .fmrHintExport {
        z-index: 99999;
        position: relative;
        background: #fff;
    }

    .fmrHintIncludeInactiveLocations {
        z-index: 99999;
        position: relative;
        background: #fff;
        padding: 5px 0px;
    }

    .flowMonitoringReportOverlay {
        display: block;
    }
}

.hint-notification-dashboard-report {
    .notificationDashBoardOverlay {
        color: #fff;
        display: block;

        .notification-dashboard-hint-arrow {
            @include custom-hint(rotate(20deg) scaleY(-1));
        }

        .notification-dashboard-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .add_notification-hint-arrow {
            @include custom-hint(rotate(20deg) scaleY(-1));
        }

        .add_notification-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .filter_notification-hint-arrow {
            @include custom-hint(rotate(50deg) scaleY(1));
        }

        .filter_notification-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .name_notification-hint-arrow {
            @include custom-hint(rotate(20deg) scaleY(-1));
        }

        .name_notification-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .Name-filter_notification-hint-arrow {
            @include custom-hint(rotate(55deg) scaleY(-1));
        }

        .Name-filter_notification-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .users_notification-hint-arrow {
            @include custom-hint(rotate(80deg) scaleY(-1));
        }

        .users_notification-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .alarm_type_notification-hint-arrow {
            @include custom-hint(rotate(-45deg) scaleY(1));
        }

        .alarm_type_notification-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .location_Filter_Type_notification-hint-arrow {
            @include custom-hint(rotate(120deg) scaleY(-1));
        }

        .location_Filter_Type_notification-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .reset_notification-hint-arrow {
            @include custom-hint(rotate(70deg) scaleY(1));
        }

        .reset_notification-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .apply_notification-hint-arrow {
            @include custom-hint(rotate(300deg) scaleY(-1));
        }

        .apply_notification-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .Name_notification-hint-arrow {
            @include custom-hint(rotate(55deg) scaleY(-1));
        }

        .Name_notification-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .Description_notification-hint-arrow {
            @include custom-hint(rotate(55deg) scaleY(-1));
        }

        .Description_notification-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .Type_notification-hint-arrow {
            @include custom-hint(rotate(55deg) scaleY(-1));
        }

        .Type_notification-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .list_notification-hint-arrow {
            @include custom-hint(rotate(20deg) scaleY(-1));
        }

        .list_notification-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .enable_notification-hint-arrow {
            @include custom-hint(rotate(20deg) scaleY(-1));
        }

        .enable_notification-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .edit_notification-hint-arrow {
            @include custom-hint(rotate(220deg) scaleY(-1));
        }

        .edit_notification-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .delete_notification-hint-arrow {
            @include custom-hint(rotate(120deg) scaleY(-1));
        }

        .delete_notification-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }
    }
}

.hint-notification-dashboard-report-standard {
    .nd-common-locations {
        color: #fff;
        display: block;

        .nd-common-Name-hint-arrow {
            @include custom-hint(rotate(20deg) scaleY(-1));
        }

        .nd-common-Name-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-common-Description-hint-arrow {
            @include custom-hint(rotate(20deg) scaleY(-1));
        }

        .nd-common-Description-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-common-Enable-hint-arrow {
            @include custom-hint(rotate(270deg) scaleY(-1));
        }

        .nd-common-Enable-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-common-Search-hint-arrow {
            @include custom-hint(rotate(20deg) scaleY(-1));
        }

        .nd-common-Search-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-common-Location_Group-hint-arrow {
            @include custom-hint(rotate(220deg) scaleY(-1));
        }

        .nd-common-Location_Group-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-common-Locations-hint-arrow {
            @include custom-hint(rotate(100deg) scaleY(-1));
        }

        .nd-common-Locations-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-common-Next-hint-arrow {
            @include custom-hint(rotate(44deg) scaleY(-1));
        }

        .nd-common-Next-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }
    }
}

.hint-notification-dashboard-report-standard-alarms {
    .nd-common-alarms {
        color: #fff;
        display: block;

        .nd-common-alarms-Name-hint-arrow {
            @include custom-hint(rotate(20deg) scaleY(-1));
        }

        .nd-common-alarms-Name-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-common-alarms-Description-hint-arrow {
            @include custom-hint(rotate(20deg) scaleY(-1));
        }

        .nd-common-alarms-Description-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-common-alarms-Enable-hint-arrow {
            @include custom-hint(rotate(270deg) scaleY(-1));
        }

        .nd-common-alarms-Enable-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-common-alarms-Alarm_List-hint-arrow {
            @include custom-hint(rotate(220deg) scaleY(-1));
        }

        .nd-common-alarms-Alarm_List-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-common-alarms-Back-hint-arrow {
            @include custom-hint(rotate(100deg) scaleY(1));
        }

        .nd-common-alarms-Back-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-common-alarms-Next-hint-arrow {
            @include custom-hint(rotate(100deg) scaleY(-1));
        }

        .nd-common-alarms-Next-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }
    }
}

.hint-notification-dashboard-report-standard-notification-tree {
    .nd-notification-tree {
        color: #fff;
        display: block;

        .nd-notification-tree-Name-hint-arrow {
            @include custom-hint(rotate(20deg) scaleY(-1));
        }

        .nd-notification-tree-Name-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-notification-tree-Description-hint-arrow {
            @include custom-hint(rotate(20deg) scaleY(-1));
        }

        .nd-notification-tree-Description-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-notification-tree-Enable-hint-arrow {
            @include custom-hint(rotate(270deg) scaleY(-1));
        }

        .nd-notification-tree-Enable-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-notification-tree-Add_Level-hint-arrow {
            @include custom-hint(rotate(270deg) scaleY(-1));
        }

        .nd-notification-tree-Add_Level-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-notification-tree-Level_Name-hint-arrow {
            @include custom-hint(rotate(0deg) scaleY(1));
        }

        .nd-notification-tree-Level_Name-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-notification-tree-Delay-hint-arrow {
            @include custom-hint(rotate(270deg) scaleY(-1));
        }

        .nd-notification-tree-Delay-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-notification-tree-User_Count-hint-arrow {
            @include custom-hint(rotate(270deg) scaleY(-1));
        }

        .nd-notification-tree-User_Count-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-notification-tree-Enable_Level-hint-arrow {
            @include custom-hint(rotate(270deg) scaleY(-1));
        }

        .nd-notification-tree-Enable_Level-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-notification-tree-Edit-hint-arrow {
            @include custom-hint(rotate(-20deg) scaleY(-1));
        }

        .nd-notification-tree-Edit-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-notification-tree-delete-hint-arrow {
            @include custom-hint(rotate(20deg) scaleY(-1));
        }

        .nd-notification-tree-delete-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-notification-tree-Back-hint-arrow {
            @include custom-hint(rotate(100deg) scaleY(1));
        }

        .nd-notification-tree-Back-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-notification-tree-Next-hint-arrow {
            @include custom-hint(rotate(100deg) scaleY(-1));
        }

        .nd-notification-tree-Next-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }
    }
}

.hint-notification-dashboard-report-daily {
    .nd-daily-report {
        color: #fff;
        display: block;

        .nd-daily-report-Name-hint-arrow {
            @include custom-hint(rotate(20deg) scaleY(-1));
        }

        .nd-daily-report-Name-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-daily-report-Description-hint-arrow {
            @include custom-hint(rotate(20deg) scaleY(-1));
        }

        .nd-daily-report-Description-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-daily-report-Enable-hint-arrow {
            @include custom-hint(rotate(270deg) scaleY(-1));
        }

        .nd-daily-report-Enable-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-daily-report-communication-hint-arrow {
            @include custom-hint(rotate(50deg) scaleY(-1));
        }

        .nd-daily-report-communication-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-daily-report-low_battery-hint-arrow {
            @include custom-hint(rotate(115deg) scaleY(-1));
        }

        .nd-daily-report-low_battery-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-daily-report-select_days-hint-arrow {
            @include custom-hint(rotate(50deg) scaleY(-1));
        }

        .nd-daily-report-select_days-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-daily-report-report_at-hint-arrow {
            @include custom-hint(rotate(110deg) scaleY(-1));
        }

        .nd-daily-report-report_at-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-daily-report-Back-hint-arrow {
            @include custom-hint(rotate(100deg) scaleY(1));
        }

        .nd-daily-report-Back-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-daily-report-Next-hint-arrow {
            @include custom-hint(rotate(100deg) scaleY(-1));
        }

        .nd-daily-report-Next-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }
    }
}

.hint-notification-dashboard-report-standard-validation {
    .nd-validation {
        color: #fff;
        display: block;

        .nd-validation-Name-hint-arrow {
            @include custom-hint(rotate(20deg) scaleY(-1));
        }

        .nd-validation-Name-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-validation-Description-hint-arrow {
            @include custom-hint(rotate(20deg) scaleY(-1));
        }

        .nd-validation-Description-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-validation-Enable-hint-arrow {
            @include custom-hint(rotate(270deg) scaleY(-1));
        }

        .nd-validation-Enable-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-validation-Location-hint-arrow {
            @include custom-hint(rotate(0deg) scaleY(-1));
        }

        .nd-validation-Location-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-validation-List-hint-arrow {
            @include custom-hint(rotate(0deg) scaleY(-1));
        }

        .nd-validation-List-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-validation-Remove_from_notification-hint-arrow {
            @include custom-hint(rotate(-285deg) scaleY(-1));
        }

        .nd-validation-Remove_from_notification-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-validation-Back-hint-arrow {
            @include custom-hint(rotate(100deg) scaleY(1));
        }

        .nd-validation-Back-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-validation-Next-hint-arrow {
            @include custom-hint(rotate(100deg) scaleY(-1));
        }

        .nd-validation-Next-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }
    }
}

.hint-notification-dashboard-report-ANSR-alarms {
    .nd-ANSR-alarms {
        color: #fff;
        display: block;

        .nd-ANSR-alarms-Name-hint-arrow {
            @include custom-hint(rotate(20deg) scaleY(-1));
        }

        .nd-ANSR-alarms-Name-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-ANSR-alarms-Description-hint-arrow {
            @include custom-hint(rotate(20deg) scaleY(-1));
        }

        .nd-ANSR-alarms-Description-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-ANSR-alarms-Enable-hint-arrow {
            @include custom-hint(rotate(270deg) scaleY(-1));
        }

        .nd-ANSR-alarms-Enable-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-ANSR-alarms-Source_Alarm-hint-arrow {
            @include custom-hint(rotate(45deg) scaleY(-1));
        }

        .nd-ANSR-alarms-Source_Alarm-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-ANSR-alarms-Entity-hint-arrow {
            @include custom-hint(rotate(40deg) scaleY(-1));
        }

        .nd-ANSR-alarms-Entity-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-ANSR-alarms-Variable-hint-arrow {
            @include custom-hint(rotate(34deg) scaleY(-1));
        }

        .nd-ANSR-alarms-Variable-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-ANSR-alarms-Add-hint-arrow {
            @include custom-hint(rotate(315deg) scaleY(-1));
        }

        .nd-ANSR-alarms-Add-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-ANSR-alarms-equation-hint-arrow {
            @include custom-hint(rotate(77deg) scaleY(-1));
        }

        .nd-ANSR-alarms-equation-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-ANSR-alarms-Back-hint-arrow {
            @include custom-hint(rotate(100deg) scaleY(1));
        }

        .nd-ANSR-alarms-Back-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-ANSR-alarms-Next-hint-arrow {
            @include custom-hint(rotate(100deg) scaleY(-1));
        }

        .nd-ANSR-alarms-Next-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }
    }
}

.hint-notification-dashboard-report-alarms-confirm {
    .nd-alarms-confirm {
        color: #fff;
        display: block;

        .nd-alarms-summary-name-hint-text,
        .nd-alarms-summary-description-hint-text,
        .nd-alarms-summary-location-hint-text,
        .nd-ANSR-alarms-summary-alarm-hint-text,
        .nd-daily-alarms-summary-alarm-hint-text,
        .nd-standard-alarms-summary-alarm-hint-text,
        .nd-alarms-summary-levels-hint-text,
        .nd-daily-alarms-summary-levels-hint-text {
            @include custom-hint(unset);
            @include custom-hint-text(#fff,
                left);
        }

        .nd-alarms-summary-location-hint-arrow,
        .nd-alarms-summary-alarm-hint-arrow,
        .nd-alarms-summary-levels-hint-arrow {
            @include custom-hint(rotate(0deg) scaleY(1));
        }

        .nd-alarms-summary-name-hint-arrow,
        .nd-alarms-summary-description-hint-arrow {
            @include custom-hint(rotate(20deg) scaleY(-1));
        }
    }
}

.nd-hint,
.nd-hint-add_notification,
.nd-hint-filter_notification,
.nd-hint-name_notification,
.nd-hint-location_notification {
    position: relative;
}

.flowMonitoringReportOverlay {
    .fmrHintFFlowMonitoringReportArrow {
        @include custom-hint(rotate(240deg));
    }

    .fmrHintFlowMonitoringReportText {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .fmrHintLocationGroupArrow {
        @include custom-hint(rotate(240deg));
    }

    .fmrHintLocationGroupText {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .fmrHintLocationGroupListArrow {
        @include custom-hint(rotate(100deg) scaleX(-1));
    }

    .fmrHintLocationGroupListText {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .fmrHintIncludeInactiveLocationsArrow {
        @include custom-hint(rotate(180deg));
    }

    .fmrHintIncludeInactiveLocationsText {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .fmrHintSearchArrow {
        @include custom-hint(rotate(180deg));
    }

    .fmrHintSearchText {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .fmrHintDateRangeArrow {
        @include custom-hint(rotate(270deg));
    }

    .fmrHintDateRangeText {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .fmrHintDataAveragingArrow {
        @include custom-hint(rotate(270deg));
    }

    .fmrHintDataAveragingText {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .fmrHintGroupByArrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .fmrHintGroupByText {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .fmrHintEntitiesArrow {
        @include custom-hint(rotate(270deg));
    }

    .fmrHintEntitiesText {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .fmrHintSectionsToBeExportedArrow {
        @include custom-hint(rotate(270deg));
    }

    .fmrHintSectionsToBeExportedText {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .fmrHintExportFormatArrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .fmrHintExportFormatText {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .fmrHintSGOptionsArrow {
        @include custom-hint(rotate(270deg) scaleX(-1));
    }

    .fmrHintSGOptionsText {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }

    .fmrHintExportArrow {
        @include custom-hint(rotate(100deg) scaleX(-1));
    }

    .fmrHintExportText {
        @include custom-hint(unset);
        @include custom-hint-text(#fff,
            left);
    }
}

.homePageAction {
    position: fixed;
    bottom: 10px;
    right: 10px;

    .mat-button,
    .mat-raised-button {
        min-width: 10px;
        margin-right: 8px;
        font-size: 14px;
        min-width: unset;
        padding: 0 10px;
        text-transform: uppercase;
    }
}

.left-action {
    right: unset !important;
    left: 10px !important;
}
