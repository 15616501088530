#crowdCore .primary {
    margin-top: 15px;
    border: 2px solid #707070 !important;
    background-color: #ffffff !important;
    text-transform: uppercase;
}

#crowdCore .warn {
    margin-top: 15px;
    border: 2px solid #1a936f !important;
    background-color: #1a936f !important;
    color: #ffffff !important;
    text-transform: uppercase;
}

#crowdCore .submit {
    border: 2px solid #f44336 !important;
    color: #ffffff !important;
    background-color: #f44336;
    text-transform: uppercase;
}

.leader {
    font-size: 12pt;
}

#crowdCore .counter,
.description {
    text-align: right;
    background-color: rgb(239, 83, 80);
    color: rgb(255, 255, 255) !important;
}

#crowdCore p.counter {
    font-weight: 100;
    font-size: 55px;
    line-height: 56px;
    margin: 0;
}

#autoscrubsummary .mat-select-placeholder,
#autoscrubsummary .label {
    color: #2196f3;
    padding-top: 16px;
    padding-bottom: 8px;
}

#autoscrubsummary th {
    background-color: #2196f3;
    color: #ffffff;
    border-radius: 0;
}

#autoscrubsummary .mat-select-value {
    padding-top: 8px;
    padding-bottom: 8px;
}

#autoscrubsummary i {
}

#autoscrubsummary material-datepicker input {
}
