@import 'assets/css/date-time-picker.css';
@import 'assets/scss/_variables.scss';
@import 'assets/scss/_colors.scss';
@import 'assets/scss/_flex.scss';
@import 'assets/scss/_navigation.scss';
@import 'assets/scss/_typography.scss';
@import 'assets/scss/_cards.scss';
@import 'assets/scss/_tables.scss';
@import 'assets/scss/_custom-svg-icon.scss';
@import 'assets/scss/_angular-material-overload.scss';
@import 'assets/scss/_hint.scss';
@import 'assets/scss/_crowd-core.scss';
@import 'assets/scss/_qstart.scss';
@import 'assets/scss/_dashboard.scss';
@import 'assets/scss/_blackTheme.scss';
@import 'assets/scss/date-time-picker-overload.scss';
@import 'flexmonster/flexmonster.min.css';
@import '~ol/ol.css';
/***************
  HTML Styles
***************/

.lc-chart {
    width: 100%;
    height: 600px;
}

.background-light {
    background: #ededed;
}

.background-default {
    background: #ffffff;
}

.flow-balance-report-print-page-sizing {
    padding: 0px;
    margin: 0px;
    width: 400mm;
    height: 400mm;
}

.one-page-print {
    height: 100vh;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
}

body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue';
}

main {
    height: 100%;

    .mat-sidenav {
        height: 100vh;
    }

    .mat-drawer-content {
        height: 100vh;
    }
}

.clearfix {
    clear: both;
}

p.box {
    padding: 20px;
}

p {
    color: rgba(0, 0, 0, 0.71);
    padding: 0;
    -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    -webkit-font-smoothing: antialiased;
}

h5>span {
    font-size: 14px;
    margin-left: 15px;
    color: #777;
}

.content {
    &.padded {
        padding: 20px 20px 0;
    }
}

.contentLayout {
    padding: 0 20px 0 20px;
}

.fullPageloader {
    .loader-position {
        position: fixed;
    }
}

.validation-error {
    color: red;
}

// overriding defult MCC color picker
mcc-color-picker {
    .btn-picker {
        width: 20px !important;
        height: 20px !important;
        border: 1px solid #ddd !important;
        position: relative;

        .transparent {
            width: 25px !important;
            transform: translateY(-2px) translateX(-8px) rotate(45deg);
            -webkit-transform: translateY(-2px) translateX(-8px) rotate(45deg);
        }
    }
}

.mcc-color-picker-transparent {
    height: 5px !important;
    width: 25px !important;
    transform: translateY(-2px) translateX(-8px) rotate(45deg) !important;
}

.mat-tooltip {
    max-width: 100%;
    overflow-wrap: break-word;
}


.hover {
    background: #eee;
}

.selection {
    background: #bbb;
}

.modal-dialog {
    &.contentLayout {
        min-height: 92%;

        mat-dialog-content {
            &.mat-dialog-content {
                margin: 0;
                padding: 0;
            }
        }
    }

    background-color: #fff;

    .heading {
        h2 {
            float: left;

            mat-icon {
                &+.sub-title {
                    margin: 0 0 0 40px;
                }
            }
        }

        button {
            &.mat-icon-button {
                float: right;
            }
        }
    }
}

.mat-dialog-title {
    mat-icon {
        margin: -4px 10px 0 0;
        vertical-align: middle;
    }

    div.sub-title,
    p.sub-title {
        color: #777;
        font-size: 0.8rem;
        font-weight: normal;
        margin-top: 0;
    }
}

.add-location-menu {
    max-width: unset !important;
}

/* -- Material Design Table style -------------- */

// Variables
// ---------------------
$light-table-header-font-weight: 600;
$light-table-header-font-color: #ffffff;
$light-table-header-accent-font-color: #ccc;
$light-table-body-color: #555;
$light-table-cell-padding: 15px 13px;
$light-table-font-size: 0.8rem;
$light-table-condensed-cell-padding: 9px 13px;
$light-table-bg: #fff;
$light-table-bg-accent: #f5f5f5;
$table-bg-hover: rgba(0, 0, 0, 0.12);
$table-bg-active: $table-bg-hover;
$table-border-color: rgba(0, 0, 0, 0.12);
$table-header-color: #f5f5f5;

//
// -----------------
// Baseline styles
.light-table {
    background-color: $light-table-bg;
    border-collapse: collapse;
    font-size: $light-table-font-size;
    margin-bottom: 2rem;
    max-width: 100%;
    width: 100%;

    >thead>tr {
        &:first-child {
            background-color: $table-header-color;
            color: rgba(0, 0, 0, 0.87);
        }

        &:last-child {
            color: rgba(0, 0, 0, 0.54);
        }

        >th {
            //color: $light-table-header-font-color;
            font-weight: $light-table-header-font-weight;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            padding: $light-table-cell-padding;
            vertical-align: bottom;
        }
    }

    >th {
        color: $light-table-header-font-color;
        font-weight: $light-table-header-font-weight;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        padding: $light-table-cell-padding;
        vertical-align: bottom;
    }

    >caption+thead,
    >colgroup+thead,
    >thead:first-child {
        >tr:first-child {

            >th,
            >td {
                border-top: 0;
            }
        }
    }

    >tbody+tbody {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
    }

    // Nesting
    .table {
        background-color: $light-table-bg;
    }

    // Remove border
    .no-border {
        border: 0;
    }

    >colgroup :nth-child(even) {
        background-color: unset;
    }
}

// Condensed table w/ half padding
.light-table-condensed {

    >thead,
    >tbody,
    >tfoot {
        >tr {

            >th,
            >td {
                padding: $light-table-condensed-cell-padding;
            }
        }
    }
}

// Bordered version
//
// Add horizontal borders between columns.
.light-table-bordered {
    border: 0;

    >thead,
    >tbody,
    >tfoot {
        >tr {

            >th,
            >td {
                border: 0;
                border-bottom: 1px solid $table-border-color;
                border-radius: 0px;
            }

            >td {
                border-right: 1px solid $table-border-color;

                &:last-child {
                    border-right: 0px solid $table-border-color;
                }
            }
        }
    }
}

// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)
.light-table-striped {
    >tbody>tr:nth-child(odd) {

        >td,
        >th {
            background-color: $light-table-bg-accent;
        }
    }
}

// Hover effect
//
.light-table-hover {
    >tbody>tr:hover {

        >td,
        >th {
            background-color: $table-bg-hover;
        }
    }
}

// Responsive tables (vertical)
//
// Wrap your tables in `.table-responsive-vertical` and we'll make them mobile friendly
// by vertical table-cell display. Only applies <768px. Everything above that will display normally.
// For correct display you must add 'data-title' to each 'td'
.light-table-responsive-vertical {

    // Tighten up spacing
    >.table {
        background-color: transparent;
        margin-bottom: 0;

        >thead,
        >tfoot {
            display: none;
        }

        >tbody {
            display: block;

            >tr {
                border: 1px solid $table-border-color;
                display: block;
                margin-bottom: $light-table-cell-padding;

                >td {
                    background-color: $light-table-bg;
                    display: block;
                    text-align: right;
                }
            }
        }
    }

    // Special overrides for the bordered tables
    >.table-bordered {
        border: 0; // Nuke the appropriate borders so that the parent can handle them

        >tbody {
            >tr {
                >td {
                    border: 0;
                    border-bottom: 1px solid $table-border-color;
                }

                >td:last-child {
                    border-bottom: 0;
                }
            }
        }
    }

    // Special overrides for the striped tables
    >.table-striped {

        >tbody>tr>td,
        >tbody>tr:nth-child(odd) {
            background-color: $light-table-bg;
        }

        >tbody>tr>td:nth-child(odd) {
            background-color: $light-table-bg-accent;
        }
    }

    // Special overrides for hover tables
    >.table-hover {
        >tbody {

            >tr:hover>td,
            >tr:hover {
                background-color: $light-table-bg;
            }

            >tr>td:hover {
                background-color: $table-bg-hover;
            }
        }
    }
}

// CSS/LESS Color variations
//
// --------------------------------
.light-table-striped.table-mc-red>tbody>tr:nth-child(odd)>td,
.light-table-striped.table-mc-red>tbody>tr:nth-child(odd)>th {
    background-color: #fde0dc;
}

.light-table-hover.table-mc-red>tbody>tr:hover>td,
.light-table-hover.table-mc-red>tbody>tr:hover>th {
    background-color: #f9bdbb;
}

.logo {
    height: 45px;
    min-width: 160px;
    width: auto !important;

    @media (max-width: 768px) {
        height: 40%;
    }

    margin: 0 20px 0 0;
}

mat-toolbar {
    &.mat-toolbar {
        background-color: #263238;
        padding: 0;

        mat-toolbar-row {
            &.mat-toolbar-row {
                background-color: rgba(255, 255, 255, 1);
                padding: 0 16px;
                justify-content: center;

                &.main-nav-menu {
                    background-color: rgba(0, 0, 0, 0);
                    font-size: 0.8rem;
                    height: auto;
                    padding: 0 10px;
                    z-index: 9;

                    .mat-button,
                    .mat-icon-button,
                    .mat-raised-button {
                        color: #fff;
                        padding: 10px 15px;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}

//hide back screen
.hidebackContent {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    pointer-events: auto;
    -webkit-tap-highlight-color: transparent;
    -webkit-transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    background: rgba(0, 0, 0, 0.95);

    #confirmation-dialog {
        width: 350px;
        background: #fff;
        position: absolute;
        top: 40%;
        left: calc((100% - 350px) / 2);
        border-radius: 5px;
        padding: 15px;
    }
}

.baseMap {
    max-width: 416px !important;

    .mat-button-toggle-group-appearance-standard {
        border: none;

        .mat-button-toggle {
            border-left: none;
        }
    }
}

.customCheck mat-pseudo-checkbox.mat-option-pseudo-checkbox.mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
    background: #5cb259;
}

// overide defult
.customCheck.mat-option.mat-option-multiple {
    color: rgba(0, 0, 0, 0.87) !important;
}

// auto-complete
//
// -------------------------------
.mat-icon.clickable {
    cursor: pointer;
}

app-multi-select-group,
ads-prism-single-select-group {
    position: relative;
}

app-entity-selector,
app-multi-select-group,
ads-prism-single-select-group {
    cursor: pointer;

    .multi-entity-input {
        input {
            width: calc(100% - 30px);
            position: relative;
        }

        .mat-icon {
            position: absolute;
            right: 1px;
            top: 2px;
        }
    }

    .multi-select-group-list {
        padding: 0;
        background: #fff;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
            0 3px 14px 2px rgba(0, 0, 0, 0.12);
        margin-top: -15px;
        max-height: 30vh;
        min-width: 400px;
        max-width: 100%;
        overflow: auto;
        position: absolute;
        z-index: 2000;

        .mat-expansion-panel:not([class*='mat-elevation-z']) {
            box-shadow: 0 1px 0px 0px rgba(0, 0, 0, 0.2), 0 0px 0px 0 rgba(0, 0, 0, 0.14),
                0 0px 0px 0 rgba(0, 0, 0, 0.12);
        }

        .mat-expansion-panel {
            background: rgba(0, 0, 0, 0.04);
        }

        .mat-expansion-panel-header {
            padding: 0 15px;
        }

        mat-expansion-panel-header.mat-expanded {
            font-weight: 600;
        }

        .mat-expansion-panel-content {
            background: #ffffff;
        }

        .mat-expansion-panel-body {
            padding: 0px;
            background: #ffffff !important;

            .mat-selection-list:focus {
                outline: none;
            }

            .mat-selection-list {
                padding: 0px;

                .mat-list-option {
                    padding-left: 15px;
                }

                .mat-list-item-content {
                    flex-direction: row;

                    .mat-pseudo-checkbox {
                        margin-right: 15px;
                    }
                }
            }
        }

        .mat-expansion-panel-spacing {
            margin: 0;
        }
    }

    mat-form-field {
        input {
            text-overflow: ellipsis;
        }
    }

    .invalid {
        .mat-form-field-placeholder.mat-form-field-float {
            /* Chrome/Opera/Safari */
            color: #f44336;
        }

        .mat-input-placeholder.mat-form-field-placeholder {
            color: #f44336;
        }
    }

    .mat-list-item-disabled {
        color: rgba(0, 0, 0, 0.54) !important;

        .mat-pseudo-checkbox {
            color: rgba(0, 0, 0, 0.36);
        }
    }
}

ads-prism-single-select-group {
    .mat-pseudo-checkbox {
        display: none;
    }
}

#calculatedEntityDialog {
    ads-prism-single-select-group {
        .multi-select-group-list {
            max-height: 200px;
            bottom: 45px;
        }
    }
}

.add-graph {
    width: 780px;

    ads-prism-single-select-group {
        .multi-select-group-list {
            max-height: 200px;
            bottom: 45px;
            left: -15px;
        }
    }
}

.minmaxavgtotaltable .mat-tab-label {
    background: #fff;
}


// multi-selecet-auto-complete
//
// -------------------------------
app-multi-select-auto-complete {
    position: relative;

    .multi-select-list {
        background: #fff;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
            0 3px 14px 2px rgba(0, 0, 0, 0.12);
        margin-top: -15px;
        max-height: 30vh;
        min-width: auto;
        width: 100%;
        overflow: auto;
        position: absolute;
        z-index: 2000;
    }

    .invalid {
        .mat-form-field-placeholder.mat-form-field-float {
            /* Chrome/Opera/Safari */
            color: #f44336;
        }

        .mat-input-placeholder.mat-form-field-placeholder {
            color: #f44336;
        }
    }

    .mat-form-field-placeholder.mat-form-field-float {
        -webkit-transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
        transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
        -ms-transform: translateY(-1.28125em) scale(0.75);
    }

    .mat-input-placeholder.mat-form-field-placeholder {
        -webkit-transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
        transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
        -ms-transform: translateY(-1.28125em) scale(0.75);
    }

    mat-form-field {
        input {
            text-overflow: ellipsis;
        }
    }
}

.cdk-overlay-pane.mat-datetimepicker-popup {
    visibility: hidden;
}

.mat-datetimepicker-calendar-header {
    color: #fff;
}

.cdk-overlay-container .mat-dialog-container {
    overflow: inherit;

    app-multi-select-auto-complete {
        position: unset;

        .multi-select-list {
            position: fixed;
            width: 550px;
        }
    }

    app-multi-select-group {
        position: unset;

        .multi-select-group-list {
            position: fixed;
        }
    }

    #resonForEditId {
        app-multi-select-auto-complete {
            position: relative;

            .multi-select-list {
                top: -150px;
                position: absolute;
                width: 700px;
                max-height: 200px;
            }
        }
    }
}

// vault-telemetry-export
//
// -------------------------------
.vault-telemetry-export {
    button[type='submit'] {
        margin: 0 auto;
    }

    .mat-dialog-actions:last-child {
        margin-bottom: 0;
    }

    app-multi-select-auto-complete {
        margin-right: 5px;
        width: 200px;
    }
}

// percent-full
//
// ----------------------
.noPerfectFullDataFound {
    padding: 20px 0;
    text-align: center;
}

// highlighting the underline
.mat-form-field-underline {
    height: 2px !important;

    .mat-form-field-ripple {
        height: 2px !important;
    }
}

.mat-select-underline {
    height: 2px !important;
}

#slicerDialog {
    .button-without-focus {
        .mat-button-focus-overlay {
            background: none;
        }
    }
}

.summary-details.weekgroup-table {
    .mat-table {
        .mat-row {
            .mat-cell {
                overflow: unset;
            }
        }

        .mat-form-field-infix {
            width: 110px;
        }
    }
}

.case-study-locked .study-tab {

    .mat-form-field,
    .mat-button,
    .mat-checkbox {
        pointer-events: none;
    }
}

.upstream-options .mat-option-text {
    font-size: 13px;

    .upstram-name {
        min-width: 50px;
        display: inline-block;
    }

    .count {
        color: #0000008a;
        font-size: 12px;
    }
}

.basin-wrapper {
    .mat-selection-list {
        border: 1px solid #eaeaea;
        float: left;
        width: 100%;
        margin-bottom: 15px;
        padding: 15px 0 0 15px;
        box-sizing: border-box;
        background: #f7f7f7;
        border-radius: 3px;
    }

    .upstream-wrapper {
        width: 80%;
        box-sizing: border-box;
        padding-left: 30px;

        a {
            font-size: 12px;
            vertical-align: text-top;
            margin-left: 10px;
        }

        .mat-list-text {
            border-radius: 25px;
            padding: 5px 15px !important;
            border: 2px solid #56495622;
            background: #fff;
            color: #a4a2a2;
        }

        .mat-pseudo-checkbox-checked+.mat-list-text {
            border: 2px solid #5cb259;
            color: #000;
        }

        .mat-list-item.mat-list-option {
            float: left;
            font-size: 13px;
            text-align: center;
            height: auto;
            margin: 0 15px 15px 0;
            border-radius: 25px;
            width: auto;
        }

        .mat-pseudo-checkbox {
            display: none;
        }

        label {
            color: #0000008a;
            font-size: 12px;
            vertical-align: top;
        }

        .mat-selection-list .mat-list-option .mat-list-item-content-reverse {
            padding: 0;
        }
    }
}

.mde-popover-panel {
    margin-top: -10px;

    .mde-popover-direction-arrow {
        right: 15px;
    }

    .mde-popover-content {
        .mat-card {
            width: 400px;
            padding: 0;
            margin: 0;
            max-height: 400px;
            overflow: hidden;

            .notification-tab-content {
                .mat-tab-group {
                    .mat-tab-header {
                        text-transform: capitalize;

                        .mat-tab-label {
                            width: 50%;
                            height: 40px;
                            font-size: 14px;
                        }

                        .mat-tab-label-active {
                            opacity: 1;
                        }

                        .mat-ink-bar {
                            background-color: #5cb259;
                        }
                    }

                    .mat-tab-header-pagination {
                        display: none;
                    }

                    mat-tab-body {
                        mat-list {
                            padding: 0;
                            max-height: 350px;
                            padding-bottom: 15px;

                            mat-list-item {
                                height: auto;

                                .mat-list-item-content {
                                    padding: 15px;
                                    border-top: 1px solid rgba(0, 0, 0, 0.12);
                                }

                                &.unread-notification {
                                    background: #e8f4fe;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#stormTable .mat-table {
    overflow: auto;

    .mat-header-row>.mat-header-cell:not(:first-child) {
        min-width: unset !important;
    }

    .mat-header-row>.mat-header-cell:nth-child(2) {
        left: unset !important;
    }
}

.long-table {
    margin-top: 25px;
    margin-bottom: 25px;

    .table-scroll-wraper {
        overflow: auto;
    }

    .entities-width {
        max-width: 50px;
        cursor: pointer;
    }

    .table-action {
        display: flex;
    }

    .flag-color {
        height: 9px;
        width: 17px;
        background: #fcd9d5;
        margin-right: 10px;
    }

    .flag-edited-color {
        background: #f48fb1;
        margin-left: 10px;
    }

    .new-page-icon {
        position: absolute;
        right: 60px;
    }

    .link-label {
        &:hover {
            cursor: pointer;
        }
    }

    .mat-row:click {
        background: #d2e5ff;
    }

    .edit-field {
        min-width: 50px;
        width: 90%;
    }

    .selected {
        background-color: #d2e5ff;
    }
}

.only-print {
    display: none;
}

@media print {
    // overwriting property during print

    .non-printable {
        display: none !important;
    }

    #location-flow-balance-graph {
        zoom: 75% !important;
    }

    .print-page {
        width: 100% !important;
        background: white;
    }

    .print-full-width {
        width: 100% !important;
    }

    .print-page-break {
        display: block !important;
        page-break-after: always !important;
    }

    .block-print {
        display: block !important;
    }

    .dont-print {
        display: none !important;
    }

    .only-print {
        display: block;
    }

    .custom-dashboard-print-content {
        .multi-locations {
            margin-right: 10px !important;
        }
    }
}

.ngxp__container {
    z-index: 99;
    padding: 0px 10px !important;
    border: none !important;
    box-shadow: none !important;
    min-width: 382.3px;
}

.widget-table {
    min-height: 200px;
}

.widgetFooter {
    .mat-button {
        padding: 0 4px !important;
        margin-right: 4px !important;
    }
}

.flex {
    display: flex;
}

.ol-overviewmap {
    bottom: 1em !important;
}

#country-search-box {
    width: 95%;
}