.material-icons.mat-18 {
    font-size: 18px;
    height: 18px;
    width: 18px;
}
.material-icons.mat-24 {
    font-size: 24px;
}
.material-icons.mat-36 {
    font-size: 36px;
}
.material-icons.mat-48 {
    font-size: 48px;
}

.dropdown-content li > a,
.dropdown-content li > span {
    color: $primary-color;
}

a {
    color: #039be5;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
}
