// $sidenav-width: 225px;
// $topnav-height: 64px;

main {
    @media #{$medium-and-down} {
        // You should set your own components overflow to scroll if you want scrolling
        overflow: hidden;
    }

    &.fixed-header {
        max-height: calc(100vh - 67px);
        overflow-y: scroll;
        margin-top: 3px;

        > .container {
            @media #{$large-and-up} {
                padding: 17px 20px 0; // Account for new 3px margin-top
            }
            @media #{$medium-and-down} {
                padding-top: 12px;
            }
        }
    }

    > .container {
        @media #{$large-and-up} {
            padding: 20px 20px 0;
            width: auto;
            max-width: none;
        }
        @media #{$medium-and-down} {
            width: 95%;
            margin-left: 2.5%;
            padding-top: 15px;
        }
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    33% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

app-sidenav > mat-toolbar > .mat-toolbar-layout {
    width: 100%;
}

main.icon-sidenav-opened,
header.icon-sidenav-opened {
    // margin-left: 48px;
}

mat-sidenav,
mat-sidenav.mat-sidenav-opening,
mat-sidenav.mat-sidenav-opened,
mat-sidenav.mat-sidenav-closing {
    // width: $sidenav-width;
    background-color: $sidenav-primary-color;
    max-width: 70%;

    &.icon-sidenav {
        // min-width: 48px;
        // max-width: 48px;
        &:not(.over) {
            transition: max-width 0.4s;
        }

        &:not(:hover) {
            mat-toolbar {
                // Fit the logo to the shrunken sidenav
                padding-left: 0;
                img.logo {
                    // max-width: 48px;
                    height: auto;
                }
            }

            .nav-children {
                height: 0 !important;
            }
        }

        a[mat-list-item] > .mat-list-item-content {
            > i.material-icons:last-child,
            > span.title {
                //visibility: hidden;
                display: none;
                opacity: 0;
            }
        }

        &:hover {
            // max-width: $sidenav-width;
            transition: max-width 0.4s;
            a[mat-list-item] > .mat-list-item-content {
                > span.title {
                    //visibility: visible;
                    display: table-cell;
                    opacity: 1;
                    animation: 0.666s ease 0s normal forwards 1 fadein;
                }
                > i.material-icons:last-child {
                    text-align: right;
                    display: inline-block;
                    opacity: 1;
                    animation: 0.666s ease 0s normal forwards 1 fadein;
                }
            }
        }
    }

    @media #{$medium-and-down} {
        &.mat-sidenav-opened {
            box-shadow: none !important;
        }
    }

    mat-toolbar {
        box-shadow: 0 1px 0 rgba(0, 0, 0, 0.12);

        .logo {
            height: 50px;
            margin-top: 2px;
            margin-bottom: 2px;
        }
    }
}

mat-sidenav-container > .mat-sidenav-content {
    height: 100vh;
    overflow-y: scroll !important;
    background-color: #ececec;
}

mat-sidenav-container.mat-sidenav-container {
    background-color: rgb(250, 250, 250);
}

mat-nav-list.side-navigation {
    padding-top: 0;

    > * > a[mat-list-item] > .mat-list-item-content {
        font-size: 12px !important;
        padding: 0;
    }

    a[mat-list-item] {
        position: relative;
    }

    a[mat-list-item] {
        > .mat-list-item-content {
            display: table;
            width: 100%;

            > span.title {
                display: table-cell;
                padding-left: 10px;
                vertical-align: middle;
                font-size: 12px;
            }

            > mat-icon {
                display: table-cell;
                height: 18px;
                font-size: 18px;
                vertical-align: middle;
                text-align: center;
                border-left: 7px solid #0a1d38;
                width: 36px;
                padding-right: 7px;
            }

            color: white;
            -webkit-font-smoothing: antialiased;
            font-weight: 500;
            //line-height: 40px;
            font-size: 14px;
            letter-spacing: 0.14px;

            > i.material-icons:last-child {
                line-height: 40px;
                height: auto;
                width: auto;
                transition: 0.4s transform cubic-bezier(0.25, 0.8, 0.25, 1);
                &:before {
                    content: 'keyboard_arrow_down';
                }
            }
        }
    }

    a.active > .mat-list-item-content > i.material-icons:last-child {
        transform: rotate(180deg) translateZ(0);
    }

    > a.active:not(.nav-dropdown) > .mat-list-item-content:not(:hover) {
        background-color: darken($sidenav-primary-color, 4%) !important;
    }
    .nav-children {
        padding-top: 0;
        overflow: hidden;

        &:not(.no-animation) {
            transition: height 0.75s cubic-bezier(0.35, 0, 0.25, 1);
            &:not(.active) {
                transition: height 0.75s cubic-bezier(0.35, 0, 0.25, 1);
                height: 0 !important;
            }
        }

        &.no-animation:not(.active) {
            height: 0 !important;
        }

        &.shrink {
            > * > a[mat-list-item] > .mat-list-item-content {
                height: 36px;
            }
        }

        $base-padding: 16px;
        $base-color-change: 3%;

        a[mat-list-item]:hover,
        a[mat-list-item].active {
            > .mat-list-item-content > span.title {
                color: $sidenav-secondary-color;
            }
        }

        &.level1 a[mat-list-item] {
            > .mat-list-item-content {
                padding-left: $base-padding * 2;
                background-color: darken($sidenav-primary-color, $base-color-change);
            }
            &:hover,
            &.active {
                > .mat-list-item-content {
                    background-color: darken($sidenav-primary-color, $base-color-change * 2);
                }
            }
        }
        &.level2 a[mat-list-item] {
            > .mat-list-item-content {
                padding-left: $base-padding * 3;
                background-color: darken($sidenav-primary-color, $base-color-change * 2);
            }
            &:hover,
            &.active {
                > .mat-list-item-content {
                    background-color: darken($sidenav-primary-color, $base-color-change * 3);
                }
            }
        }
        &.level3 a[mat-list-item] {
            > .mat-list-item-content {
                padding-left: $base-padding * 4;
                background-color: darken($sidenav-primary-color, $base-color-change * 3);
            }
            &:hover,
            &.active {
                > .mat-list-item-content {
                    background-color: darken($sidenav-primary-color, $base-color-change * 4);
                }
            }
        }
        &.level4 a[mat-list-item] {
            > .mat-list-item-content {
                padding-left: $base-padding * 5;
                background-color: darken($sidenav-primary-color, $base-color-change * 4);
            }
            &:hover,
            &.active {
                > .mat-list-item-content {
                    background-color: darken($sidenav-primary-color, $base-color-change * 5);
                }
            }
        }
        &.level5 a[mat-list-item] {
            > .mat-list-item-content {
                padding-left: $base-padding * 6;
                background-color: darken($sidenav-primary-color, $base-color-change * 5);
            }
            &:hover,
            &.active {
                > .mat-list-item-content {
                    background-color: darken($sidenav-primary-color, $base-color-change * 6);
                }
            }
        }
    }
}

.side-navigation mat-nav-list {
    position: relative;
}

.side-navigation mat-nav-list.nav-children:before,
.side-navigation mat-nav-list.nav-children:after,
.side-navigation a.active:not(.nav-dropdown):before,
.side-navigation a.active:not(.nav-dropdown):after {
    content: '';
    left: 0;
    position: absolute;
    // width: $sidenav-width;
    height: 2px;
    z-index: 3;
}

.side-navigation mat-nav-list.nav-children:before,
.side-navigation a.active:before {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, transparent 100%);
    top: 0;
}

.side-navigation mat-nav-list.nav-children:after,
.side-navigation a.active:after {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.1) 0%, transparent 100%);
    bottom: 0;
}

@media #{$medium-and-down} {
    main,
    footer {
        padding-left: 0;
    }
}

header mat-toolbar {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    background-color: rgb(255, 255, 255);
    mat-toolbar-row:not(:first-child) {
        @media #{$medium-and-down} {
            height: 44px;
        }
    }
    .breadcrumb-holder {
        white-space: nowrap;
        text-overflow: ellipsis;
        .breadcrumb {
            &:before {
                content: '\E5CC';
                color: hsla(0, 0%, 100%, 0.7);
                vertical-align: top;
                display: inline-block;
                font-family: Material Icons;
                font-weight: 400;
                font-style: normal;
                font-size: 25px;
                margin: 0 10px 0 8px;
                -webkit-font-smoothing: antialiased;
            }

            &:first-child:before {
                display: none;
            }

            > .breadcrumb-link {
                @media #{$large-and-up} {
                    color: $font-color;
                }
                @media #{$medium-and-down} {
                    color: white;
                }
            }

            line-height: 44px;

            @media #{$large-and-up} {
                color: $font-color;
                &:before {
                    color: $font-color;
                }
                &:last-child {
                    color: $font-color;
                }
            }
            @media #{$medium-and-down} {
                color: white;
                &:before {
                    color: white;
                }
                &:last-child {
                    color: white !important;
                }
            }
        }
    }

    mat-toolbar-row > .breadcrumb-holder {
        overflow-x: hidden;
        @media #{$medium-and-down} {
            // You should set your own components overflow to scroll if you want scrolling
            overflow-x: scroll;
        }

        > .breadcrumb:last-child {
            color: $font-color;
        }
    }
}

mat-nav-list.secondary-nav {
    padding-top: 0;
}

mat-nav-list.secondary-nav > a[mat-list-item] > .mat-list-item-content,
mat-nav-list.secondary-nav > mat-list-item > .mat-list-item-content,
mat-nav-list.secondary-nav > mat-list-item > .mat-list-item-content {
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 20px;

    > mat-icon {
        height: auto;
        width: auto;
        margin-right: 10px;
    }
}

mat-nav-list.secondary-nav > a[mat-list-item].active > .mat-list-item-content,
mat-nav-list.secondary-nav > mat-list-item.active > .mat-list-item-content,
mat-nav-list.secondary-nav > mat-list-item.active > .mat-list-item-content {
    background-color: darken($primary-color, 4%);
    color: white;

    > mat-icon {
        color: white;
    }

    p {
        color: white;

        .detail-message {
            color: darken(white, 10%);
        }
    }
}

[mat-icon-button] {
    // height: auto !important;
}

.mat-menu-click-catcher {
    z-index: 9999;
}

#topnav-search {
    width: 0;
    overflow: hidden;
    animation: 0.666s ease 0s normal forwards 1 fadeout;
    opacity: 0;
    visibility: hidden;
    transition: width 0.4s cubic-bezier(0.35, 0, 0.25, 1);
    &.search-visible {
        width: 200px;
        visibility: visible;
        opacity: 1;
        animation: 0.8s ease 0s normal forwards 1 fadein;
    }

    @media #{$medium-and-down} {
        transition: none;
        .mat-input-placeholder.mat-empty {
            color: white;
        }
    }
}

#menu-toggler {
    color: $sidenav-primary-color;
    margin-right: 16px;
    margin-left: -16px;
    width: 50px;
    background-color: #f6f6f6;
    &[mat-icon-button] {
        border-radius: 0;
        padding: 10px;
        line-height: 44px;
    }
    @media #{$medium-and-down} {
        color: white;
    }
}

#user-dropdown-toggler {
    width: auto;
    &[mat-icon-button] {
        border-radius: 3px;
        padding: 0 10px;
        line-height: 40px;
    }
    .mat-button-ripple-round {
        border-radius: unset;
    }
}

@media #{$large-and-up} {
    .container {
        width: 85%;
    }
}

.app-flex-filler {
    flex: 1 1 auto;
}

.navbar-search-wrapper {
    position: absolute;
    left: 60px;
    width: calc(100% - 4rem);
    height: 100%;
}

.nav-children .mat-menu-content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.nav-children .mat-button {
    text-align: left;
}

a.each-nav-item.mat-button {
    display: block;
    opacity: 0.78;
    border-bottom: 3px solid #263238;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    &:hover {
        border-bottom: 3px solid #ffffff;
        opacity: 1;
    }
    &.active {
        opacity: 1;
        border-bottom: 3px solid #ffffff;
    }
    mat-icon {
        height: 20px;
        font-size: 19px;
    }
}

.mat-menu-content {
    a.each-nav-item.mat-button {
        border-bottom: 0px solid;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        &:hover {
            border-bottom: 0px solid;
        }
    }
    a.mat-button.active {
        border-bottom: 0px solid;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
    }
}
#navBarContainer {
    .mat-button-focus-overlay {
        background-color: unset;
    }
}
.mat-menu-panel.nav-children {
    #navBarContainer {
        .mat-button-focus-overlay {
            background-color: rgba(0, 0, 0, 0.04);
        }
    }
}
@media #{$ipad-screen-and-down} {
    a.each-nav-item.mat-button {
        &:hover {
            border-bottom: 3px solid #263238;
            opacity: 1;
        }
    }
}
