$blackScreenColorNotImportant: rgba(255, 255, 255, 0.87);
$blackScreenColor: $blackScreenColorNotImportant !important;
$black-screen-dark-background: #000 !important;
$black-screen-background: #212121 !important;
$light-black-screen-background: #424242 !important;
$grey-black-screen-background-not-important: #616161;
$grey-black-screen-background: $grey-black-screen-background-not-important !important;
$blackScreenborder: rgba(255, 255, 255, 0.12) !important;
$blackScreenMidColor: rgba(255, 255, 255, 0.54) !important;
$blackScreenLightColor: rgba(255, 255, 255, 0.38) !important;
$white-color: white;
$font-color-dark: #bbb;
$font-color-darker: #999;
$background-color: #616161 !important;
$table-first-child-color: rgb(97, 97, 97) !important;
$blackscreen-borderColor: #efeaea;

.blackTheme {
    background: $grey-black-screen-background;
    color: $blackScreenColor;

    .background-light {
        background: $light-black-screen-background;
    }
    .background-default {
        background: $black-screen-dark-background;
    }

    app-topnav {
        background: $grey-black-screen-background;
    }
    .logo {
        background: $grey-black-screen-background;
        border-radius: 10px;
    }
    .overlay {
        background: $black-screen-background;
    }
    .filter-operation {
        background: $light-black-screen-background;
    }

    .footer-on-hint {
        background: initial !important;
    }

    div:not(.danger), p:not(.danger), ul:not(.danger), li:not(.danger) {
        color: $blackScreenColorNotImportant;
    }

    .mat-datetimepicker-clock {
        background: $black-screen-background;
    }

    inline-edit {
        background: #263238 !important;
    }

    .material-symbols-outlined {
        color: $blackScreenColor;
    }

    #bpDailySummaryReportId {
        .bp-summary-synopsys-wrapper {
            color: $blackScreenColor;
            background: $light-black-screen-background;
        }

        .bp-divider {
            color: $blackScreenColor;
            background: $light-black-screen-background;
        }

        .item-percent {
            color: $blackScreenColor;
        }

        .item-total-label {
            color: $blackScreenColor;
        }
    }

    #site-name-popover {
        .content {
            background: $black-screen-background;
        }

        .title {
            color: #fff !important;
        }

        .active-bg {
            background-color: #145200 !important;
        }

        .inactive-bg {
            background-color: #3F3E3E !important;
        }

        .maintainance-bg {
            background-color: #786932 !important;
        }

        .green-alarm-ng {
            background-color: #145200 !important;
        }

        .orange-alarm-bg {
            background-color: #763F04 !important;
        }

        .red-alarm-bg {
            background-color: #860F09 !important;
        }
    }

    h1, h2, h3, h4 {
        color: $blackScreenColor;
    }
    textarea {
        background: $black-screen-dark-background;
        color: $blackScreenColor;
    }
    .mat-drawer {
        color: $blackScreenColor;
        background-color: rgba(0, 0, 0, 0.87);
    }
    a mat-icon {
        color: $font-color-dark;
    }
    .mat-menu-item .mat-icon-no-color, .mat-menu-item-submenu-trigger::after, .mat-datetimepicker-calendar-table-header,
    .mat-calendar-table-header, .mat-calendar-body-label {
        color: $blackScreenColor;
    }
    .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
        color: $blackScreenLightColor;
    }
    .mat-slide-toggle-bar, .mat-pseudo-checkbox {
        background: $grey-black-screen-background;
    }
    .mat-calendar-arrow {
        border-top-color: $blackScreenColor;
    }

    button, input, mat-select {
        color: $blackScreenColor;
    }
    input::placeholder, .mat-select-placeholder { color: $blackScreenLightColor; }

    input:-webkit-autofill{
        -webkit-text-fill-color: $blackScreenColor;
        background-color: $grey-black-screen-background;
        box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px $black-screen-background; // UGLY SOLUTION for #24070
        color: $blackScreenColor;
    }

    .mat-table {
        background: none;
    }
    input {
        caret-color: $white-color;
    }

    .mat-button:hover {
        background: $grey-black-screen-background;
    }
    .mat-select-arrow-wrapper .mat-select-arrow {
        color: $white-color !important;
    }
    .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
        color: $blackScreenLightColor;
    }

    .custom-placeholder, .mat-select-value-text, .card-custom-header-se .custom-placeholder, .card-custom-header-se .mat-select-value-text {
        color: $blackScreenColor;
    }
    .mat-menu-panel, .mat-option, .mat-datetimepicker-calendar-content, .mat-selection-list, .mat-list-option, .mat-datepicker-content  {
        background: $light-black-screen-background;
        color: $blackScreenColor;
    }
    .mat-option-disabled {
        color: $blackScreenLightColor;
    }
    .mat-menu-panel {
        border: 1px solid $blackScreenLightColor;
    }

    .mat-expansion-panel {
        background: $light-black-screen-background;

        .mat-expansion-panel-header {
            color: $blackScreenColor;
            background: $black-screen-background;
        }

        .mat-expansion-panel-body {
            color: $blackScreenColor;
            background: $light-black-screen-background;
        }
    }
    .mat-expansion-indicator::after {
        color: $blackScreenColor;
    }

    .minmaxavgtotaltable .mat-tab-label {
        background: $black-screen-dark-background;
    }

    .custom-nav-dropdown-content {
        background: $light-black-screen-background;
        color: $blackScreenColor;
        border: $blackScreenMidColor 1px solid;
    }

    .custom-subnav-dropdown-content {
        background: $light-black-screen-background;
        color: $blackScreenColor;
        border: $blackScreenMidColor 1px solid;
    }

    .location-selection-bar .pagination {
        .left, .right {
            border-color: $blackScreenColor;
        }
    }

    mat-toolbar.mat-toolbar {
        mat-toolbar-row.mat-toolbar-row {
            background: $black-screen-background;
            color: $blackScreenColor;

            .hint-action {
                background: $black-screen-background;
            }
        }
        mat-toolbar-row.mat-toolbar-row.main-nav-menu {
            background: $black-screen-dark-background;
            color: $blackScreenColor;

        }
    }

    .customer-edit-svg:after {
        content: url('./../images/svgIcon/customer_edit_white.svg');
        width: 24px;
    }
    .location-header-svg:after {
        content: url('./../images/svgIcon/location_header_white.svg');
        width: 24px;
    }
    .sidebarhandlebutton {
        background: $black-screen-background;
    }

    .notification-dashboard {
        .notification-details {
            background-color: $black-screen-background;
            .filter-menu {
                background-color: $light-black-screen-background;
            }
        }
    }
    .pinsidebar {
        color: rgba(255, 255, 255, 0.54);
    }

    .map, .locationmap {
        background: #414141;
        .ol-scale-text, .ol-scale-step-text {
            text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
            color: rgba(255, 255, 255, 0.87);
            font-weight: bold;
        }

        .mapbuttons {
            background: #212121 !important;
            color: rgba(255, 255, 255, 0.87);
        }
        .olmap, .ol-viewport {
            canvas {
                // Makes map darker
                filter: brightness(0.65);
            }
        }
        .ol-overlay-container {
            .popup {
                background: $black-screen-background;
            }
        }
    }
    .mat-icon-button.mat-button-disabled {
        color: $blackScreenLightColor;
    }
    .mat-slide-toggle {
        color: $blackScreenColor;
    }
    table {
        background: $black-screen-background;
        color: $blackScreenColorNotImportant;
    }

    .mat-expansion-panel-header-title {
        color: $blackScreenColor;
    }

    .mat-datetimepicker-calendar-body-disabled>.mat-datetimepicker-calendar-body-cell-content:not(.mat-datetimepicker-calendar-body-selected),
    .mat-datetimepicker-calendar-next-button.disabled, .mat-datetimepicker-calendar-previous-button.disabled  {
        color: $blackScreenLightColor;
    }

    #locationDashboardAnnotation {
        background: $light-black-screen-background;
    }

    #inputField.mat-form-field-appearance-legacy .mat-form-field-underline {
        background-color: $blackScreenLightColor;
    }

    .location-selection-bar .location-list .mat-tab-label {
        background: $light-black-screen-background;
    }

    .location-dashboard-tab {
        mat-tab-header {
            background: rgb(0, 0, 0);
        }
        mat-toolbar {
            color: $blackScreenColor;
            border-bottom: 1px solid $light-black-screen-background;
            .mat-icon {
                color: $blackScreenColor;
            }

        }

        .leaderBoardContainer, .more-options-menu, .more-options-menu button {
            background: $black-screen-background;
            color: $blackScreenColor;
        }
        .more-options-menu {
            border: black 2px solid;
            button mat-icon {
                color: $blackScreenColor;
            }
        }

        .mat-tree, .mat-tree .mat-tree-node, .row, .cell {
            background: $black-screen-background;
            color: $blackScreenColor;
            .mat-icon {
                color: $blackScreenColor;
            }
        }
        .cell {
            border-color: $black-screen-background;
        }
        .layerbtnpressed {
            background: $light-black-screen-background;
        }
    }

    .mcc-color-picker-overlay {
        border: 1px $blackScreenborder solid;
    }
    .mcc-color-picker-overlay, .mcc-color-picker-selector {
        background: $black-screen-background;
    }
    .mat-menu-panel.customDateMenu .header {
        color: $blackScreenColor;
    }

    .new-notification-dropdown {
        background: $light-black-screen-background;
    }

    .custom-phone-input {
        border-color: $blackScreenLightColor;
    }
    .custom-phone-input .custom-phone-label {
        color: $blackScreenColor;
    }
    .mat-dialog-actions {
        border-color: $blackScreenLightColor;
    }
    .phone-input {
        background: none;
        color: $blackScreenColor;
    }
    .customer-editor {
        background: none;
    }
    .baseMap {
        .mat-menu-content, .mat-menu-item {
            background: $black-screen-background !important;
            color: $blackScreenColor;
        }

        .mat-menu-content {
            border: black 2px solid;
            button mat-icon {
                color: $blackScreenColor;
            }
        }

        .basemapgrp {
            background: $black-screen-background;
            color: $blackScreenColor;
        }
    }

    ads-prism-vault-tree {
        background-color: $black-screen-background;
        .mat-tree {
            background-color: $black-screen-background;
            .mat-tree-node {
                color: $blackScreenColor;
                .mat-icon-button[disabled][disabled] {
                    color: $blackScreenLightColor;
                }
                .mat-flat-button {
                    background-color: $black-screen-background;
                    color: $blackScreenColor;
                }
                &.active {
                    background-color: $light-black-screen-background;
                    color: $blackScreenColor;
                    .mat-icon-button[disabled][disabled] {
                        color: $blackScreenColor;
                        opacity: 0.54;
                    }
                    .mat-flat-button {
                        background-color: $light-black-screen-background;
                        color: $blackScreenColor;
                    }
                }
            }
        }
    }

    .updatesContainer .mat-expansion-panel {
        background: #6f5025 !important;
        border: 1px #ffe0b5 solid;
        .mat-expansion-panel-header {
            background: none !important;
        }
    }

    .hydrograph-editing-more-data {
        .input-more {
            background: none !important;
        }
    }

    .tooltip-btn-off {
        .highcharts-button-box {
            fill: #424242;
        }
        text {
            fill: #e0e0e0 !important;
        }
    }
    .tooltip-btn-on {
        text {
            fill: #000000 !important;
        }
    }
    .tooltip-lbl {
        text {
            fill: #e0e0e0 !important;
        }
    }
    .jump-scroll-btn.disabled text {
        color: $blackScreenborder;
        fill: $blackScreenborder;
    }
    .jump-scroll-btn text {
        color: $blackScreenColor;
        fill: $blackScreenColor;
    }

    .edit-buttons {
        button {
            background: #fff !important;
            color: #111 !important;
            mat-icon {
                color: #111 !important;
                &.drag {
                    content: url('./../images/svgIcon/drag.svg') !important;
                }
            }
            img {
                filter: invert(1);
            }
        }
        button.inactive {
            background: #aaa !important;
            color: #555 !important;
            mat-icon {
                color: #444 !important;
            }
        }
    }
    .custom-eraser-white:after {
        content: url('./../images/svgIcon/eraser.svg') !important;
    }
    .tolerance-control-input {
        background: $black-screen-background;
    }


    .tooltip-lbl {
        color: $blackScreenColor;
    }
    .tooltip-btn-off {
        background: $grey-black-screen-background;
    }

    .highcharts-reset-zoom {
        text {
            fill: $blackScreenColor;
        }
        rect {
            fill: $black-screen-background;
        }
    }


    #CaseStudyDetails {
        .custom-settings {
            .mat-header-cell, .mat-header-cell:first-child {
                background: none !important;
            }
        }
        .custom-vertical-tab {
            .mat-header-cell:first-child,
            .mat-cell:first-child {
                background: none !important;
            }
        }
        .color-running {
            background-color: #39505d;
        }
    }

    .hover {
        background: $black-screen-background;
    }
    .selection {
        background: $light-black-screen-background;
    }

    .storm #stormTable, .sub-property-width {
        border: 2px solid $blackScreenborder;
    }
    .custom-slider .ngx-slider .ngx-slider-pointer {
        background-color: $grey-black-screen-background;
    }
    .custom-settings {
        .custom-stepper-action {
            .mat-button {
                &:hover {
                    background: $light-black-screen-background !important;
                }
            }
        }
    }
    .sub-property-width > mat-card-header  {
        border-bottom: 2px solid $grey-black-screen-background;
    }
    .regimes-container .regimes-element {
        .regimes-title {
            color: $blackScreenColor;
        }
        .regimes {
            .regime-holder {
                background: none;
            }
            .regime-holder:nth-child(even) {
                background: $light-black-screen-background;
            }
        }
    }
    .slider-toggle-gross {
        background-color: #0c520e;
    }
    .slider-toggle-net {
        background-color: #968404;
    }
    .custom-slider .ngx-slider .ngx-slider-bar {
        background: $grey-black-screen-background-not-important;
    }
    #stormSettingsDialog {
        .sectionTitle {
            color: $blackScreenColor;
        }

    }
    #basinTable .mat-header-row {
        background-color: $light-black-screen-background;
    }
    .filter-content {
        background-color: $light-black-screen-background;
    }

    .basin-wrapper {
        #basinTable {
            .highlight .mat-cell:first-child {
                background-color: #224b6b !important;
            }
            .mat-header-cell {
                background: $light-black-screen-background;
            }
        }
        label {
            color: $blackScreenColor;
        }
        .upstream-wrapper {
            .mat-list-text {
                background: $black-screen-dark-background;
                color: $white-color;
                border: 1px $light-black-screen-background solid;
            }
            .select-options {
                span {
                    color: $blackScreenColor;
                    border: $blackscreen-borderColor;
                    background: $light-black-screen-background;
                }
                span.disabled {
                    color: $blackScreenLightColor;
                    background: $grey-black-screen-background;
                }
            }
            .mat-pseudo-checkbox-checked + .mat-list-text {
                color: $blackScreenColor;
            }
        }
    }
    .timeline-title {
        color: $blackScreenColor;
    }

    #CaseStudyDetails .pbi-fullscreen {
        background: none;
    }
    iframe {
        filter: invert(100%) brightness(1.4);
    }

    .vault-toggle-sidenav .mat-button {
        background-color: $black-screen-background;
        color: $blackScreenColor;
        border-color: $blackScreenborder;
    }

    .input-design {
        color: $blackScreenLightColor;
    }
    .alarm-title-bar {
        border-color: $blackScreenborder;
    }

    .mat-tab-header-pagination-chevron {
        border-color: $blackScreenColor;
    }

    .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
        border-color: $blackScreenColor;
    }
    .mat-checkbox-frame {
        border-color: rgba(255, 255, 255, 0.54);
    }
    .mat-radio-outer-circle {
        border-color: rgba(255, 255, 255, 0.54);
    }
    .mat-radio-disabled .mat-radio-outer-circle {
        border-color: rgba(255, 255, 255, 0.38);
    }
    .mat-card-subtitle {
        color: rgba(255, 255, 255, 0.54);
    }
    .mat-form-field {
        color: $blackScreenColor;
    }
    .mat-form-field-label {
        color: $blackScreenMidColor;
    }
    .mat-form-field-appearance-legacy .mat-form-field-label {
        color: $blackScreenColor;
    }
    .mat-input-element:disabled {
        color: $blackScreenColor;
    }
    .mat-checkbox-disabled {
        .mat-checkbox-label {
            color: $font-color-darker;
        }
        .mat-checkbox-inner-container {
            color: $font-color-darker;
        }

        svg {
            background-color: $black-screen-dark-background;
            path {
                stroke: $font-color-darker;
            }
        }
    }
    .mat-form-field-underline {
        background-color: rgba(255, 255, 255, 0.42) !important;
    }
    .mat-form-field-ripple {
        background-color: rgba(255, 255, 255, 0.42) !important;
    }
    .mat-hint {
        color: rgba(255, 255, 255, 0.54);
    }
    .mat-selection-list .mat-list-item,
    .mat-selection-list .mat-list-option {
        color: rgba(255, 255, 255, 0.87);
    }
    .multi-select-list,
    .multi-select-group-list {
        .mat-list-item,
        .mat-list-option {
            color: rgba(0, 0, 0, 0.87);
        }
        .mat-pseudo-checkbox {
            color: rgba(0, 0, 0, 0.54);
        }
    }
    .mat-select-value {
        color: $blackScreenColor;
    }
    .mat-pseudo-checkbox {
        color: rgba(255, 255, 255, 0.54);
    }
    .mat-select-arrow {
        color: rgba(255, 255, 255, 0.54);
    }
    .mat-card {
        background-color: $black-screen-background;
        color: $blackScreenColor;
        mat-card-actions {
            background-color: $black-screen-background;
            color: $blackScreenColor;
            border-top: 1px solid $blackScreenborder;
        }
    }

    .custom-flat-button .mat-button[disabled][disabled],
    .mat-button[disabled][disabled] {
        color: $blackScreenLightColor;
        &:hover {
            color: $blackScreenMidColor;
        }
    }
    #SliicerFlowMonitorChangesDialog {
        .mat-dialog-actions {
            border-top: 1px solid $blackScreenMidColor;
        }
        .btn-color {
            color: $blackScreenLightColor;
            &:hover {
                color: rgba(0, 0, 0, 0.38) !important;
            }
        }
    }
    mat-toolbar.mat-toolbar mat-toolbar-row.mat-toolbar-row.main-nav-menu .custom-nav-dropdown-content {
        a.each-nav-item.mat-button {
            color: $blackScreenColor;
            border-bottom: 0px solid #263238 !important;
            &:hover {
                border-bottom: 0px solid #ffffff !important;
            }
            &.active {
                border-bottom: 0px solid #ffffff !important;
            }
        }
    }
    .mat-tab-group {
        .mat-tab-nav-bar,
        .mat-tab-header {
            border-bottom: 1px solid rgba(255, 255, 255, 0.12);
        }
        .mat-tab-label,
        .mat-tab-link {
            color: rgba(255, 255, 255, 0.87);
        }
        .mat-ink-bar {
            background-color: #114b5f;
        }
    }
    .mat-dialog-container {
        background: $black-screen-background;
        color: $blackScreenColor;
        .mat-dialog-title {
            background: $light-black-screen-background;
            color: $blackScreenColor;
        }
        .mat-dialog-content {
            background: $black-screen-background;
            color: $blackScreenColor;
        }
        .mat-dialog-actions {
            background: $black-screen-background;
            color: $blackScreenColor;
        }
    }
    .mat-table {
        .mat-header-cell,
        .mat-cell {
            background-color: $black-screen-background;
            color: $blackScreenColor;
            border-bottom: 1px solid $blackScreenborder;
            border-top: 1px solid $blackScreenborder;
        }
        .mat-row {
            &:hover {
                background-color: $grey-black-screen-background;
            }
            &:last-child {
                .mat-cell {
                    border-bottom: 1px solid $blackScreenborder;
                }
            }
            .mat-cell {
                border-bottom: 1px solid $blackScreenborder; //border-right: 1px solid #e0e0e0;
            }
        }
    }
    .mat-paginator,
    .mat-paginator-page-size .mat-select-trigger {
        color: $blackScreenMidColor;
    }
    .mat-paginator {
        background: $black-screen-background;
    }
    mat-toolbar {
        &.mat-toolbar {
            background-color: $black-screen-background;
        }
    }
    .main-nav-menu {
        a.each-nav-item.mat-button {
            border-bottom: 3px solid $black-screen-background;
            &:hover {
                border-bottom: 3px solid #ffffff !important; // overriding
                opacity: 1;
            }
            &.active {
                opacity: 1;
                border-bottom: 3px solid #ffffff !important; // overriding
            }
        }
    }
    .datefilters {
        color: $blackScreenMidColor;
    }
    .notabledataFound {
        color: $blackScreenColor;
    }
    .date-range-picker-button {
        color: rgba(255, 255, 255, 0.87) !important;
    }
    .date-range-content {
        color: rgba(255, 255, 255, 0.87) !important;
    }

    #mapLocationAutoInput {
        border: 1px solid #ddd;
        background: $black-screen-background;
        .mat-input-flex {
            background: $black-screen-background;
        }
        .mat-form-field-underline {
            background-color: transparent !important;
            .mat-form-field-ripple {
                background-color: transparent !important;
            }
        }
        mat-icon {
            color: #fff;
        }
        .mat-form-field-infix {
            border-top: none;
        }
    }
    .google-map {
        .marker-legend {
            background-color: $black-screen-background;
            .sub-header {
                color: $blackScreenMidColor;
            }
            .header {
                color: $blackScreenColor;
            }
            .shape {
                border-bottom: 1px solid $blackScreenborder;
            }
        }
    }
    #google-popup {
        color: rgba(0, 0, 0, 0.87);
    }
    .googleMapRecenter {
        color: #000;
    }
    .popupHeader {
        background: $light-black-screen-background;
    }
    #vault-location-export-dialog {
        .save-setting-dialog {
            background-color: $light-black-screen-background;
            &:after {
                content: '';
                border-color: #424242 transparent transparent transparent;
            }
        }
    }
    .conformationContainer {
        .conformationContaint {
            background: $black-screen-background;
            .popupHeader {
                color: rgba(255, 255, 255, 0.87) !important;
            }
        }
    }
    #markerLocationClusterId {
        background: $black-screen-background;
        color: $blackScreenColor;
        border: 1px solid $light-black-screen-background;
        .mat-list .mat-list-item {
            color: $blackScreenColor;
        }
        .mat-list-item:hover {
            background: $blackScreenborder;
        }
    }
    .marker-location-details-general {
        background: $black-screen-background;
        color: $blackScreenColor;
    }
    #scheduleCollectionEditor {
        .daysLabel {
            color: rgba(255, 255, 255, 0.54);
        }
    }
    #locationDetailsId {
        .dialog-content {
            mat-radio-group.mat-radio-group[ng-reflect-disabled='true'] {
                span {
                    color: rgba(255, 255, 255, 0.48);
                }
            }
        }
        .mat-dialog-actions {
            .textTypeButton .mat-button {
                color: #2196f3;
            }
        }
        button.mat-fab.secondaryMatButton {
            background-color: transparent;
            color: #333;
            border: 1px solid #333;
            width: 46px;
            height: 46px;
            .mat-button-wrapper {
                padding: 10px 0;
                line-height: 0;
            }
        }
        .active-location-selection {
            mat-radio-button {
                &.mat-radio-button {
                    label {
                        color: rgba(255, 255, 255, 0.87);
                        div.mat-radio-container {
                            color: inherit;
                            div.mat-radio-outer-circle,
                            div.mat-radio-inner-circle,
                            div.mat-radio-ripple.mat-ripple {
                                color: inherit;
                            }
                        }
                    }
                }
                &.mat-radio-checked {
                    label {
                        color: rgba(255, 255, 255, 0.87);
                        div.mat-radio-container {
                            color: inherit;
                        }
                    }
                }
                &.mat-radio-disabled {
                    .mat-radio-label-content {
                        color: rgba(255, 255, 255, 0.38);
                    }
                }
                .mat-radio-outer-circle {
                    border-color: rgba(255, 255, 255, 0.54);
                }
                .mat-radio-disabled .mat-radio-outer-circle {
                    border-color: rgba(255, 255, 255, 0.38);
                }
            }
        }
    }
    .activeWidgeHeader {
        background: $black-screen-background;
    }
    .hydrographScale {
        background: $light-black-screen-background;
    }
    form#widgetFiltersForm {
        background: $light-black-screen-background;
    }
    .filterSection {
        background: $light-black-screen-background;
    }
    #leaderBoardContainer {
        .leaderBoard-item {
            .lB-item-top {
                background: $black-screen-background;
                .lB-item-top-lft {
                    color: $blackScreenColor;
                }
                .lB-item-top-rgt {
                    .item-col {
                        .item-content:last-child {
                            border-left: 1px solid $blackScreenborder;
                        }
                    }
                    .item-row {
                        .item-content:last-child {
                            border-top: 1px solid $blackScreenborder;
                        }
                    }
                    .item-value {
                        color: $blackScreenColor;
                    }
                    .item-text {
                        color: $blackScreenMidColor;
                    }
                }
            }
            .lB-item-but {
                color: $blackScreenMidColor;
                background: $light-black-screen-background;
                border-top: 2px solid $light-black-screen-background;
                border-bottom: 5px solid $light-black-screen-background;
            }
            .strong {
                color: $blackScreenColor;
            }
        }
    }
    #location-dashboard {
        .mat-form-field-label {
            color: $blackScreenMidColor;
        }
        .mat-input-element {
            caret-color: $blackScreenMidColor;
        }
        .hydrographScattergraphContainer {
            background-color: $light-black-screen-background;
            color: $blackScreenColor;
        }
        .mat-card:not([class*='mat-elevation-z']) {
            box-shadow: 0 3px 1px -2px rgba(255, 255, 255, 0.2), 0 2px 2px 0 rgba(255, 255, 255, 0.14),
                0 1px 5px 0 rgba(255, 255, 255, 0.12);
        }
        .dynamicContent {
            background: #424242 !important;
            color: rgba(255, 255, 255, 0.87) !important;
        }
        .addNewGraphContainer {
            background: $black-screen-background;
            box-shadow: 0px -1px 10px 9px rgba(255, 255, 255, 0.2), 0 2px 2px 0 rgba(255, 255, 255, 0.14),
                0 7px 5px 0 rgba(255, 255, 255, 0.12);
        }
    }
    #bpDailySummaryReportId, #bpHumanReviewId, #showDailySummaryReportId {
        .topmenu {
            background-color: #212121;
            color: rgba(255, 255, 255, 0.87);
        }
    }

    .location-info {
        color: $blackScreenColor;
    }

    .paginator-button {
        background-color: $black-screen-background;
    }

    #location-dashbord-filter,
    #bpHumanReviewId,
    #bpDailySummaryReportId,
    #showDailySummaryReportId {
        .location-info-bar {
            background-color: #212121;
            color: rgba(255, 255, 255, 0.87);
            .location-info-bar-controls {
                .filter-item {
                    border-color: rgba(255, 255, 255, 0.12);
                }
            }

            .mat-accordion {
                .location-info-expansion-panel {
                    background-color: #212121;
                    &.mat-expanded {
                        background-color: lighten($color: #212121, $amount: 10);
                        border-color: $blackScreenLightColor;
                    }
                }
            }
        }
        #locationDbSearch,
        #moreLocationDbOption {
            color: rgba(255, 255, 255, 0.87);
        }

        &.data-edit-mode-filter {
            .location-info-bar {
                background-color: #4f3005;
                color: $blackScreenColor;
                .location-info-expansion-panel {
                    &:not(.mat-expanded) {
                        background-color: #4f3005;
                    }
                    &.mat-expanded {
                        background-color: $black-screen-background;
                    }
                }
                .location-info-bar-controls {
                    .filter-item:first-of-type {
                        img {
                            height: 50px;
                            padding: 0 5px 0 0;
                        }
                    }
                    .filter-item {
                        border-color: #6d6c6c69;
                        border-right-width: 0.05rem;

                        &.location-info {
                            align-items: center;
                            display: flex;
                            padding-top: 0;
                        }

                        .mat-button {
                            color: darken($color: $border-color, $amount: 70);
                        }
                    }
                }
            }
        }
    }
    .customDateMenuContaint {
        .mat-input-element:disabled {
            color: rgba(0, 0, 0, 0.38) !important;
        }
    }
    .customHeaderLayout {
        background-color: $black-screen-background;
        color: $blackScreenColor;
    }
    #advanceHydroGraphId {
        .hydroGraphDetailsContainer,
        .scattergraphGraphDetailsContainer {
            background-color: $black-screen-background;
            color: $blackScreenColor;
            .noDataEntities {
                color: $blackScreenColor;
            }
            .advanceHydroGraphHeader {
                background-color: $black-screen-background;
                color: $blackScreenColor;
            }
            border-right: 1px solid $blackScreenborder;
        }
        .advanceGraph,
        .advanceGraphHeader {
            background-color: $black-screen-background;
            color: $blackScreenColor;
        }
        .edit-graph-filter-section {
            background: #424242 !important;
        }
        .bestfit-option {
            background: unset;
            .custom-in:before {
                content: url('./../images/svgIcon/in_white.svg');
            }
            .custom-out:before {
                content: url('./../images/svgIcon/out_white.svg');
            }
            .custom-flag:after {
                content: url('./../images/svgIcon/flag_white.svg');
            }
            .custom-unflag:after {
                content: url('./../images/svgIcon/unflag_white.svg');
            }
            .custom-magic:after {
                content: url('./../images/svgIcon/magic_white.svg');
            }
            .custom-down:before {
                content: url('./../images/svgIcon/down_white.svg');
            }
            .custom-up:before {
                content: url('./../images/svgIcon/up_white.svg');
            }

            .custom-apply:after {
                content: url('./../images/svgIcon/snap.svg');
            }
            .custom-eraser:after {
                content: url('./../images/svgIcon/eraser.svg');
            }
            .custom-drag:after {
                content: url('./../images/svgIcon/drag.svg');
            }

            .adjustLine.mat-button[disabled][disabled] {
                color: #fff !important;
                opacity: 0.36;
            }

            .pre-edit-controls button {
                background: #fff !important;
                color: #000 !important;
            }
        }
        .filter-operation {
            background: $light-black-screen-background;
        }
    }
    .edit-graph-filter-section .bestfit-option .pre-edit-controls .mat-button[disabled][disabled] {
        opacity: 0.26;
    }
    .edit-graph-filter-section .bestfit-option .adjustLine .adjust-line-tool-content {
        border-bottom: 2px solid rgba(255, 255, 255, 0.42);
    }

    #customDateSelector {
        color: rgba(255, 255, 255, 0.87) !important;
        border-bottom: 2px solid rgba(255, 255, 255, 0.42) !important;
    }
    .customDateMenuContaint {
        .mat-form-field {
            color: $blackScreenMidColor;
        }
        .mat-form-field-label {
            color: $blackScreenLightColor;
        }
        .mat-form-field-underline {
            background-color: $blackscreen-borderColor;
        }
    }
    .ar-footer-on-hint {
        background: $black-screen-dark-background;
    }
    .vaultPageHint {
        .vaultCreateFolderHint,
        .vaultUploadHint,
        .vaultExportHint {
            &.mat-button[disabled][disabled] {
                color: #2196f3 !important;
            }
        }
    }
    .vault {
        app-vault-nav {
            background-color: $black-screen-background;
        }
        .vault-list {
            .menu {
                background-color: $black-screen-background;
            }
            .vault-file-container {
                background-color: $black-screen-background;
                .vault-header {
                    .breadcrumb {
                        color: $blackScreenColor;
                        span {
                            color: #2196f3;
                            &:last-child {
                                color: $blackScreenColor;
                            }
                        }
                    }
                }
            }
        }
        #valutItemDataLeaderBoardTable {
            margin-left: -15px;
            background-color: $black-screen-background;
            .mat-table {
                width: calc(100% - 20px);
                padding-left: 15px;
                padding-right: 15px;
                background-color: $black-screen-background;
            }
        }
        .valutItemDataLeaderBoardPaginator {
            margin-left: -20px;
            margin-right: -20px;
        }
    }
    .vault-file-upload-container {
        .vault-file-upload-containt {
            .mat-expansion-panel-header-title {
                span {
                    padding-right: 10px;
                    &.light {
                        color: rgba(255, 255, 255, 0.36);
                    }
                    &.error {
                        color: #f44336;
                    }
                }
            }
            .mat-expansion-panel {
                background: #212121;
                color: rgba(255, 255, 255, 0.87);
                .mat-expansion-panel-header-title {
                    color: rgba(255, 255, 255, 0.87);
                }
                .mat-expansion-indicator::after {
                    color: rgba(255, 255, 255, 0.54);
                }
            }
            .mat-expansion-panel:not([class*='mat-elevation-z']) {
                box-shadow: 0 3px 1px -2px rgba(255, 255, 255, 0.2), 0 2px 2px 0 rgba(255, 255, 255, 0.14),
                    0 1px 5px 0 rgba(255, 255, 255, 0.12);
            }
            .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']):hover {
                background: rgba(255, 255, 255, 0.04);
            }
        }
        .vault-file-upload-footer {
            background-color: #212121;
        }
    }
    #vaultShareDialog {
        .shareLinkButton .mat-button[disabled][disabled]:hover {
            color: rgba(255, 255, 255, 0.38) !important;
        }
    }
    #valutItemDataLeaderBoardTable {
        .fileTypeIcon_default {
            background-position: -245px -132px;
        }
        .fileTypeIcon_pdf {
            background-position: -27px -29px;
        }
        .fileTypeIcon_csv {
            background-position: -188px -81px;
        }
        .fileTypeIcon_doc {
            background-position: -245px -29px;
        }
        .fileTypeIcon_gif {
            background-position: -82px -29px;
        }
        .fileTypeIcon_jpg {
            background-position: -188px -29px;
        }
        .fileTypeIcon_ppt {
            background-position: -28px -81px;
        }
        .fileTypeIcon_tif {
            background-position: -81px -81px;
        }
        .fileTypeIcon_xls {
            background-position: -133px -29px;
        }
        .fileTypeIcon_png {
            background-position: -136px -81px;
        }
    }
    .long-table {
        background: $black-screen-background;
        color: rgba(255, 255, 255, 0.87);
        .mat-expansion-panel {
            background: $black-screen-background;
            color: rgba(255, 255, 255, 0.87);
            .mat-expansion-panel-header-title {
                color: rgba(255, 255, 255, 0.87);
            }
            .mat-expansion-indicator::after {
                color: rgba(255, 255, 255, 0.54);
            }
        }
        .mat-expansion-panel:not([class*='mat-elevation-z']) {
            box-shadow: 0 3px 1px -2px rgba(255, 255, 255, 0.2), 0 2px 2px 0 rgba(255, 255, 255, 0.14),
                0 1px 5px 0 rgba(255, 255, 255, 0.12);
        }
        .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']):hover {
            background: rgba(255, 255, 255, 0.04);
        }
        .mat-expansion-panel-header:not([aria-expanded='true']) {
            background: unset;
        }
    }
    #userPreferencesDialog {
        .mat-expansion-panel {
            background: $black-screen-background;
            color: rgba(255, 255, 255, 0.87);
            .mat-expansion-panel-header-title {
                color: rgba(255, 255, 255, 0.87);
            }
            .mat-expansion-indicator::after {
                color: rgba(255, 255, 255, 0.54);
            }
        }
        .mat-expansion-panel:not([class*='mat-elevation-z']) {
            box-shadow: 0 3px 1px -2px rgba(255, 255, 255, 0.2), 0 2px 2px 0 rgba(255, 255, 255, 0.14),
                0 1px 5px 0 rgba(255, 255, 255, 0.12);
        }
        .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']):hover {
            background: rgba(255, 255, 255, 0.04);
        }
        .mat-expansion-panel-header:not([aria-expanded='true']) {
            background: unset;
        }
        .mat-table {
            background: $black-screen-background;
            .mat-header-row {
                border-bottom: 1px solid rgba(255, 255, 255, 0.42);
                border-top: 1px solid rgba(255, 255, 255, 0.42);
                .mat-header-cell {
                    border: 0 !important;
                }
            }
            .mat-row {
                border-bottom-color: rgba(255, 255, 255, 0.42);
                &:hover {
                    background: none;
                }
                .mat-cell {
                    border: 0 !important;
                }
            }
        }
        .mat-dialog-content {
            .mat-card-subtitle {
                color: rgba(255, 255, 255, 0.87);
            }
        }
    }
    #dailySummaryOverviewId {
        th {
            border: 0px;
            border-right: 1px solid $blackScreenborder;
            &:last-child {
                border-right: 0px solid $blackScreenborder;
            }
        }
    }
    .hydrographScaleOptions {
        background: $light-black-screen-background;
    }
    .light-table-bordered {
        border: 0;
        background: $black-screen-background;
        > thead {
            > tr {
                &:first-child {
                    background-color: $light-black-screen-background;
                    color: $blackScreenColor;
                }
                &:last-child {
                    background-color: $black-screen-background;
                    color: $blackScreenColor;
                    border-bottom: 1px solid $blackScreenborder;
                }
            }
        }
        > tbody,
        > tfoot {
            > tr {
                > th,
                > td {
                    border-bottom: 1px solid $blackScreenborder;
                    color: $blackScreenColor;
                    background: $black-screen-background;
                    border-right: 1px solid $blackScreenborder;
                    &:last-child {
                        border-bottom: 1px solid $blackScreenborder;
                        background: $black-screen-background;
                        color: $blackScreenColor;
                        border-right: 0px solid $blackScreenborder;
                    }
                }
            }
        }
    }
    #percentFullReportId {
        th {
            border: 0px;
            border-right: 1px solid $blackScreenborder;
            &:last-child {
                border-right: 0px solid $blackScreenborder;
            }
        }
    }
    #contactUsContainerId {
        .details {
            a {
                color: rgba(255, 255, 255, 0.87);
            }
        }
    }
    .vault-delete-action {
        .mat-dialog-title {
            background: transparent !important;
            color: $blackScreenColor;
        }
    }
    .vault-move-action {
        .mat-tree-border {
            border: 1px solid $blackScreenborder;
        }
        .mat-tree {
            background: $black-screen-background;
            .mat-tree-node {
                background: $black-screen-background;
                color: $blackScreenColor;
            }
        }
    }
    #bpDailySummaryReportId,
    #bpHumanReviewId,
    #showDailySummaryReportId {
        th {
            border: 0px;
            border-right: 1px solid $blackScreenborder;
            &:last-child {
                border-right: 0px solid $blackScreenborder;
            }
        }
        #dailySummaryAccordion {
            .mat-expansion-panel {
                background: $black-screen-background;
                color: rgba(255, 255, 255, 0.87);
                .mat-expansion-panel-header-title {
                    color: rgba(255, 255, 255, 0.87);
                }
                .mat-expansion-indicator::after {
                    color: rgba(255, 255, 255, 0.54);
                }
            }
            .mat-expansion-panel-body {
                background: #616161;
            }
        }
    }
    #location-dashbord-synopsis-leaderBoard {
        .location-dashbord-synopsis {
            .lB-item-top {
                background: $black-screen-background;
                .lB-item-top-lft {
                    color: $blackScreenColor;
                }
                .lB-item-top-rgt {
                    .item-col {
                        .item-content:last-child {
                            border-left: 1px solid $blackScreenborder;
                        }
                    }
                    .item-row {
                        .item-content:last-child {
                            border-top: 1px solid $blackScreenborder;
                        }
                    }
                    .item-value {
                        color: $blackScreenColor;
                    }
                    .item-text {
                        color: $blackScreenMidColor;
                    }
                }
            }
            .strong {
                color: $blackScreenColor;
            }
        }
    }
    .custom-location-dashboard {
        .location-details {
            background: $black-screen-background;
            color: $blackScreenColor;
        }
        .graph-background {
            background: $black-screen-background;
        }
        h3 {
            color: $blackScreenColor;
        }
    }
    .add-graph {
        .entity-container {
            border-color: $blackScreenborder;
        }
    }
    #calculatedEntityDialog {
        .mat-dialog-content {
            .mat-tab-labels {
                background: #424242 !important;
            }
            .form-validate {
                border-top: none !important;
            }
            .mat-tab-body-wrapper {
                .variables-container {
                    .variable-list {
                        .variable-space {
                            color: rgba(255, 255, 255, 0.87);
                        }
                    }
                }
            }
        }
        .mat-dialog-actions {
            border-top: 1px solid $blackScreenMidColor;
            background: none !important;
        }
    }
    #aboutUsDialog {
        .mat-dialog-title {
            margin: 0px;
        }
        .mat-dialog-container .mat-dialog-title {
            background: #616161 !important;
            color: rgba(255, 255, 255, 0.87) !important;
        }
    }
    #CaseStudyDetails {
        background-color: rgb(97, 97, 97) !important;
        .locationDetails {
            background-color: $black-screen-background;
            color: rgba(255, 255, 255, 0.87) !important;
        }
        .summary_table {
            background-color: $black-screen-background;
            color: rgba(255, 255, 255, 0.87) !important;
            td {
                border-color: $blackScreenMidColor;
            }
        }
        .study-stepper {
            background: black;
            .mat-tab-header {
                border-bottom: 1px solid solid $blackScreenColor;
            }
            .study-color {
                background: black;
                .mat-step-label {
                    color: $blackScreenColor;
                }
            }
        }
        .summary-details {
            background-color: $black-screen-background;
            color: rgba(255, 255, 255, 0.87) !important;
            border-radius: 0;
            .createStudyWizard,
            .create-study-wizard {
                border-top-color: $blackScreenMidColor !important;
                .mat-horizontal-stepper-header-container {
                    background-color: $black-screen-background;
                    .mat-step-header {
                        // color: rgba(255, 255, 255, 0.87) !important;
                        .mat-step-label {
                            color: rgba(255, 255, 255, 0.87) !important;
                        }
                    }
                    .mat-stepper-horizontal-line {
                        border-color: $blackScreenMidColor;
                    }
                }
                .stepperAction,
                .stepper-action {
                    background-color: $black-screen-background;
                    border-top-color: $blackScreenMidColor;
                }
            }
            .study-button-background {
                background: $light-black-screen-background;
                .border-right {
                    border-right: 1px solid black;
                    padding-right: 3px;
                }
                .padding-le {
                    padding-left: 3px;
                }
            }
            .mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
                color: $white-color;
            }
            ul.list {
                color: $blackScreenColor;
            }
            .basin-wrapper {
                .mat-selection-list {
                    background-color: $black-screen-background;
                }
                .list-box {
                    .mat-radio-group {
                        background-color: $black-screen-background;
                    }
                    .upstream-name {
                        color: $white-color;
                    }
                }
            }

            #rainfall-event-table {
                .mat-cell:nth-child(2) {
                    width: 150px;
                    background-color: $background-color;
                    border-right: 1px solid $border-color;
                }
                .mat-header-cell:nth-child(2) {
                    background-color: $background-color;
                }
            }
        }
        .custom-vertical-tab {
            background-color: $black-screen-background;
            .uploadIcon {
                box-shadow: 0 3px 1px -2px rgba(255, 255, 255, 0.2), 0 2px 2px 0 rgba(255, 255, 255, 0.14),
                    0 1px 5px 0 rgba(255, 255, 255, 0.12) !important;
            }
            .export-storm,
            #hyetograph,
            #dayweekGroupPageTable {
                .mat-header-cell,
                .mat-cell {
                    background-color: $black-screen-background;
                }
            }
            .data-head {
                .rainfall {
                    background-color: $black-screen-background;
                }
                .duration {
                    background-color: $background-color;
                }
            }
            .mat-tab-body-wrapper .mat-tab-group.mat-primary .mat-tab-labels {
                background-color: $black-screen-background;
            }
        }
        .custom-stepper {
            .stepperAction,
            .stepper-action {
                border-top: 1px solid rgba(255, 255, 255, 0.12);
            }
            .lockstudy {
                color: rgba(255, 255, 255, 0.87) !important;
            }
        }
        .list {
            .mat-pseudo-checkbox {
                color: rgba(0, 0, 0, 0.54);
            }
        }

        .customDateRangeSelector.mat-button {
            width: 100%;
            padding-left: 0px;
            text-align: left;
            border-bottom: 2px solid rgba(255, 255, 255, 0.42);
            color: $blackScreenColor;
        }
        .lockstudy {
            color: rgba(255, 255, 255, 0.87) !important;
        }
        .study-setup {
            .bg-color > .mat-tab-header {
                background: $black-screen-background;
                color: $blackScreenColor;
            }
        }
    }
    #SlicerDashboardId {
        .mat-icon-button[disabled][disabled] {
            color: $blackScreenLightColor;
        }
    }
    #stormTable {
        .custom-strom:before {
            content: url('./../images/svgIcon/Icon_Storm_events-white.svg');
            width: 28px;
            display: inline-block;
            vertical-align: middle;
            position: relative;
            top: 1px;

            padding-top: 5px;
            padding-right: 13px;
            vertical-align: middle;
        }
    }

    #FlowMonitoringReportId {
        .mat-icon-button[disabled][disabled] {
            color: $blackScreenLightColor;
        }
    }
    .flow-monitor-container {
        .label-container {
            .border-radius {
                display: inline-block;
                border-radius: 26px;
                border: 1.2px solid $white-color;
                padding: 13px;
                box-shadow: 0 8px 6px -6px $white-color;
                .icon-align {
                    cursor: pointer;
                }
            }
        }
    }

    #customerRainProfileId {
        .custom-vertical-tab {
            ul.list {
                color: $blackScreenColor;
            }
        }
    }
    #vaultTelemetryDialog {
        .uploaded-files {
            background: $light-black-screen-background;
        }
        .telemetry-validate-section {
            .mat-expansion-panel:not([class*='mat-elevation-z']) {
                border-bottom: 1px solid rgba(255, 255, 255, 0.12);
            }
        }
    }

    #locationEditorDialog,
    #locationEditorTritonDialog,
    #vaultTelemetryDialog {
        .mat-expansion-panel {
            background: $black-screen-background;
            box-shadow: 0 3px 1px -2px rgba(255, 255, 255, 0.2), 0 2px 2px 0 rgba(255, 255, 255, 0.14),
                0 1px 5px 0 rgba(255, 255, 255, 0.12);
            .mat-expansion-panel-header {
                background: $black-screen-background;
                .mat-expansion-panel-header-title {
                    color: $blackScreenColor;
                }
            }
            .mat-expansion-panel-content {
                color: rgba(255, 255, 255, 0.87);
            }
            .toggle-text-disabled {
                color: rgba(255, 255, 255, 0.87);
            }
            .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
                color: rgba(255, 255, 255, 0.87);
            }
        }
        .mat-expansion-indicator::after {
            color: rgba(255, 255, 255, 0.87);
        }
    }
    .collection-action {
        button.mat-icon-button {
            color: #fff !important;
        }
        .mat-icon-button[disabled][disabled] {
            color: rgba(255, 255, 255, 0.26) !important;
        }
    }

    #map-location-details {
        #locationEditorTritonDialog {
            .mat-dialog-content,
            .mat-dialog-actions {
                background: $black-screen-background;
                color: $blackScreenColor;
            }
        }
    }
    #locationEditorTritonDialog {
        .data-delivery-section-error,
        .basic-info-section-error,
        .triton-plus-section-error,
        .alarms-config-section-error,
        .peak-combo-section-error,
        .surface-combo-section-error,
        .av-gated-section-error,
        .echo-section-error,
        .foresite-section-error,
        .echo-long-range-section-error {
            mat-panel-title,
            mat-panel-description,
            .mat-icon {
                color: #f44336 !important;
            }
            .ng-invalid.required .mat-form-field-label-wrapper .mat-form-field-label {
                color: #f44336;
            }
            .ng-invalid.required .mat-form-field-underline {
                background: #f44336 !important;
            }
            .mat-form-field .mat-select-placeholder {
                color: #f44336 !important;
            }
            .ng-invalid.required .mat-form-field-label-wrapper .mat-form-field-label {
                color: #f44336 !important;
            }
            .mat-expansion-panel-header .mat-expansion-panel-header-title {
                color: #f44336 !important;
            }
        }
    }
    @mixin cell() {
        background-color: $table-first-child-color;
        border-right: 1px solid $blackscreen-borderColor;
    }

    #geometry-table,
    #rainfall-event-table {
        .mat-table {
            .mat-header-cell:first-child {
                @include cell();
            }
            .mat-cell:first-child {
                @include cell();
            }
        }
    }

    #rainfall-event-table {
        .mat-table {
            .mat-header-cell:nth-child(2) {
                background-color: $table-first-child-color;
            }
        }
    }

    #rainfall-distribution-table {
        .mat-table {
            .mat-header-cell:first-child {
                @include cell();
            }
            .mat-cell:first-child {
                @include cell();
            }
        }
    }

    #resonForEditId {
        background: $black-screen-background;
        color: $blackScreenColor;
    }
    .multipleEditorSection {
        background: $light-black-screen-background;
    }
    .dynamic-dashboard-label {
        color: $blackScreenColor;
    }
    .header-divider {
        .header-text {
            color: $blackScreenColor;
        }
    }
    .view-toggle {
        .mat-button-toggle-group {
            .mat-button-toggle-appearance-standard {
                color: rgba(255, 255, 255, 0.54);
            }
        }
    }
    .vault-file-preview {
        .each-file {
            border: 1px solid $blackScreenborder;
            color: $blackScreenColor;
            &:hover {
                box-shadow: 0px 3px 1px -2px rgba(255, 255, 255, 0.2), 0px 2px 2px 0px rgba(255, 255, 255, 0.14),
                    0px 1px 5px 0px rgba(255, 255, 255, 0.12);
            }
            .file-preview {
                border-bottom: 1px solid $blackScreenborder;
            }
        }
        .file-action {
            background: rgb(82, 81, 81);
            .mat-button {
                color: inherit;
                border-left: 1px solid $blackScreenborder;
            }
        }
    }
    .vault-file-list {
        border: 1px solid rgba(255, 255, 255, 0.12);
    }
    .vault-folder-list {
        .each-file {
            background-color: transparent;
            border: 1px solid $blackScreenborder;
            &:hover {
                box-shadow: 0px 3px 1px -2px rgba(255, 255, 255, 0.2), 0px 2px 2px 0px rgba(255, 255, 255, 0.14),
                    0px 1px 5px 0px rgba(255, 255, 255, 0.12);
            }
            .folder-name {
                background: transparent;
                color: $blackScreenColor;
            }
            .mat-button {
                color: $blackScreenColor;
                border-left: 1px solid $blackScreenborder;
            }
        }
    }
    .folder-accordion {
        .mat-expansion-panel {
            background: $black-screen-background;
            color: rgba(255, 255, 255, 0.87);
            .mat-expansion-panel-header {
                background: $light-black-screen-background;
            }
            .mat-expansion-panel-header-title {
                color: rgba(255, 255, 255, 0.87);
            }
            .mat-expansion-indicator::after {
                color: rgba(255, 255, 255, 0.54);
            }
        }
        .mat-expansion-panel:not([class*='mat-elevation-z']) {
            border: 1px solid rgba(255, 255, 255, 0.12);
        }
        .mat-expansion-panel-body {
            background: $black-screen-background;
        }
    }
    .location-files {
        .breadcrumb {
            color: $blackScreenColor;
            span {
                color: #2196f3;
                &:last-child {
                    color: $blackScreenColor;
                }
            }
        }
    }
    .blackThemeTable {
        background-color: $black-screen-background;
        > thead > tr {
            &:first-child {
                background-color: $light-black-screen-background;
                color: $blackScreenColor;
            }
            &:last-child {
                background-color: $black-screen-background;
                color: $blackScreenMidColor;
            }
            > th {
                border-bottom: 1px solid $blackScreenborder;
                border-right: 1px solid $blackScreenborder;
            }
        }
        > th {
            color: $blackScreenColor;
            border-bottom: 1px solid $blackScreenborder;
        }
        > caption + thead,
        > colgroup + thead,
        > thead:first-child {
            > tr:first-child {
                > th,
                > td {
                    border-top: 0;
                }
            }
        }
        > tbody + tbody {
            border-top: 1px solid $blackScreenborder;
        } // Nesting
        > colgroup {
            background-color: unset;
        }
        > thead,
        > tbody,
        > tfoot {
            > tr {
                > th,
                > td {
                    border-bottom: 1px solid $blackScreenborder;
                }
                > td {
                    border-right: 1px solid $blackScreenborder;
                    &:last-child {
                        border-right: 0px solid $blackScreenborder;
                    }
                }
            }
        }
        > tbody > tr:hover {
            > td,
            > th {
                background-color: $blackScreenborder;
            }
        }
    }
    .bottom-border {
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    }
    .top-border {
        border-top: 1px solid rgba(255, 255, 255, 0.12);
    }
    .associated-entity,
    .associated-flow-balance,
    .plotting-confirmations,
    .location-files {
        background-color: #212121;
    }
    #ViewDataLocationDetails {
        background-color: #616161;
        color: $blackScreenColor;
        .locationDetails {
            background-color: $black-screen-background;
            color: $blackScreenColor;
        }
        .details-page {
            background-color: $black-screen-background;
            color: $blackScreenColor;
            .mat-tab-group {
                .mat-tab-header {
                    border-right: 1px solid $blackScreenborder;
                    .mat-tab-list {
                        background: $light-black-screen-background;
                    }
                    .mat-tab-labels {
                        .mat-tab-label {
                            border-bottom: 1px solid $blackScreenborder;
                            border-left: 4px solid transparent;
                        }
                    }
                    .mat-tab-label-active {
                        border-left: 4px solid #5cb259 !important;
                        opacity: 1;
                        font-weight: 600;
                    }
                }
            }
            .locationdet {
                background-color: #212121;
                border-bottom: 1px solid rgba(255, 255, 255, 0.12);
            }
            .reportGraphContentContainer {
                background: #212121;
            }
            .reportGraphContent {
                .mat-card {
                    border-left: 1px solid rgba(255, 255, 255, 0.12);
                }
            }
        }
        .locationdet {
            background-color: $black-screen-background;
            .details {
                .header {
                    background-color: $black-screen-background;
                    color: $blackScreenColor;
                }
                .location_series {
                    background-color: $black-screen-background;
                    color: $blackScreenColor;
                }
            }
        }

        .install_details,
        .install-details {
            background-color: $black-screen-background;
            color: $blackScreenColor;
        }
        .associated_entity,
        .associated-entity {
            background-color: $black-screen-background;
            color: $blackScreenColor;
        }
        .graphs {
            background-color: $black-screen-background;
            color: $blackScreenColor;
        }
        .dateRangeHeader {
            background-color: $black-screen-background;
            color: $blackScreenColor;
        }
    }
    .location-detail-section {
        .each-details {
            border: 1px solid rgba(255, 255, 255, 0.12);
        }
    }
    #calculatedEntityDialog {
        .dialogue {
            background-color: $light-black-screen-background;
        }
    }

    .customDateRangeSelector.mat-button {
        width: 100%;
        padding-left: 0px;
        text-align: left;
        border-bottom: 2px solid rgba(255, 255, 255, 0.42);
        color: $blackScreenColor;
    }
    #sliicerMarketingDialog {
        .big,
        .normal {
            color: rgba(0, 0, 0, 0.87);
        }
    }



    @mixin hintOverridePlusBorder() { background: #000 !important; color: #fff !important; border: 2px #fff solid;}
    @mixin hintOverride() { background: #000 !important; color: #fff !important;}

    .showTopHint {
        .data-communication-hint {
            .data-comm-filter-hint,
            .data-comm-full-hint,
            .data-comm-close-hint,
            .data-comm-title-hint
            { @include hintOverridePlusBorder() }
        }
        .homePageIconHint {
            .customer-selector-hint,
            .location-group-hint,
            .active-inactive-hint,
            .notification-hint,
            .hintIcon,
            .user-hint
            { @include hintOverride() }

            .pin-hint,
            .widgets-hint,
            .locationSearch
            { @include hintOverridePlusBorder() }
        }
        .homePageSecondTab {
            .more-options-menu-hint,
            .layers-hint,
            .basemap-hint,
            .add-monitor-hint,
            .monitor-hint,
            .show-legend-hint,
            .feat-select-hint
            { @include hintOverridePlusBorder() }

            .zoom-in-hint,
            .transparency-hint
            { @include hintOverride() }
        }

        .homePageThirdTab {
            .selection-menu-mock,
            .measure-menu-mock

            { @include hintOverridePlusBorder() }

            .measure-menu-mock button,
            .measure-menu-mock button mat-icon,
            .tools-hint
            { @include hintOverride() }
        }

        .homePageFourthTab {
            .edit-mon-hint,
            .child-search-hint,
            .monitor-name-hint,
            .add-mon-hint,
            .mon-search-hint
            { @include hintOverridePlusBorder() }
        }

        .audit-log-hint {
            .audit-title-hint,
            .audit-download-hint,
            .audit-filter-hint,
            .audit-search-hint,
            .audit-date-hint,
            .audit-locations-hint,
            .audit-loc-group-hint
            { @include hintOverride() }
        }

        .location-dashBoard-edit-hint {
            .cancel-button-hint,
            .accept-button-hint,
            .preview-button-hint,
            .hydrograph-expand,
            .scattergraph-expand,
            .hydrograph-filter,
            .more-option-hint
            { @include hintOverride() }
        }

        .scheduleDashboardHint {
            .schedule-title-hint,
            .add-schedule-hint,
            .filter-hint,
            .name-filter-hint,
            .week-days-hint,
            .frequency-hint,
            .edit-schedule-hint,
            .delete-hint
            { @include hintOverride() }
        }

        .location-dashBoard-edit-sg-hint {
            .clear-data-hint,
            .clear-selection-hint,
            .manually-edit-hint,
            .remove-flag-hint,
            .flag-data-hint,
            .drag-data-hint,
            .select-entity-hint,
            .snap-select-hint,
            .scattergrph-flag-data-hint,
            .zoom-data-hint,
            .scattergraph-clear-selection-hint,
            .select-data-hint,
            .scattergraph-remove-flag-hint,
            .snap-curve-hint
            { @include hintOverride() }
        }

        .edit-user-details {
            .save-user-hint
            { @include hintOverride() }
        }

        .edit-user-role {
            .roles-hint
            { @include hintOverride() }
        }

        .edit-user-customer {
            .customer-search-hint,
            .customer-list-hint
            { @include hintOverride() }
        }

        .edit-user-location {
            .select-all-hint,
            .customer-select-hint,
            .location-list-hint
            { @include hintOverride() }
        }

        .edit-user-features {
            .features-hint
            { @include hintOverride() }
        }

        .users-page-hint {
            .search-users-hint,
            .user-status-hint,
            .user-edit-hint
            { @include hintOverride() }
        }

        .view-location-details-hint {
            .edit-button-hint,
            .collect-button-hint,
            .schedule-collect-button-hint,
            .export-button-hint,
            .percent-full-hint,
            .daily-summary-hint,
            .uptime-hint,
            .details-hint
            { @include hintOverride() }
        }

        .view-location-associated-entity-hint {
            .add-entity-hint,
            .ansr-hint
            { @include hintOverride() }
        }

        .view-location-confirmation-points-hint {
            .add-confirmation-hint,
            .confirmation-edit-hint,
            .confirmation-delete-hint,
            .download-confirmation-hint,
            .ansr-edit-hint,
            .ansr-delete-hint,
            .confirmation-hint
            { @include hintOverride() }
        }

        .view-location-files-hint {
            .show-hide-subfolders-hint .mat-expansion-indicator,
            .create-folder-hint,
            .file-share-hint,
            .file-download-hint,
            .file-import-hint,
            .file-delete-hint,
            .upload-file-hint,
            .files-hint
            { @include hintOverride() }
        }

        .view-location-advanced-hint {
            .advanced-hint,
            .advanced-filter-hint,
            .diagnostics-hint,
            .date-picker-hint,
            .date-time-hint,
            .user-name-hint,
            .result-hint
            { @include hintOverride() }
        }

        .view-location-notes-hint {
            .add-note-hint,
            .filter-hint
            .ansr-edit-hint,
            .ansr-delete-hint,
            .notes-hint
            { @include hintOverride() }
        }
        .view-location-flow-balance-hint {
            .generate-button-hint,
            .flow-balance-edit-hint,
            .flow-balance-hint
            { @include hintOverride() }
        }

        .vaultThirdHint {
            .upload-menu-mock,
            .export-menu-mock,
            .export-menu-mock button
            { @include hintOverride() }
        }

        .data-editing-audit-hint {
            .data-audit-title-hint,
            .data-audit-export-hint,
            .data-audit-filter-hint
            { @include hintOverride() }
        }

        .dailySummaryOverviewHint {
            .daily-title-hint,
            .daily-download-hint
            { @include hintOverride() }
        }
        .dailySummaryDetailsHint {
            .dailySummaryDetailsDownloadHint,
            .dailySummaryDetailsFilterHint
            { @include hintOverride() }
        }
        .percentFullHint {
            .percentFullDownloadHint,
            .percentTitleHint,
            .percentFullFilterHint,
            .percent-date-hint,
            .percent-locations-hint,
            .percent-yesterday-hint,
            .percent-date-column-hint
            { @include hintOverride() }
        }
        .uptimeOverviewHint {
            .uptime-title-hint,
            .uptime-download-hint,
            .uptime-filter-hint
            { @include hintOverride() }
        }
        .uptimeDetailsHint {
            .uptimeDetailsDownloadHint,
            .uptimeDetailsFilterHint
            { @include hintOverride() }
        }
    }

    .dialog-name-hint,
    .dialog-search-hint,
    .dialog-locations-hint,
    .dialog-days-hint,
    .dialog-collect-hint,
    .dialog-frequency-hint,
    .dialog-event-hint,
    .dialog-cancel-hint,
    .dialog-save-hint
    { @include hintOverride() }

    .dialog-name-hint,
    .dialog-search-hint,
    .dialog-locations-hint,
    .dialog-days-hint,
    .dialog-collect-hint,
    .dialog-frequency-hint,
    .dialog-event-hint,
    .dialog-cancel-hint,
    .dialog-save-hint
    { @include hintOverride() }

    .daily-type-hint,
    .daily-dialog-locations-hint,
    .daily-dialog-entity-hint,
    .daily-dialog-date-hint,
    .daily-dialog-cancel-hint,
    .daily-dialog-export-hint
    { @include hintOverride() }

    .hint-flow-monitoring-report {
        .fmrHintFFlowMonitoringReport,
        .fmrHintLocationGroup,
        .fmrHintSearch,
        .fmrHintExport,
        .fmrHintIncludeInactiveLocations
        { @include hintOverride() }
    }

    .block-editor-dialog-condition-location-hint,
    .block-editor-dialog-condition-entity-hint,
    .block-editor-dialog-condition-operator-hint,
    .block-editor-dialog-condition-value-hint,

    .block-editor-dialog-entity-hint,
    .block-editor-dialog-action-hint,
    .block-editor-dialog-valueentity-hint,
    .block-editor-dialog-entityvalue-hint,

    .block-editor-dialog-add-hint,
    .block-editor-dialog-apply-hint,
    .block-editor-dialog-close-hint
    { @include hintOverride() }

    // Flexmonster
    // #fm-pivot-view {
    fm-pivot {
        div.fm-toolbar-ui ul {
            background-color: $black-screen-background;
            color: $white-color;
        }

        .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header .fm-search-wrap .fm-search-btn:hover,
        #fm-toolbar-wrapper #fm-toolbar .fm-dropdown-content li:hover {
            background-color: $light-black-screen-background;
        }

        #fm-toolbar-wrapper {
            div.fm-popup {
                background-color: $black-screen-dark-background;
                color: $white-color;
            }

            .fm-toolbar-ui div.fm-ui-dropdown .fm-ui-dropdown-list li:hover {
                background-color: $grey-black-screen-background;
            }

            div.fm-ui-dropdown .fm-ui-dropdown-list ul li.fm-selected {
                background-color: $grey-black-screen-background;
            }

            div.fm-ui-dropdown .fm-ui-dropdown-btn,
            .fm-toolbar-ui .fm-ir-horizontal .fm-ui-dropdown,
            .fm-toolbar-ui .fm-ir-horizontal .fm-inp
            {
                background-color: $light-black-screen-background;
                color: $white-color;
            }

            .fm-toolbar-ui li,
            .fm-toolbar-ui .fm-radio-wrap label,
            div.fm-panel .fm-panel-content .fm-title-bar .fm-title-text {
                color: $white-color;
            }

            div.fm-modal-overlay {
                background-color: $blackScreenLightColor;
            }
        }

        #fm-pivot-view, #fm-toolbar-wrapper {
            span.fm-ui-label,
            .fm-ui span {
                color: $white-color;
            }

            input[type=text].fm-ui-text-input,
            input[type=number],
            input[type=password],
            input[type=text]
             {
                background-color: $black-screen-background;
                color: $white-color;
                border-color: $blackScreenborder;
            }

            button {
                background-color: $black-screen-background;
                color: $white-color;
            }

            div.fm-ui-dropdown .fm-ui-dropdown-list {
                background-color: $black-screen-background;
                color: $white-color;
            }
            div.fm-ui-dropdown .fm-ui-dropdown-list ul li .fm-ui-label, div.fm-ui-dropdown .fm-ui-dropdown-list ul li .fm-ui-label {
                color: $white-color;
            }
            div.fm-ui-dropdown .fm-ui-dropdown-list ul li.fm-selected, div.fm-ui-dropdown .fm-ui-dropdown-list ul li.fm-selected,
            div.fm-ui-dropdown .fm-ui-dropdown-list ul li:hover, div.fm-ui-dropdown .fm-ui-dropdown-list ul li:hover {
                background-color: $grey-black-screen-background;
            }
        }

        #fm-pivot-view {
            background-color: $black-screen-background;
            color: $white-color;

            .fm-filter-view .fm-filters-subview-wrap .fm-buttons-bar .fm-ui-link-btn.fm-muted {
                color: $font-color-dark;
            }
            .fm-filter-view .fm-filters-subview-wrap .fm-filters-subview {
                background-color: $light-black-screen-background;
                color: $white-color;
            }

            div.fm-calculated-view .fm-popup-content .fm-list-header {
                background-color: $black-screen-background;
                color: $white-color;
                border-color: $blackScreenborder;
            }

            div.fm-ui-dropdown .fm-ui-dropdown-btn,
            div.fm-ui-dropdown .fm-ui-dropdown-btn .fm-ui-label {
                background-color: $black-screen-background;
                color: $white-color;
            }

            span.fm-draggable {
                background-color: $black-screen-background;
                color: $white-color;
                border-color: $blackScreenborder;
            }

            .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header {
                background-color: $black-screen-background;
                color: $white-color;
            }

            div.fm-ui-vlist ul.fm-ui-list,
            .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content li,
            div.fm-calculated-view .fm-popup-content .fm-lst-measures li
            {
                background-color: $light-black-screen-background;
                color: $white-color;
            }

            div.fm-ui-modal-overlay {
                background-color: $light-black-screen-background;
            }

            div.fm-ui-window {
                background-color: $black-screen-dark-background;
                color: $white-color;
                .fm-popup-title {
                    color: $white-color;
                }
            }

            .fm-fields-view-wrap button.fm-btn-open-fields,
            .fm-grid-layout .fm-data-sheet {
                background-color: $black-screen-background;
                color: $white-color;
            }

            .fm-filter-view .fm-filters-table .fm-filters-table-header {
                background-color: $light-black-screen-background;
                color: $white-color;
            }

            .fm-filter-view .fm-filters-table .fm-filters-table-content {
                background-color: $black-screen-background;
                color: $white-color;
            }

            .fm-grid-layout .fm-filters,
            .fm-grid-layout div.fm-cell,
            .fm-grid-layout div.fm-cols-sheet div.fm-scroll-pane div.fm-scroll-content .fm-cell,
            .fm-grid-layout div.fm-sheet-header,
            .fm-grid-layout div.fm-sheet-header div.fm-cell,
            .fm-charts-view,
            .fm-charts-view .fm-header-toolbar
            {
                background-color: $light-black-screen-background;
                color: $white-color;
            }

            .fm-grid-layout div.fm-data-sheet div.fm-cell {
                background-color: $black-screen-background;
                color: $white-color;
            }

            .fm-charts-view .fm-chart-legend .fm-chart-legend-container {
                background-color: $black-screen-background;
                color: $white-color;
            }

            .fm-grid-layout .fm-filters.fm-cols-filter .fm-filter-header,
            .fm-grid-layout .fm-filters.fm-rows-filter .fm-filter-header,
            .fm-fields-view.fm-flat-fields .fm-popup-content .fm-list-wrap .fm-list-header .fm-ui-checkbox span.fm-ui-label {
                color: $white-color;
            }

            .fm-chart svg .fm-axis text {
                fill: $white-color;
            }
        }
    }

    .map-attribution {
        background-color: #212121 !important;

        a {
            color: rgba(255, 255, 255, 0.87) !important;
        }
    }

    .tev {
        background-color: #212121 !important;
    }

    .qvi-tooltip-wrapper {
        background-color: #212121 !important;
        color: $white-color;
    }

    .design-storm-btn {
        background-color: $grey-black-screen-background-not-important !important;
    }

    #country-search-box {
        background-color: #212121 !important;
    }

    .iti__dropdown-content {
        background-color: #212121 !important;
    }
}
