.owl-dt-calendar-table .owl-dt-calendar-cell-content {
    font-size: 13px !important;
}
.owl-dt-weekday {
    span {
        display: block;
        visibility: hidden;
        overflow: hidden;
        width: 10px;
        &:first-letter {
            visibility: visible;
        }
    }
}
