@use '@angular/material' as mat;
@import '@angular/material/_theming';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

$primary: (
    50: #e2e9ec,
    100: #b8c9cf,
    200: #88a5af,
    300: #58818f,
    400: #356677,
    500: #114b5f,
    600: #0f4457,
    700: #0c3b4d,
    800: #0a3343,
    900: #052332,
    A100: #6bc6ff,
    A200: #38b3ff,
    A400: #059fff,
    A700: #0090eb,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #ffffff,
        A700: #ffffff,
    ),
);

$secondary: (
    50: #edf2e7,
    100: #d1dfc3,
    200: #b3ca9b,
    300: #95b472,
    400: #7ea454,
    500: #679436,
    600: #5f8c30,
    650: #5cb259,
    700: #548129,
    800: #4a7722,
    900: #396516,
    A100: #c5ff9e,
    A200: #a7ff6b,
    A400: #89ff38,
    A700: #7aff1f,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #ffffff,
        600: #ffffff,
        650: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);
$third: (
    50: #ffffff,
    contrast: (
        50: #059fff,
    ),
);

/* Edit These Variables To Change Colors!! */
$core-primary-palette: mat.define-palette($primary, 500);
$core-accent-palette: mat.define-palette($secondary, 650);
$core-warn-palette: mat.define-palette(mat.$red-palette);

$core-sidenav-primary-palette: mat.define-palette($primary, 500);
$core-sidenav-accent-palette: mat.define-palette($secondary, 650);
$core-sidenav-font-color: map_get($core-sidenav-accent-palette, default);

/* Don't Edit Anything Below This Line!!!! */

// mandatory stuff for theming
$core-main-theme: mat.define-light-theme($core-primary-palette, $core-accent-palette, $core-warn-palette);
$core-sidenav-theme: mat.define-light-theme($core-sidenav-primary-palette, $core-sidenav-accent-palette, $core-warn-palette);

// include the custom theme object into the angular material theme
@include mat.all-legacy-component-themes($core-main-theme);
@include mat.toolbar-theme($core-sidenav-theme);

// set scss variables from color palettes
$primary-color: map_get($core-primary-palette, default);
$secondary-color: map_get($core-accent-palette, default);
$sidenav-primary-color: map_get(map_get($core-sidenav-theme, primary), default);
$sidenav-secondary-color: $core-sidenav-font-color;

$border-radius: 2px;
$enable-shadows: true;

$roboto-font-path: '../node_modules/materialize-css/fonts/roboto/' !default;
$font-color: rgba(0, 0, 0, 0.7);

$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;
$ipad-screen: 1024px;

$medium-and-up: 'only screen and (min-width : #{$small-screen-up})' !default;
$large-and-up: 'only screen and (min-width : #{$medium-screen-up})' !default;
$small-and-down: 'only screen and (max-width : #{$small-screen})' !default;
$medium-and-down: 'only screen and (max-width : #{$medium-screen})' !default;
$medium-only: 'only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})' !default;
$ipad-screen-and-down: 'only screen and (max-width : #{$ipad-screen})';
