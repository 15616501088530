﻿$assetPath: './../images/svgIcon';
$button-color: #2196f3;
$green-color: #5db159;
$border-color: #eeeeee;
$border-color-light: #f2f2f2;
$black-color: black;
$orange-color: #f9a810;

a {
    cursor: pointer;
}

html {
    background-color: #ececec;

    main {
        background-color: rgba(0, 0, 0, 0);
    }
}

// custom svg icon
// .customAnalytic:after  {
//     content: url("./../images/svgIcon/analytics.svg");
//         width: 16px;
//         display: inline-block;
//         vertical-align: middle;
// }

/* overrighting the zindex value to show the customer dropdown on top of loder */

.error {
    color: #f44336;
    padding: 0px;
    font-size: 12px;
    margin: 0;
}

// .summary-details .field-width {
//     width: 30%;
// }
.cdk-overlay-container {
    z-index: 10000 !important;
}

.display-none {
    display: none;
}

.summary-details .field-width {
    width: 30%;
}


/*overwriting default border radius */

mat-card {
    border-radius: 4px !important;
}

.customTranspButton.mat-button {
    min-width: 10px;
    padding: 0px;
    background: transparent;

    &:hover {
        background: transparent;
    }
}

button.icon-raised-button.mat-raised-button {
    min-width: 40px;
    width: 40px;
    height: 36px;
    padding: 0;
    margin: 0;
    margin-left: 10px;
}

button.icon-raised-button.mat-button {
    min-width: 40px;
    width: 40px;
    height: 36px;
    padding: 0;
    margin: 0;
}

.custom-error-snack-bar {
    .mat-simple-snackbar-action {
        color: #f44336;
    }
}

.custom-disabled-View {
    .mat-disabled {
        .mat-slide-toggle-bar {
            background-color: rgba(103, 148, 54, 0.5);
        }

        .mat-slide-toggle-thumb {
            background-color: #679436;
        }
    }
}

.custom-slide-toggle {
    .mat-slide-toggle-label {
        flex-direction: row-reverse;
    }

    .mat-slide-toggle-content {
        margin-right: 8px;
    }
}

.custom-slide-toggle.highlight-text {
    .mat-slide-toggle-content {
        font-weight: 600;
    }
}

.custom-header {
    .header-text {
        display: block;
        font-size: 16px;
        margin-bottom: 5px;
        font-weight: 700;
    }

    .sub-header-text {
        display: block;
        font-size: 14px;
        font-weight: 400;
    }
}

@mixin no-data-found-section($height) {
    height: $height;
    min-height: 200px;
    display: flex;

    .no-data-content,
    .notabledataFound {
        margin: auto;

        .no-data-found-img {
            -webkit-print-color-adjust: exact;
            background-image: url('./../images/no_data.png');
            background-repeat: no-repeat;
            height: 78px;
            margin: auto;
            width: 99px;
        }

        .no-data-found-text {
            padding: 15px;
            text-align: center;
        }
    }
}

.headerContent.withIcon {
    position: relative;
    z-index: 5;

    mat-icon {
        position: absolute;
        z-index: 10;
        top: 0;
        bottom: 0;
        font-size: 20px;
        height: 20px;
        cursor: pointer;
        margin: auto !important;
    }
}

.headerContent.withIcon.right {
    padding-right: 25px !important;

    mat-icon {
        right: 5px;
    }
}

.headerContent.withIcon.left {
    padding-left: 25px !important;

    mat-icon {
        left: 5px;
    }
}

.hydroGraphDetailsContainer {
    .no-data-found-section {
        @include no-data-found-section(inherit);
    }
}

.hydrograph-container {
    .no-data-found-section {
        @include no-data-found-section(inherit);
    }
}

.associated-flow-balance {
    .no-data-found-section {
        @include no-data-found-section(calc(100vh - 380px));
    }
}

#rainFallReportId {
    .no-data-found-section {
        @include no-data-found-section(calc(380px));
    }
}

#DailyRainReportPageId {
    .no-data-found-section {
        @include no-data-found-section(calc(380px));
    }
}

app-advance-scattergraph {
    .no-data-found-section {
        @include no-data-found-section(calc(100% - 41px));
    }
}

ads-prism-custom-dashboard {
    .no-data-found-section {
        @include no-data-found-section(calc(100% - 85px));
    }
}

app-advance-scattergraph-container {
    .no-data-found-section {
        @include no-data-found-section(calc(100% - 41px));
    }
}

app-location-gis-quick-look {
    .no-data-found-section {
        @include no-data-found-section(calc(100% - 41px));
    }
}

.border-radius-5 {
    border-radius: 5px;
}

.mat-box-shadow {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.hidden-overflow {
    overflow: hidden;
}

@mixin action-column-button() {
    button.mat-icon-button {
        width: 30px;
        height: 30px;
        line-height: 30px;
        color: #000;
        opacity: 0.54;

        &:hover {
            opacity: 0.87;
        }

        &.active {
            opacity: 0.87;
        }
    }

    .mat-icon-button[disabled][disabled] {
        color: rgba(0, 0, 0, 0.26);
    }
}

@mixin text-ellipsis($width) {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: $width;
}

.item-flex-center {
    align-self: center;
}

#vaultContainerPage {
    .vault {
        min-height: calc(100vh - 125px);

        .file-tree-container {
            max-height: calc(100vh - 165px);
        }

        .vault-list {
            margin: 0px;
            width: calc(100% - 250px);
            max-height: calc(100vh - 125px);
            margin-right: 0px;
        }
    }
}

.hide-folder-nav {
    .vault-list {
        margin-left: 39px !important;
        width: 100% !important; // overiding the vault list width
    }

    .vault-toggle-sidenav {
        width: 40px;
        left: -2px;
    }
}

.file-tree-container {
    width: 350px;
    overflow: auto;
    margin-top: 40px;
    background-color: #263238;

    .file-tree {
        background-color: #263238;
        width: 100%;
        display: table;
    }

    .rg-right {
        width: 15px !important;
    }
}

ads-prism-vault-tree {
    background-color: #263238;
    max-width: 250px;

    .mat-tree {
        background-color: #263238;

        .mat-tree-node {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            box-sizing: border-box;
            color: #fff;
            min-height: 40px;

            .mat-icon-button[disabled][disabled] {
                color: rgba(255, 255, 255, 0.54);
            }

            .mat-flat-button {
                background-color: #263238;
                color: #fff;
                width: calc(100% - 40px);
                max-width: 565px;
                text-align: left;
                overflow: hidden;
            }

            &.active {
                background-color: #fff;
                color: #263238;

                .mat-icon-button[disabled][disabled] {
                    color: #263238;
                    opacity: 0.54;
                }

                .mat-flat-button {
                    background-color: #fff;
                    color: #263238;
                }
            }
        }
    }
}

mat-toolbar.mat-toolbar {
    background: #ffffff;
}

.mat-option {
    font-size: 14px;
}

.custom-radio-check-full-width {
    padding: 15px 0;

    .mat-checkbox {
        display: block;
        line-height: 2.5;
    }

    .mat-radio-button {
        display: block;
        line-height: 2.5;
    }
}

.mat-select-trigger {
    font-size: 14px;
}

.mat-form-field-disabled {
    color: rgba(0, 0, 0, 0.38);
}

mat-nav-list.side-navigation a {
    border-bottom: 1px solid #25364e;
}

mat-nav-list.side-navigation a.mat-list-item.active {
    background: #496375;

    mat-icon {
        border-left: 7px solid #496375 !important;
    }
}

mat-nav-list.side-navigation .nav-children a>.mat-list-item-content {
    background-color: #1b2d46 !important;
    opacity: 0.5 !important;
}

mat-nav-list.side-navigation .nav-children a:hover>.mat-list-item-content,
mat-nav-list.side-navigation .nav-children a.active>.mat-list-item-content {
    opacity: 1 !important;
}

mat-nav-list.side-navigation .nav-children a:hover>.mat-list-item-content>span.title,
mat-nav-list.side-navigation .nav-children a.active>.mat-list-item-content>span.title {
    color: #fff !important;
}

.mat-simple-snackbar {
    overflow-wrap: break-word;
    word-break: break-word;
}

.word-overflow-breal {
    overflow-wrap: break-word;
    word-break: break-word;
}

.whiteBg {
    background-color: white;
    padding: 0 !important;

    .mat-toolbar-row {
        justify-content: space-around;
    }
}

.cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, 0.6);
}

.cdk-overlay-light-backdrop {
    background: rgba(0, 0, 0, 0.2);
}

.removeDialogBackdrop {
    .cdk-overlay-dark-backdrop {
        display: none;
    }
}

.hint-action {
    height: 100%;
    margin-right: -16px;
    padding-right: 7.5px;
    padding-left: 7.5px;
    background: #ececec;
    overflow: hidden;

    .notification {
        padding-top: 7.5px;
    }
}

.mat-calendar-next-button,
.mat-calendar-previous-button {
    &.mat-icon-button {
        height: unset;
        line-height: unset;
    }
}

button.mat-link.mat-raised-button {
    color: #059fff;
}

button.link.mat-button {
    color: #3991ed;
    text-decoration: underline;
}

button.secondaryButton.mat-button {
    border: 1px solid #3f97f6;
    color: #3f97f6;
}

mat-grid-tile mat-grid-tile-header {
    background: transparent !important;
}


/* Common Elements*/

.setting-name {
    .mat-button {
        padding: 0;
        text-align: left;
        color: #3f97f6;
    }

    .mat-button:hover .mat-button-focus-overlay {
        opacity: 0;
    }
}

.customLongName {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 200px;
    word-break: unset;
    display: block;
}

.mat-error {
    color: #f44336;
    font-size: 12px;
}

.custom-error {
    position: relative;

    .custom-error-message {
        position: absolute;
        color: #f44336;
        font-size: 12px;
        bottom: 0;
        left: 0;
    }
}

.inline-Block {
    display: inline-block;
}

.uppercaseTransform {
    text-transform: uppercase;
}

@mixin capitalized-word() {
    text-transform: lowercase;

    &:first-letter {
        text-transform: uppercase;
    }
}

@mixin custom-matIcon() {
    cursor: pointer;
    opacity: 0.54;
    width: 30px;

    &:hover {
        opacity: 0.87;
    }

    &.active {
        opacity: 0.87;
    }
}

@mixin custom-matCardHeader() {
    mat-card-header {
        text-transform: uppercase;
        margin: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        min-height: 60px;
        font-size: 16px;
        font-weight: bold;

        .backIcon {
            @include custom-matIcon();
        }

        .headerSerch {
            text-transform: capitalize;
            font-weight: normal;
        }
    }
}

@mixin custom-buttom-material() {
    .mat-button {
        margin-right: 8px;
        font-size: 14px;
        min-width: unset;
        padding: 0 10px;
        color: #2196f3;
        text-transform: uppercase;

        &:hover {
            background: #ddd;
        }
    }

    .mat-button[disabled][disabled] {
        color: rgba(0, 0, 0, 0.38);
    }
}

@mixin custom-buttom-material-cap() {
    .mat-button {
        margin-right: 8px;
        font-size: 14px;
        min-width: unset;
        padding: 0 10px;
        color: #2196f3;
        text-transform: capitalize;

        &:hover {
            background: #ddd;
        }
    }

    .mat-button[disabled][disabled] {
        color: rgba(0, 0, 0, 0.38);
    }
}

.custom-flat-button-cap {
    @include custom-buttom-material-cap();
}

.custom-flat-button {
    @include custom-buttom-material();
}

.custom-icon-button {
    .mat-button {
        font-size: 14px;
        min-width: unset;
        padding: 0 10px;
    }

    .mat-button[disabled][disabled] {
        color: rgba(0, 0, 0, 0.38);
    }

    opacity: 0.54;

    &:hover {
        opacity: 0.87;
    }

    &.active {
        opacity: 0.87;
    }
}

@mixin custom-paginator-material() {
    mat-paginator.mat-paginator {
        min-height: 36px;
        border-top: 1px solid rgba(0, 0, 0, 0.12);

        .mat-paginator-container {
            min-height: 36px;
            border-top: 1px solid rgba(0, 0, 0, 0.12);
        }

        .mat-paginator-range-actions {
            min-height: 36px;
        }

        .mat-paginator-range-label {
            margin: 0 8px;
        }

        .mat-paginator-page-size-select {
            margin: 0px 4px 0 4px;
        }

        .mat-icon-button {
            width: 36px;
            height: 36px;
            line-height: 36px;
        }
    }
}

@mixin custom-footer-material() {
    .widgetFooter {
        display: flex;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        height: 60px;
        padding: 0;
        @include custom-paginator-material();
        @include custom-buttom-material();
    }
}

@mixin custom-dialog-material() {
    .mat-checkbox {
        .mat-checkbox-inner-container {
            height: 16px;
            width: 16px;
        }

        .mat-checkbox-layout .mat-checkbox-label {
            font-size: 14px;
        }
    }

    @include custom-paginator-material();

    .popupHeaderWithButton {
        display: flex;
        min-height: 60px;
        background: #263238;
        font-size: 16px;
        font-weight: bold;
        width: 100%;
        color: #ffffff;
    }

    .popupHeader {
        height: unset;
        line-height: unset;

        span {
            padding: 20px 15px;

            &:first-child {
                padding-right: 0px;
            }
        }
    }

    .mat-dialog-title {
        text-transform: uppercase;
        margin: -15px -15px 0px -15px;
        display: flex;
        width: calc(100% + 30px);

        mat-icon {
            margin: -4px 0px 0 0;
        }

        word-break: break-all;
    }

    .mat-dialog-content {
        min-width: 550px;
        min-height: 60px;
        max-height: calc(100vh - 215px);
        margin: 0 -15px;
        padding: 0 15px;
        font-size: 14px;

        .mat-card-title {
            font-size: 16px;
            font-weight: 600;
            color: #a8a8a8;
            padding: 15px 0;
            margin-bottom: 0;
        }

        .mat-card-subtitle {
            font-size: 14px;
            margin: 0 5px;
        }
    }

    .mat-dialog-actions {
        height: 60px;
        padding: 0;
        margin-left: -15px;
        margin-right: -15px;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        @include custom-buttom-material();

        &:last-child {
            margin-bottom: -15px;
        }
    }
}

.vault-delete-action {
    padding: 15px;
    @include custom-dialog-material();

    .mat-dialog-content {
        min-width: 400px;
        padding: 0px 3px;

        ul {
            padding-left: 15px;
            margin: 0;

            li {
                padding-bottom: 5px;
                margin: 0;
            }
        }
    }

    .mat-dialog-actions {
        border-top: none;
        height: 25px;
    }
}

.vault-move-action {
    @include custom-dialog-material();

    .mat-tree {
        border: 1px solid rgba(0, 0, 0, 0.12);
        margin-bottom: 15px;
        overflow: auto;
        max-height: calc(100vh - 300px);

        .mat-tree-node {
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);

            &:last-child {
                border-bottom: 0px solid rgba(0, 0, 0, 0.12);
            }

            .mat-icon-button[disabled][disabled] {
                opacity: 0.54;
            }

            .mat-flat-button {
                width: calc(100% - 40px);
                text-align: left;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            &.active {
                background-color: rgba(0, 0, 0, 0.12);

                .mat-icon-button[disabled][disabled] {
                    opacity: 0.54;
                }

                .mat-flat-button {
                    background-color: transparent;
                }
            }
        }
    }
}

@mixin custom-conformation() {

    .conformationContainer,
    .vault-delete-action {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        pointer-events: auto;
        -webkit-tap-highlight-color: transparent;
        transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
        background: rgba(0, 0, 0, 0.48);

        .conformationContaint {
            width: 350px;
            background: #fff;
            position: absolute;
            top: 40%;
            left: calc((100% - 350px) / 2);
            border-radius: 5px;
            padding: 15px;
            @include custom-dialog-material();

            .popupHeader {
                background: unset;
                color: rgba(0, 0, 0, 0.87);
            }

            .mat-dialog-content {
                min-width: unset;
                width: 350px;
            }

            .mat-dialog-actions {
                border-top: 0px solid rgba(0, 0, 0, 0.12);
            }
        }
    }
}

.dateRangeHeader {
    display: flex;
    height: 60px;
    padding: 0;

    .dateRangeText {
        padding-left: 15px;
        font-family: Roboto, 'Helvetica Neue';
        font-size: 16px;
        font-weight: 500;
    }

    .filter-icon {
        padding-right: 15px;
        cursor: pointer;
    }

    .backIcon {
        @include custom-matIcon();
    }

    .headerText {
        font-family: Roboto, 'Helvetica Neue';
        font-size: 16px;
        font-weight: 500;
    }
}

.customFooter {
    display: flex;
    min-height: 60px;
    padding: 0;
}

.pageHeaderCapitalize {
    text-transform: capitalize;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.7);
}

.customHeaderLayout {
    display: flex;
    min-height: 60px;
    background: #fff;
    margin-bottom: 20px;
    width: 100%;

    .backIcon {
        @include custom-matIcon();
    }

    .headerText {
        font-family: Roboto, 'Helvetica Neue';
        font-size: 16px;
        font-weight: 500;
    }

    .divider {
        min-height: 60px;
        border-left: 1px solid;
        color: #80808069;
    }
}

#assistanceCenterContainer {
    .mat-ink-bar {
        height: 0px;
    }

    .mat-tab-header {
        border-bottom: 0px solid rgba(0, 0, 0, 0.12);
    }

    .mat-tab-label {
        min-width: 100px;
        background: #ddd;
        color: #333;
        opacity: 0.8;
        font-weight: 600;
        padding: 0 7.5px;
        border-right: 1px solid #fff;
    }

    .mat-tab-label-active {
        opacity: 1;
        background-color: #fff;
    }
}

.markerLocationDetails {
    mat-ink-bar {
        background-color: #ff5252 !important;
        min-width: 160px !important;
    }

    .mat-tab-label {
        min-width: 100px !important;
    }

    mat-ink-bar {
        min-width: 100px !important;
    }

    .mat-tab-label-active {
        color: #1976d2 !important;
        opacity: 1 !important;
        background-color: rgba(255, 82, 82, 0.0980392) !important;
    }

    .mat-tab-label {
        text-transform: uppercase !important;
    }

    .mat-ripple-background {
        background-color: rgba(255, 82, 82, 0.0980392) !important;
    }
}

@mixin custom-table-material($background,
    $headColor) {
    .mat-table {
        display: table;
        width: 100%;

        >.mat-header-row,
        >.mat-row {
            display: table-row;
            padding: 0; //border: none;

            >.mat-header-cell,
            >.mat-cell {
                display: table-cell;
                vertical-align: middle;
            }
        }
    }

    .mat-header-row {
        min-height: 44px;
        padding: 0;
        font-size: medium;
        background-color: $background;

        .mat-header-cell {
            font-size: 13px;
            font-weight: 600;
            background-color: $background;
            color: rgba(0, 0, 0, 0.54);
            border-radius: 0;
            padding: 15px 13px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            border-top: 1px solid rgba(0, 0, 0, 0.12);
        }

        .mat-sort-header-arrow {
            margin-top: auto;
            margin-bottom: auto;
        }

        mat-header-cell.text-right {
            .mat-sort-header-container {
                justify-content: flex-end;
            }
        }
    }

    .mat-row {
        min-height: 38px;
        padding: 0;

        &:hover {
            background-color: #eeeeee;
        }

        &:last-child {
            .mat-cell {
                border-bottom: 0px solid rgba(0, 0, 0, 0.12);
            }
        }

        .mat-cell {
            padding: 9px 13px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12); //border-right: 1px solid #e0e0e0;

            mat-icon {
                cursor: pointer;
                font-size: 18px;
            }
        }
    }

    .flatButton {
        color: #2196f3;
        padding: 0 5px;
        min-width: 40px;
    }

    .flatButtonDisabled {
        color: #b8b5b5;
        padding: 0 5px;
        min-width: 40px;
        filter: grayscale(1);
    }

    .img-icon-24 {
        width: 24px;
    }
}

@mixin my-date-picker($width) {
    my-date-picker {
        width: $width;
        margin-right: 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.54);

        .mydp {
            display: block;
            border: 1px solid transparent;

            .selectiongroup {
                background-color: transparent;
            }

            .selection {
                padding: 0px;
                background-color: transparent;
            }

            .selbtngroup {
                height: 25px;

                button {
                    background: transparent;
                }
            }
        }

        input {
            height: 25px;
        }
    }

    my-date-picker:last-child {
        .mydp {
            .selector {
                right: 0;
            }
        }
    }

    .mydp .selectorarrowleft:after,
    .mydp .selectorarrowleft:before {
        right: 24px;
    }
}

@mixin active-Beta-Label($background-color,
    $color,
    $top,
    $right,
    $padding,
    $font-size) {
    .betaLabel {
        position: absolute;
        top: $top;
        right: $right;
        background: $background-color;
        padding: $padding;
        transform: rotate(45deg);
        color: $color;
        font-size: $font-size;
        font-weight: 500;
    }
}

.betaContent {
    line-height: 1.5;

    p {
        color: rgba(0, 0, 0, 0.87);
    }
}

.blockagePDLeaderBoard {
    @include active-Beta-Label(#3f51b5,
        #ffffff,
        6px,
        -22px,
        2px 22px,
        13px);
}

.autoReviewLeaderBoard {
    @include active-Beta-Label(#f99300,
        #ffffff,
        6px,
        -22px,
        2px 22px,
        13px);
}

.blockagePredictionLeaderBoardContent {
    @include active-Beta-Label(#3f51b5,
        #ffffff,
        6px,
        -22px,
        2px 22px,
        13px);

    .activeWidgeHeader.showBetaLabel {
        span:last-child {
            padding-right: 30px;
        }
    }
}

.dataReviewLeaderBoardContent {
    @include active-Beta-Label(#f99300,
        #ffffff,
        6px,
        -22px,
        2px 22px,
        13px);

    .activeWidgeHeader.showBetaLabel {
        span:last-child {
            padding-right: 30px;
        }
    }
}

.blockageLocationMinWidth {
    min-width: 70px;
}

.blockageDateMinWidth {
    min-width: 80px;
}

.blockageDepthTrendFixWidth {
    width: 170px;
}

@mixin active-Widge-Header() {
    .activeWidgeHeader {
        height: 29px;
        background: #fff;
        font-size: 16px;
        font-weight: bold;
        display: table;
        width: 100%;
        padding: 15px 0;
        text-transform: uppercase;

        .actions {
            text-transform: none;

            span.mat-button-wrapper {
                padding-left: 0px;
                font-weight: normal;
                font-size: 12px;
            }
        }
        span {
            display: table-cell;
            vertical-align: middle;
            padding: 0px;
            line-height: 100%;
        }

        span:last-child {
            text-align: right;
            padding-right: 15px;
        }

        mat-icon {
            cursor: pointer;
            opacity: 0.54;

            &:hover {
                opacity: 0.87;
            }

            &.active {
                opacity: 0.87;
            }
        }

        span:first-child {
            padding-left: 15px;
        }
    }
}

#bpHumanReviewId .mat-expansion-panel-body {
    background: #ececec;
}

#bpDailySummaryReportId .mat-expansion-panel-body {
    background: #ececec;
}
#showDailySummaryReportId .mat-expansion-panel-body {
    background: #ececec;
}

#showDailySummaryReportId,
#bpHumanReviewId,
#bpDailySummaryReportId,
.minmaxavgtotaltable {
    mat-card-content {
        padding: 15px;
    }

    .mat-expansion-panel:not([class*='mat-elevation-z']) {
        box-shadow: unset;
    }

    .mat-expansion-panel-header-title {
        font-size: 14px;
        font-weight: bold;
    }

    .mat-expansion-panel-header {
        padding: 0 15px;
    }

    .mat-expansion-panel-body {
        padding: 15px;
    }

    .mat-card-header-text {
        margin: 0;
        margin-left: 20px;
    }

    th {
        border: 0px;
        border-right: 1px solid rgba(0, 0, 0, 0.12);

        &:last-child {
            border-right: 0px solid rgba(0, 0, 0, 0.12);
        }
    }

    .mat-paginator {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
    }

    .location-dashbord-synopsis {
        cursor: pointer;
        opacity: 0.7;
        font-size: 13px;
        position: relative;
        height: 135px;
        border: 0px solid;
        min-width: 250px;
        width: 100%;
        border-radius: 2px;
        overflow: hidden;
        display: block;

        .lB-item-top {
            width: 100%;
            height: 110px;
            background: #fff;
            display: table;

            .lB-item-top-rgt {
                display: table-cell;
                vertical-align: middle;
                padding: 0px;

                .item-col {
                    display: table;
                    width: 100%;

                    .item-content {
                        display: table-cell;
                        width: calc(50%);
                        padding-left: 15px;
                        padding-right: 15px;

                        &:first-child {
                            border-right: 1px solid rgba(0, 0, 0, 0.12);
                        }
                    }
                }

                .item-row {
                    .item-content:last-child {
                        border-top: 1px solid #dddddd;
                        padding-top: 2px;
                        margin-top: 2px;
                    }
                }

                .item-value {
                    font-size: 34px;
                    font-weight: bold;
                }

                .item-text {
                    color: #6a6a6a;
                }
            }
        }

        .lB-item-but {
            width: 100%;
            height: 21px;
            background: #f4f4f4;
            font-size: 13px;
            font-weight: 600;
            display: table;
            text-transform: uppercase;
            border-top: 2px solid #f4f4f4;
            border-bottom: 5px solid #f4f4f4;

            span {
                display: table-cell;
                vertical-align: middle;
                padding-left: 10px;
            }
        }

        &:last-child {
            margin-right: 0px;
        }
    }
}

.two-section-layer {
    display: flex;
    justify-content: space-between;

    .each-layer {
        width: calc((100% - 30px) / 2);
    }
}

#uptimeReportId {
    th {
        // @include capitalized-word();
        border: 0px;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        border-right: 0px solid rgba(0, 0, 0, 0.12);

        &:last-child {
            border-right: 0px solid rgba(0, 0, 0, 0.12);
        }
    }

    td {
        border-right: 0px solid rgba(0, 0, 0, 0.12);
    }
}

#percentFullOverviewId {
    @include active-Widge-Header();

    mat-card {
        padding: 0px;

        mat-card-content {
            padding: 15px;
        }
    }
}

#dailySummaryOverviewId {
    .mat-card-header-text {
        margin: 0;
        margin-left: 20px;
    }

    th {
        border: 0px;
        border-right: 1px solid rgba(0, 0, 0, 0.12);

        &:last-child {
            border-right: 0px solid rgba(0, 0, 0, 0.12);
        }
    }

    .mat-paginator {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
}

#percentFullReportId {
    .mat-card-header-text {
        margin: 0;
        margin-left: 20px;
    }

    .reportHeader {
        div:first-child {
            padding-left: 15px;
            width: unset;
        }
    }

    .percent-full-content {
        min-height: 500px;
    }

    th {
        border: 0px;
        border-right: 1px solid rgba(0, 0, 0, 0.12);

        &:last-child {
            border-right: 0px solid rgba(0, 0, 0, 0.12);
        }
    }

    .mat-paginator {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
}

.fixCellEllipsis {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#dataEditingId {
    margin: 10px 0; // @include custom-dialog-material();
    @include custom-conformation(); // width: 1200px;

    // background: #ffffff;
    // z-index: 99999;
    /* .mat-card-header {
        min-height: 60px;
        background: #263238;
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
    }
    .mat-card-content {
        max-height: 550px;
        overflow: auto
    } */
    #dataEditingTableId {
        .mat-form-field.no-padding>.mat-form-field-wrapper {
            margin-bottom: -1.25em;
        }
    }
}

#dataEditConfirmationDialog {
    @include custom-dialog-material();
    @include custom-conformation();
}

.customDialogLayer {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);

    .customDialog {
        display: table;
        width: 100vw;
        height: 100vh;

        .customDialogPosition {
            width: 600px;
            vertical-align: middle;
            display: table-cell;
            padding-left: calc(50vw - 300px);
            padding-right: calc(50vw - 300px);
        }
    }
}

#resonForEditId {
    background: #fff;
    @include custom-dialog-material();

    #resonForEditId .mat-dialog-content {
        width: 550px;
        max-height: 350px;
    }

    .mat-dialog-title {
        margin: auto;
        width: 100%;
    }

    .mat-dialog-actions:last-child {
        margin-bottom: -15px;
        margin: 0;
        display: flex;
    }

    app-multi-select-auto-complete {
        position: relative;

        .multi-select-list {
            top: -150px;
            position: absolute;
            max-height: 200px;
        }
    }

    .mat-tab-group {
        .mat-tab-header {
            background: #263238;
            text-transform: uppercase;

            .mat-tab-label {
                width: 50%;
                color: #fff;
                font-size: 16px;
                font-weight: bold;
            }

            .mat-tab-label-active {
                opacity: 1;
                background: #263238;
            }

            .mat-ink-bar {
                background-color: #fff;
                height: 3px;
            }
        }
    }

    .eachTabContent {
        margin: 0px;
        padding: 15px;
    }

    .eachEditComment {
        border-top: 1px solid #ddd;
        margin-top: 10px;
        padding-top: 10px;
    }

    .eachEntityEdited {
        &:last-child {
            .listComma {
                display: none;
            }
        }
    }

    .impact-space {
        line-height: 1.5;
        padding-bottom: 10px;
    }
}

[mat-dialog-draggable-title] {
    cursor: move;
}

#customer-user-association,
#usersPageId,
#customersPageId,
.customer-editor,
#apiKeyDashbordId,
#customerDetailLocationsEditorId,
#customerDetailFeatureId,
#dailySummaryOverviewId,
#showDailySummaryReportId,
#bpDailySummaryReportId,
#bpHumanReviewId,
#percentFullReportId,
#uptimeOverviewId,
#uptimeReportId,
#errorPageId,
#dataCommunicationid,
#SlicerDashboardId,
#FlowMonitoringReportId,
#AuditPageId,
#MonitorConfigurationPageId,
#LocationNotificationConfigurationReport,
#DailyRainReportPageId,
#dataEditingId,
#flow-balance-report,
.custom-location-dashboard,
.mul-location-dashboard,
#scheduling-dashboard,
#data-edit-audit-report,
#location-flow-balance-report {
    mat-card {
        z-index: 1;
        padding: 0px;
        @include custom-matCardHeader();

        .user-information-status {
            text-transform: none;
            opacity: 0.54;
            padding-right: 10px;
        }

        mat-card-content {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    @include custom-footer-material();

    mat-card-actions {
        min-height: 60px;
        display: flex;
        margin-left: 0px;
        margin-right: 0px;
        border-top: 1px solid rgba(0, 0, 0, 0.12);

        &:last-child {
            margin-bottom: -7px;
        }
    }

    .fab-button-Style {
        margin-left: 15px;
        margin-bottom: 15px;
    }

    .customDateRangeSelector {
        width: 300px;
        margin-left: 15px;
    }
}

.filterSection {
    // display: -webkit-box;
    // display: -moz-box;
    // display: -ms-flexbox;
    // display: -webkit-flex;
    // display: flex;
    // -webkit-flex-flow: row wrap;
    // flex-flow: row wrap;
    // justify-content: space-between;
    background: #ececec;
    margin: 0 -15px;
    padding-top: 15px;

    .eachSection {
        padding: 0 15px;
        display: inline-block;
        min-width: 200px;
        width: calc((100% - 120px) / 4);
    }

    .report-text {
        padding: 0 15px;
        display: inline-block;
        min-width: 200px;
        width: calc((100% - 90px) / 3);
    }
}

.customer-editor {
    .mat-card-header-text {
        margin: 0;
        margin-left: 10px;
    }

    mat-card-actions {
        &:last-child {
            margin-bottom: unset;
        }
    }
}
.no-padding {
    padding: 0 0;
}
.search-section {
    width: 50%;
    max-width: 420px;

    .search-icon {
        top: 4px;
        position: relative;
        opacity: 0.54;
    }

    .mat-form-field-label {
        text-transform: capitalize;
    }
}

#addApiKey {
    margin-left: 15px;
}

#customerDetailsId {
    .mat-tab-group {
        margin: 0 15px;
    }

    .mat-card-actions {
        @include custom-buttom-material();
    }
}

#customerDetailFeatureId {
    .mat-checkbox {
        .mat-checkbox-inner-container {
            height: 16px;
            width: 16px;
        }

        .mat-checkbox-layout .mat-checkbox-label {
            font-size: 14px;
        }
    }
}

#customersPageId {
    mat-icon {
        cursor: pointer;
    }

    .featuresList {
        div {
            display: inline-block;
            margin-right: 5px;

            &:last-child {
                span {
                    display: none;
                }
            }
        }
    }
}

#customAlarmsPageTable {
    @include custom-table-material(#ffffff,
        #000);
    overflow: auto;

    .fixCellWidth {
        width: 60px;
    }

    .fixCustomerCellWidth {
        width: 350px;
    }

    mat-icon {
        cursor: pointer;
    }

    .rainGaugesList {
        div {
            display: inline-block;
            margin-right: 5px;

            &:last-child {
                span {
                    display: none;
                }
            }
        }
    }
}

#SlicerDashboardId {
    overflow: hidden;

    .mat-card-header {
        overflow: hidden;
    }

    @include active-Beta-Label(#3f51b5,
        #ffffff,
        12px,
        -31px,
        2px 32px,
        14px);
}

#flowMoniterId {
    .mat-card-header {
        overflow: hidden;
        position: relative;
    }

    @include active-Beta-Label(#3f51b5,
        #ffffff,
        12px,
        -31px,
        2px 32px,
        14px);

    app-multi-select-group {
        .multi-select-group-list {
            margin-bottom: 15px;
        }
    }
}

#FlowMonitoringReportId {
    overflow: hidden;

    .mat-card-header {
        overflow: hidden;
    }

    @include active-Beta-Label(#3f51b5,
        #ffffff,
        12px,
        -31px,
        2px 32px,
        14px);
}

#dataCommunicationid,
#schedulingdataId,
#scheduling-dashboard,
.custom-location-dashboard {
    @include custom-matCardHeader();

    .mat-card-header {
        padding: 0 10px;

        .mat-card-header-text {
            margin: 0;
            margin-left: 15px;
        }
    }

    .mat-button {
        color: #2196f3;
        text-transform: uppercase;

        &:hover {
            background: #ddd;
        }
    }

    .mat-table {
        .table-cells {
            text-align: right;
        }
    }
}

#scheduling-dashboard {
    .mat-card-header-text {
        margin: 0 3px;
    }
}

.locationDashbordGraphHeader {
    @include custom-matCardHeader();
}

.custom-location-dashboard {
    .location-details {
        background-color: white;
        text-transform: uppercase;
        margin: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        min-height: 60px;
        font-size: 16px;
        font-weight: 500;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        margin-bottom: 5px;

        .backIcon {
            cursor: pointer;
            opacity: 0.54;
            width: 30px;

            &:hover {
                opacity: 0.87;
            }

            &.active {
                opacity: 0.87;
            }
        }
    }

    .multi-locations {
        margin-top: 7.5px;
        margin-bottom: 20px;
        margin-left: -7.5px;
        margin-right: -7.5px;
    }

    .icon-middle {
        vertical-align: bottom;
    }

    .select-color {
        width: 18px;
        border: none;
        background: none;
    }

    input[type='color' i]::-webkit-color-swatch {
        border: none;
    }

    .graph-width {
        width: calc(50% - 15px);
        display: inline-block;
        margin: 7.5px;
        border-radius: 5px;
        overflow: hidden;
    }

    .graph-full-width {
        width: calc(100% - 15px);
        display: inline-block;
        margin: 7.5px;
        border-radius: 5px;
        overflow: hidden;
    }

    .full-width {
        width: 100%;
    }

    .graph-background {
        background: white;
    }

    .multi-locations-add-new {
        height: calc(100vh - 210px);
        display: flex;
        justify-content: center;
        align-items: center;

        .page {
            background: transparent;
            min-width: 500px;
            border: 0px;
            position: unset;
            display: flex;
            height: 216px;

            .page-content {
                margin: auto 0;

                h3 {
                    font-size: 25px;
                    margin: 0px;
                }

                .lines {
                    margin: 0px;
                }
            }
        }
    }

    #custom-dashboard-graph {
        .dynamicGraph {
            height: 100%;
            width: calc(100% - 2px);
        }
    }
}

.font-14 {
    font-size: 14px;
}

#location-dashboard {
    mat-card {
        border-radius: 5px;
    }

    @include active-Widge-Header();

    .activeWidgeHeader {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .WidgetFilter {
        width: auto;
        padding-top: 10px;
        margin-left: 0px;
        margin-right: 0px;

        mat-checkbox {
            margin-right: 10px;
        }
    }

    .locationInLine {
        .mat-tab-header {
            border-bottom: 0px solid rgba(0, 0, 0, 0.12);
        }

        .mat-ink-bar {
            height: 0px;
        }

        .mat-tab-label {
            line-height: 40px;
            height: 40px;
            min-width: 100px;
            background: #fff;
            margin-right: 10px;
            margin-bottom: 8px;
            color: #333;
            opacity: 0.8;
            font-weight: 600;
            border-radius: 5px;
            border: 1px solid #ddd;
            box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

            &:focus {
                background-color: #5cb259;
            }
        }

        .mat-tab-label-active {
            color: #fff;
            opacity: 1;
            background-color: #5cb259;
            border: 1px solid #fff;
            box-shadow: 7px 7px 7px -2px rgba(0, 0, 0, 0.2), 0 4px 2px 0 rgba(0, 0, 0, 0.14), 0 -2px 5px 0 rgba(0, 0, 0, 0.12) !important;
        }

        .mat-tab-header-pagination {
            height: 40px;
        }
    }

    .hydrographDashboard {
        display: flex;
        @include custom-buttom-material();
    }

    .addNewGraph {
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 9;
    }

    .addNewGraphContainer {
        position: fixed;
        bottom: 56px;
        right: 16px;
        z-index: 9;
        background: #fff;
        width: 300px;
        padding: 15px;
        box-shadow: 0px -1px 10px 9px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 7px 5px 0 rgba(0, 0, 0, 0.12);
    }
}

@mixin custom-tab-widget-material() {
    .mat-tab-header {
        border-bottom: 0px solid rgba(0, 0, 0, 0.12);
    }

    .mat-ink-bar {
        height: 0px;
    }

    .mat-tab-label {
        line-height: normal;
        height: 135px;
        padding: 0;
        opacity: 1;
        text-align: left;
        margin-right: 15px;
        margin-bottom: 8px;
        border-radius: 5px;
        width: calc((100%) / 5); //width: calc((100% - 60px)/5);
        box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 0px 1px 0 rgba(0, 0, 0, 0.12);
    }

    .mat-tab-label:last-child {
        margin-right: 0px;
    }

    .mat-tab-label:first-child {
        display: none;
    }

    .mat-tab-label-active {
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    }

    .mat-tab-label:hover {
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    }

    .pagination-controls {
        height: 135px;
    }

    .mat-tab-label-content {
        width: 100%;
    }
}

#leaderBoardContainer {
    @include custom-tab-widget-material();
}

#location-dashbord-synopsis-leaderBoard {
    @include custom-tab-widget-material();

    .mat-tab-label {
        margin: 1px;
        margin-right: 15px;
        min-width: 215px;
        width: unset;
        box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 0px 1px 0 rgba(0, 0, 0, 0.12);
    }

    .mat-tab-label:last-child {
        margin-right: 2px;
    }

    .mat-tab-label.mat-tab-disabled {
        color: rgba(0, 0, 0, 0.87);
    }

    .location-dashbord-synopsis .lB-item-but {
        width: 101%;
        height: 23px;
    }

    .location-dashbord-synopsis {
        font-size: 13px;
        min-width: 190px;
        cursor: default;
    }

    .location-dashbord-synopsis .lB-item-top .lB-item-top-rgt .item-value {
        font-size: 16px;
    }

    .mat-tab-label-active {
        box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 0px 1px 0 rgba(0, 0, 0, 0.12);
    }

    .mat-tab-label:hover {
        box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 0px 1px 0 rgba(0, 0, 0, 0.12);
    }

    .synopsis_0 {
        .lB-item-but {
            color: #fff;
            background: #5cb259;
            border-top: 2px solid #5cb259;
            border-bottom: 3px solid #5cb259;
        }
    }

    .synopsis_1 {
        .lB-item-but {
            color: #fff;
            background: #2196f3;
            border-top: 2px solid #2196f3;
            border-bottom: 3px solid #2196f3;
        }
    }

    .synopsis_2 {
        .lB-item-but {
            color: #fff;
            background: #3f51b5;
            border-top: 2px solid #3f51b5;
            border-bottom: 3px solid #3f51b5;
        }
    }

    .synopsis_3 {
        .lB-item-but {
            color: #fff;
            background: #f99300;
            border-top: 2px solid #f99300;
            border-bottom: 3px solid #f99300;
        }
    }

    .synopsis_4 {
        .lB-item-but {
            color: #fff;
            background: #ec407a;
            border-top: 2px solid #ec407a;
            border-bottom: 3px solid #ec407a;
        }
    }

    .synopsis_5 {
        .lB-item-but {
            color: #fff;
            background: #009688;
            border-top: 2px solid #009688;
            border-bottom: 3px solid #009688;
        }
    }
}

[aria-label='tonality'] {
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    transform: rotate(90deg);
    width: 18px !important;
    margin-right: 6px;
}

.mat-select-panel {
    margin-top: 10px;
}

.cdk-overlay-container {
    .mat-dialog-container {
        max-height: calc(100vh - 5px);
        padding: 15px;
        border-radius: 5px; //background: transparent !important;

        .mat-card-actions {
            margin: 0px;
        }

        mat-card {
            margin: 0px;
        }

        .pagination {
            margin: 5px 0px !important;
        }
    }
}

material-datepicker {
    input {
        border: 1px solid rgb(255, 255, 255) !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
    }
}

.widge-custom-date-range {
    margin-right: 20px;
    position: relative;
    min-height: 50px; //top:3px;

    label {
        position: absolute;
        padding: 0 2px;
        transform-origin: left top;
        bottom: 26px;
        left: 0px;
        text-align: left;
        transform: scale(0.75);
        font-size: 16px;
        color: rgba(0, 0, 0, 0.38);
    }

    .fromError {
        position: absolute;
        left: 2px;
        top: 80px;
    }

    .toError {
        position: absolute;
        left: 208px;
        top: 80px;
    }
}

.view-data-date-range {
    my-date-picker {
        width: 200px;
        margin-right: 15px;

        .mydp {
            display: block !important;
            border-bottom: 1px solid #cfcfcf !important;
            border: 1px solid #ececec;

            .selection {
                padding: 0px;
                background-color: #ececec !important;
            }

            .selbtngroup {
                height: 25px !important;

                button {
                    background: #ececec !important;
                }
            }
        }

        input {
            height: 25px !important;
        }
    }
}

@media (max-width: 930px) {

    .auto-scrub-date-range,
    .custom-date-range {
        margin-right: auto !important;

        material-datepicker {
            input {
                width: 120px;
            }
        }
    }
}

.success {
    color: #43a047;
}

.warning {
    color: #ff9800;
}

.danger {
    color: #f44336;
}

.add-graph {
    width: 600px;
    @include custom-dialog-material();

    .entity-container {
        border: 1px solid #ddd;

        .composite-list {
            display: flex;
            justify-content: space-between;

            .each-item {
                width: calc((100% - 140px) / 4);
                padding: 0 15px;
            }

            .each-item:nth-child(4) {
                padding-top: 10px;
            }

            .mat-button {
                min-width: 40px;
            }
        }
    }

    .composite-list:first-child {
        padding-top: 10px;
    }

    .select-color {
        width: 18px;
        border: none;
        background: none;
    }

    input[type='color' i]::-webkit-color-swatch {
        border: none;
    }

    .select-graph {
        font-size: 14px;
        padding-left: 0;
        padding-right: 5px;
    }
}

.daysContainer {
    .mat-checkbox-inner-container {
        display: none !important;
    }
}

.menuNotification {
    button.mat-menu-item {
        border-bottom: 1px solid #ddd;
        font-size: 14px;
        padding: 0px 15px;

        .mat-icon {
            font-size: 14px;
            height: unset;
            line-height: unset;
            margin: 0;
        }
    }

    button.mat-menu-item:last-child {
        border-bottom: 0px solid #ddd;
    }
}

.days-container {
    .mat-checkbox-inner-container {
        display: none !important;
    }

    display: table;
    border-collapse: separate;
    border-spacing: 5px;

    .mat-checkbox-label {
        line-height: 14px;
    }

    .each-day {
        cursor: pointer;
        width: 14px;
        vertical-align: middle;
        text-align: center;
        border: 1px solid #ddd;
        padding: 8px;
        border-radius: 24px;
        font-size: 12px;
        font-weight: 700;
        display: table-cell;
        opacity: 0.54;

        &:hover {
            opacity: 0.87;
        }
    }

    .mat-checkbox-checked {
        .each-day {
            background: #5cb259;
            color: #fff;
            opacity: 0.87;
            border: 1px solid #5cb259;
        }
    }

    .active-day {
        background: #5cb259;
        color: #fff;
        border: 1px solid #5cb259;
    }
}

.widgetSection {
    overflow: hidden;
    width: 100%;
    height: 100%;
    min-width: 600px;
    margin: 0;
    padding: 0px;

    .widget-container {
        flex: 1 1 auto;
        height: inherit;
        overflow: hidden;
    }

    .mat-card-content {
        height: inherit !important;
        // overflow: auto;
        // overflow-y: scroll;
        // overflow-x: hidden;
        margin: 0px;
        min-width: auto;
    }

    @include active-Widge-Header();
    @include custom-footer-material();
}

.batteryLeaderBoardContent,
.dataReviewLeaderBoardContent,
.blockagePredictionLeaderBoardContent {
    width: 480px;
}

.scrollOverlay1 {
    position: absolute;
    height: 574px;
    width: 18px;
    background: white;
    right: 0;
}

.textTypeButton {
    @include custom-buttom-material();
}

.popupHeader {
    height: 50px;
    background: #263238;
    font-size: 16px;
    font-weight: bold;
    display: table;
    width: 100%;
    color: #ffffff;

    span {
        display: table-cell;
        vertical-align: middle;
        padding: 15px;

        &:first-child {
            padding-right: 0px;
        }
    }

    span:last-child {
        text-align: right;
    }

    mat-icon {
        cursor: pointer;
    }
}

.locationDetailsContainer {
    max-height: 400px;
    overflow-y: auto;
    padding: 15px;
    background: #ececec;
}

#addNewLocation {
    position: absolute;
    bottom: 20px;
    left: 10px;
}

.add-new-location-top-value {
    bottom: 10px !important; // overriding existing
}

#locationDetailsId {
    padding: 15px;
    @include custom-dialog-material();

    .popupHeader span:last-child {
        padding-left: 0px;
    }

    .overlay {
        // preloader styles
        height: 85%;
        top: 60px;
    }

    .dialog-content {
        overflow: auto;
        min-width: auto;
        max-height: 350px;

        mat-radio-group.mat-radio-group[ng-reflect-disabled='true'] {

            // handlds isActive styling
            span {
                color: rgba(0, 0, 0, 0.48);
            }
        }
    }

    .mat-dialog-title {
        display: table;
    }

    .mat-dialog-content {
        min-width: unset;
        max-height: 350px;
        overflow: auto;
    }

    .mat-dialog-actions {
        padding: 12px 0;
        height: unset;

        .mat-button {
            color: unset;
        }

        .textTypeButton .mat-button {
            color: #2196f3;
        }
    }

    button.mat-fab.secondaryMatButton {
        background-color: transparent;
        color: #333;
        border: 1px solid #333;
        width: 46px;
        height: 46px;

        .mat-button-wrapper {
            padding: 10px 0;
            line-height: 0;
        }
    }

    .popupHeader {
        height: auto;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
    }

    .edit-controls {
        display: flex;
        align-items: end;

        .pre-edit-controls {
            button {
                min-width: 50px;
                padding: 0;
            }
        }
    }

    .active-location-selection {
        mat-radio-button {
            &.mat-radio-button {
                label {
                    color: #aaa;

                    div.mat-radio-container {
                        color: inherit;

                        div.mat-radio-outer-circle,
                        div.mat-radio-inner-circle,
                        div.mat-radio-ripple.mat-ripple {
                            color: inherit;
                        }
                    }
                }
            }

            &.mat-radio-checked {
                label {
                    color: #000;

                    div.mat-radio-container {
                        color: inherit;
                    }
                }
            }
        }
    }
}

.carosalHeader {
    cursor: pointer;
    margin-top: 10px;
    height: 40px;
    background: #fff;
    font-size: 14px;
    font-weight: bold;
    display: table;
    width: 100%;
    color: #333;

    span {
        display: table-cell;
        vertical-align: middle;
        padding: 9px 15px;
    }

    span:last-child {
        text-align: right;
    }

    mat-icon {
        cursor: pointer;
    }
}

.carosalHeader:first-child {
    margin-top: 0px;
}

.activecarosalHeader {
    background: #5cb258;
    color: #fff;
}

.popupContainer {
    .mat-card-content {
        margin: 0;
    }

    overflow: auto;
    max-height: calc(100vh - 90px);
    min-width: 600px;
    background: #fff;

    .custom-table-inside {
        min-width: 290px;

        th {
            background-color: #fff;
            color: #a8a8a8;
            font-size: 16px;
            font-weight: 500;
        }

        mat-icon {
            font-size: 16px;
            margin-right: 5px;
            height: auto;
            cursor: pointer;
        }

        .mat-checkbox-ripple {
            position: absolute;
            left: -5px;
            top: -5px;
            right: -5px;
            bottom: -5px;
            border-radius: 8%;
            z-index: 1;
        }
    }

    .mat-card-title {
        font-size: 16px;
        font-weight: 500;
        color: #a8a8a8;
        padding: 15px 0;
        margin-bottom: 0;
    }

    .mat-card-subtitle {
        font-size: 14px;
        margin: 0 5px;
    }

    mat-card {
        padding: 0px;
        padding-bottom: 8px;
    }
}

.locationEditoractionItem {
    padding-top: 6px;
    text-align: right;
}

.custom-table {
    display: table;
    border-collapse: collapse;
    margin-bottom: 5px;

    th {
        font-size: 13px;
        border-radius: 0;
        padding: 15px 13px; //border-bottom: 2px solid #114B5F !important;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.54);
        font-size: medium;
        background-color: #ffffff;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

    thead {
        td {
            padding: 0px;
        }
    }

    tbody {
        tr:hover {
            background-color: #eeeeee;
        }
    }

    td {
        padding: 9px 13px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

    .mat-checkbox-layout .mat-checkbox-label {
        line-height: 20px;
    }
}

.activeLeaderBoardContent {
    .custom-table {
        min-width: 440px;
    }
}

mat-card-actions.submitButn.mat-card-actions {
    text-align: right;
    margin-right: 5px;
    margin: 0;

    mat-icon {
        margin-right: 0px;
    }
}

mat-card-actions.submitCenter.mat-card-actions {
    text-align: center;
    margin-right: 5px;
    margin: 0;

    mat-icon {
        margin-right: 0px;
    }
}

button.mat-fab.mat-accent {
    margin-right: 5px;
}

.text-right {
    text-align: right;
}

.width-100 {
    width: 100px;
}

.height-50 {
    height: 50px;
}

.boxLabel {
    border: 1px solid;
    padding: 2px 15px;
    font-weight: bold;
    background: #bfbab2;
    color: #fff;
    border-radius: 5px;
    font-size: 12px;
    width: 40px;
    display: block;
    text-align: center;
    float: right;
}

.green-bg {
    background: #5cb258 !important;
}

.yellow-bg {
    background: #fdd835;
}

.orange-bg {
    background: #f99300 !important;
}

.red-bg {
    background: #f44336 !important;
}

.yellow-c {
    color: #fdd835 !important;
}

.green-c {
    color: #5cb258 !important;
}

.orange-c {
    color: #f99300 !important;
}

.red-c {
    color: #f44336 !important;
}

.gray-c {
    color: #bfbab2 !important;
}

.top {
    padding-top: 10px;
}

.good {
    color: green;
}

.bad {
    color: red;
}

.lowerCaseText {
    text-transform: lowercase;
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}

#topHeaderSection {
    .top-header-each-item {
        border-left: 1px solid rgba(0, 0, 0, 0.12);
        height: 64px;
        padding: 0 15px;
        min-width: 0;
        line-height: 0;
        border-radius: 0px;
    }

    .hint-action {
        margin-right: -16px;
        width: auto;
    }
}

.disabled-link {
    cursor: not-allowed !important;
    pointer-events: none;
}

button#activeInactiveLocation {
    padding: 0;
    min-width: 0;
    line-height: 0;
    width: unset;
    height: unset;
}

#locationGroupSelect {
    position: relative;
    font-size: 14px; //top: -8px;

    .mat-input-wrapper.mat-form-field-wrapper {
        padding-bottom: 0;
    }

    .mat-select-placeholder.mat-floating-placeholder {
        top: -12px !important;
    }

    .mat-button-focus-overlay {
        background-color: transparent;
    }

    .mat-button {
        padding: 0;
        min-width: 0;
        line-height: 0;
        height: 64px;
        border-radius: 0px;
    }

    /*.addLocationGroupIcon {
        background: #5cb259;
        border-radius: 2px;
        color: #fff;
        line-height: 30px;
        width: 36PX;
        .mat-button-ripple-round {
            border-radius: 0;
        }
        mat-icon {
            font-size: 30px;
            height: 30px;
            width: 30px;
            line-height: 30px;
        }
        .personAdd {
            font-size: 23px;
        }
    }
    */
}

mat-card-actions.floatButn.mat-card-actions {
    text-align: right;
    margin: 0 5px;
    padding-bottom: 0;
}

.floatButn {
    text-align: right;
    margin: 0 5px;
}

.custom-link[href='/autoreviewsummary'] {
    background: #ffffff !important;
}


/*specific componet Use*/

.alarmLeaderBoardContent {
    .custom-table {
        min-width: 440px;

        th {
            background-color: #5cb259;
        }
    }
}

.batteryLeaderBoardContent {
    .custom-table {
        min-width: 440px;

        th {
            background-color: #2196f3;
        }
    }

    .highcharts-container {
        margin: auto;
    }
}

#widget-battery-status-id {
    width: 100%;
}

.batteryLeaderBoardContent.full-width-leaderboard {
    #widgetFiltersForm {
        #widgetDateId {
            display: none;
        }

        #widgetLocationId {
            width: calc(50% - 20px);
        }

        #widget-battery-status-id {
            width: 50%;
        }
    }
}

.blockagePredictionLeaderBoardContent {
    .custom-table {
        min-width: 440px;

        th {
            background-color: #3f51b5;
        }
    }
}

.dataReviewLeaderBoardContent {
    .custom-table {
        min-width: 440px;

        th {
            background-color: #f99300;
        }
    }
}

.collectionLeaderBoardContent {
    .custom-table {
        min-width: 440px;

        th {
            background-color: #ec407a;
        }
    }
}

.auto-review-error-threshold {

    .mat-checkbox,
    .mat-radio-button {
        padding-right: 15px !important;
        padding-left: 0;
    }
}

@mixin full-width-height() {
    height: 100%;
    width: 98%;
}

#highChartIdAuto {
    @include full-width-height();
}

#scatterChartIdAuto {
    @include full-width-height();
}

#hydrograph-chart {
    @include full-width-height();
}

#dailySummaryChartContainer {
    @include full-width-height();
    max-height: 300px;
}

#uptimeChartContainer {
    @include full-width-height();
    max-width: 400px;
    max-height: 300px;
}

#percentFullChartContainer {
    @include full-width-height();
    max-width: 500px;
    max-height: 300px;

    .customTextStyle {
        font-size: 24px;
        color: black;
    }
}

.graphContent {
    .mat-card {
        padding-left: 5px;
        padding-right: 5px;
    }

    .activeWidgeHeader {
        padding-left: 20px;
        width: auto;
    }
}

.tbl-display-cust {
    display: table;
    width: 100%;
}

.tbl-display-child-cust {
    display: table-cell;
    padding: 0 6px;
    width: 16%;
    text-align: center;
}

.flow-balance-report-content {
    width: 28% !important;
    font-size: 20px;
    padding-bottom: 15px;
}

#mapLocationAutoInput {
    position: absolute;
    width: 240px;
    top: 10px;
    right: 10px;
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    height: 32px;

    .mat-input-flex {
        .mat-input-infix {
            border: 0;
            line-height: 20px;
        }
    }

    .mat-form-field-underline {
        background-color: transparent;

        .mat-form-field-ripple {
            background-color: transparent;
        }
    }

    .mat-form-field-infix {
        border-top: none;
    }

    .mat-form-field-prefix {
        padding-left: 5px;
    }

    .locationSearch {
        position: relative;
        top: 5px;
    }

    .clickable {
        position: relative;
        top: 5px;
    }

    .customLongName {
        width: 180px;
    }
}

.undo-redo-button {
    background-color: rgb(38, 50, 56);
    opacity: 0.54;
    border-radius: 50% !important;
    margin: 0.5rem !important;
    margin-left: 0;
    height: 2rem;
    min-width: 2rem !important;
    padding: 0 !important;
    display: flex !important;
    justify-content: center;

    mat-icon {
        color: white;
        font-size: 1.125rem;
    }

    &.active,
    &:hover {
        opacity: 1;
    }
}

/*common use */

.lightBackGround {
    opacity: 0.54;

    &:hover {
        opacity: 0.87;
    }

    &.active {
        opacity: 0.87;
    }
}

button.lightBackGround.mat-button.active {
    color: rgba(0, 0, 0, 0.87);
}

.activeBackGround {
    opacity: 0.54;

    &:hover {
        opacity: 0.87;
    }

    &.active {
        opacity: 0.87;
        color: #5cb259;
    }
}

button.activeBackGround.mat-button.active {
    color: #5cb259;

    .mat-icon {
        color: #5cb259;
    }
}

.cusomddItem {
    margin-top: 20px;
}

.flex-container-space-between {
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.flex-item-r {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.display-t-r {
    display: table-row;
}

.hide {
    display: none !important;
}

.show {
    display: block !important;
}

.app-flex-filler {
    flex: 1 1 auto;
}

.flex-item-al-e-ju-s {
    align-items: flex-end;
    justify-content: flex-start;
}

.flex-item-al-s-ju-s {
    align-content: flex-start;
    align-items: flex-start;
}

.flex-item-al-e-ju-e {
    align-items: flex-end;
    justify-content: flex-end;
}

.flex-item-al-e-ju-c {
    align-items: center;
}

.flex-item-jc-s {
    justify-content: flex-start;
}

.flex-item-jc-sb {
    justify-content: space-between;
}

.flex-1 {
    flex: 1;
}

.left-15 {
    left: 15px !important; //overridding the existing left position based on condition
}

.margin-t-10 {
    margin-top: 10px !important;
}

.margin-t-15 {
    margin-top: 15px !important;
}

.margin-t-5 {
    margin-top: 5px;
}

.margin-t-0 {
    margin-top: 0 !important;
}

.margin-b-15 {
    margin-bottom: 15px !important;
}

.margin-b-30 {
    margin-bottom: 30px !important;
}

.margin-l-auto {
    margin-left: auto !important;
}

.margin-l-10 {
    margin-left: 10px !important;
}

.margin-l-15 {
    margin-left: 15px;
}

.margin-15 {
    margin: 15px;
}

.margin-r-15 {
    margin-right: 15px;
}

.margin-b-n5 {
    margin-bottom: -5px;
}

.margin-l-10 {
    margin-left: 10px;
}

.margin-r-10 {
    margin-right: 10px;
}

.margin-r-45 {
    margin-right: 45px;
}

.margin-r-12 {
    margin-right: 12px;
}

.margin-r-20 {
    margin-right: 20px !important;
}

.margin-l-20 {
    margin-left: 20px;
}

.margin-t-b-15 {
    margin: 15px 0;
}

.width-100-px {
    width: 100px;
}

.text-align-center {
    text-align: center;
}

.bottom-border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.top-border {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.pad-b-0 {
    padding-bottom: 0px;
}

.pad-r-15 {
    padding-right: 15px;
}

.pad-r-10 {
    padding-right: 10px;
}

.pad-r-20 {
    padding-right: 20px;
}

.pad-l-25 {
    padding-left: 25px;
}

.pad-l-100 {
    padding-left: 100px;
}

.pad-r-25 {
    padding-right: 25px;
}

.pad-b-15 {
    padding-bottom: 15px;
}

.pad-l-15 {
    padding-left: 15px;
}

.pad-l-10 {
    padding-left: 10px;
}

.pad-l-0 {
    padding-left: 0px !important;
}

.pad-15 {
    padding: 15px;
}

.margin-20 {
    margin: 20px;
}

.pad-b-20 {
    padding-bottom: 20px;
}

.pad-b-25 {
    padding-bottom: 25px;
}

.pad-b-40 {
    padding-bottom: 40px;
}

.pad-t-12 {
    padding-top: 12px;
}

.pad-t-6 {
    padding-top: 6px;
}

.pad-t-5 {
    padding-top: 5px;
}

.pad-t-15 {
    padding-top: 15px;
}

.pad-t-20 {
    padding-top: 20px;
}

.pad-t-30 {
    padding-top: 30px;
}

.pad-l-5 {
    padding-left: 5px;
}

.pad-b-10 {
    padding-bottom: 10px;
}

.pad-t-10 {
    padding-top: 10px !important;
}

.btn-color {
    color: black !important;
}

.text-position {
    position: relative;
}

.custom-btn-color {
    color: #2196f3;

    .mat-slide-toggle-label {
        margin: 0px 7px;
    }
}

.d-flex {
    display: flex;
}

.border-lt {
    border-left: 1px solid #ddd;
}

.border-rt {
    border-right: 1px solid #ddd;
}

.icon-align {
    vertical-align: bottom;
    padding: 0 5px;
    cursor: pointer;
}

//overwriting as content need to present in the element
.graphAreahidden {
    visibility: hidden;

    .activeWidgeHeader {
        height: 0 !important;
        padding: 0 !important;
    }

    .highcharts-container {
        height: 0 !important;
        padding: 0 !important;
    }
}

.full-width {
    width: 100% !important;
}

.full-height {
    height: 100%;
}

.width-0-px {
    width: 0%;
}

.width-80-percent {
    width: 80%;
}

.width-285-px {
    width: 285px;
}

.full-width .mat-form-field {
    width: 100%;
}

.width-50-p {
    width: 50%;
}

.width-45-p {
    width: 45%;
    display: inline-block;
    margin-right: 4%;
}

.widgetInputWidth {
    width: calc(50% - 10px);
}

.widgetInputWidth .mat-form-field {
    width: 100%;
}

.widgetInputWidthExpanded {
    width: calc(25% - 20px);
}

.widgetInputWidthExpanded .mat-form-field {
    width: 100%;
}

.width-200-px {
    width: 200px;
}

.width-128-px {
    width: 128px;
}

// .width-260-px{
//     width:260px;
// }
.disable-field {
    pointer-events: none;
    opacity: 0.6;
}

.width-500-px {
    width: 500px;
}

.width-500-px .mat-form-field {
    width: 500px;
}

.width25percent {
    width: calc(25% - 20px);
}

.width-24px {
    width: 24px;
}

.width-30-p {
    width: 30%;
}

.width-40-p {
    width: 40%;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.bold-font {
    font-weight: bold;
}

.inline {
    display: inline;
}

.topPadding {
    padding-top: 10px;
}

.no-margin {
    margin: 0;
}

.page-marketing-screen {
    padding: 40px 0 10px;
    text-align: center;
    width: 100%;
    display: block;
    margin: auto;

    .contact-button {
        color: white;
        background-color: #5cb259;
        border-radius: 5px;
        min-width: 150px;
        font-size: 16px;
        font-weight: 400;
    }
}

.content-color {
    color: #212121;
    padding-bottom: 15px;
}

.mat-fabIcon-48 {
    height: 48px !important;
    width: 48px !important;

    .material-icons {
        line-height: 16px !important;
        height: 16px;
        width: auto;
        font-size: 30px;
    }
}

.mat-fabIcon-40 {
    height: 40px !important;
    width: 40px !important;
    margin: 0px !important;

    .material-icons {
        line-height: 8px !important;
        height: 16px;
        width: auto; //font-size: 30px;
    }
}

.full-width-leaderboard {
    width: 96% !important;
    margin-left: 2% !important;

    .fullScreenShow {
        display: block;
    }

    .fullScreenHide {
        display: none;
    }

    #widgetFilters,
    #autoReviewWidgetFilter,
    #collectionWidgetFilter {
        width: auto;
    }
}

#widgetDateId {
    min-width: 480px;

    .noFlexWrap {
        width: 100%;

        .eachDateSection {
            width: 50%;

            .mat-form-field {
                width: 100%;

                .noFlexWrap {
                    width: 100%;

                    .eachDateSection {
                        width: 50%;

                        .mat-form-field {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.auto-width-leaderboard {
    #alarmWidgetId {
        #widgetStatusId {
            width: calc(50% - 10px);
        }

        #widgetTypeId {
            width: calc(50% - 10px);
        }
    }

    #widgetFilters {
        #anomaliesReasons {
            width: calc(50% - 10px);
        }

        #widgetDateId {
            min-width: 480px; // padding-top:15px;
            width: 100%;

            .noFlexWrap {
                width: 100%;

                .eachDateSection {
                    width: 50%;

                    .mat-form-field {
                        width: 100%;

                        .noFlexWrap {
                            width: 100%;

                            .eachDateSection {
                                width: 50%;

                                .mat-form-field {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#alarmWidgetId {
    .multi-selecet-auto-complete mat-chip-list .mat-chip-list-wrapper mat-basic-chip {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: nowrap;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: auto;
        padding: 5px 5px 0px 7px;
        background-color: #fff;
        margin: 0 5px 5px 0;
        border: 1px solid #ddd;
        border-radius: 5px;
    }

    .multi-selecet-auto-complete {
        height: 80px;
    }
}

.collectionLeaderBoardContent.full-width-leaderboard {
    #widgetLocationId {
        padding-top: 5px;
    }
}

.full-width-leaderboard {
    #alarmWidgetId {
        #widgetStatusId {
            width: calc(25% - 20px);
            padding-top: 5px;
        }

        #widgetTypeId {
            width: calc(25% - 20px);
            padding-top: 5px;
        }
    }

    #widgetFilters {
        #widgetDateId {
            width: 50%;
            min-width: 50%;

            .noFlexWrap {
                width: 100%;

                .eachDateSection {
                    margin-top: 1px;
                    width: 50%;

                    .mat-form-field {
                        width: 100%;

                        .mat-form-field-infix {
                            padding: 0.575em 0;
                        }
                    }
                }
            }
        }
    }

    &.collectionLeaderBoardContent {
        .widgetInputWidthExpanded {
            padding-top: 5px;
            margin-left: 20px;
        }
    }
}

.auto-width-leaderboard {
    width: auto;
    margin-left: 15px;

    .fullScreenShow {
        display: none;
    }

    .fullScreenHide {
        display: block;
    }
}

#collectionWidgetFilter {
    .cusomddItem {
        width: 420px;
    }
}

#percentFullReportId {
    .widgetInputWidthExpanded {
        margin-left: 20px;
    }
}

#uptimeOverviewId {
    .mat-card-header-text {
        margin: 0;
        margin-left: 27px;
    }

    .mat-paginator {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
}

#location-flow-balance-report {
    .mat-card-header-text {
        margin: 0;
        margin-left: 17px;
    }

    .mat-paginator {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
}


#FlowMonitoringDetails {
    .mat-card-header-text {
        margin: 0;
    }
}

#MonitorConfigurationPageId,
#LocationNotificationConfigurationReport,
#DailyRainReportPageId,
#AuditPageId,
#usersPageId {
    .mat-card-header-text {
        margin: 0;
        margin-left: 22px;
    }
}

#customer-user-association,
#customersPageId {
    .mat-card-header-text {
        margin: 0;
        margin-left: 10px;
    }
}

#customersPageId {
    .mat-card-header-text {
        margin: 0;
        margin-left: 17px;
    }
}

.WidgetFilter {
    justify-content: flex-start;
    position: relative;
    width: 550px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
    background: #f8f8f8;
    color: rgba(0, 0, 0, 0.38);

    .closeFilter {
        position: absolute;
        right: 15px;
        top: 2px;
        color: rgba(0, 0, 0, 0.38);
        cursor: pointer;
    }
}

.notabledataFound {
    padding: 15px 0;
    text-align: center;
    width: 100%;
    display: block;
    margin: auto;
    line-height: 100px;

    &.lineHeightNormal {
        line-height: normal;
    }
}

.page-marketing-screen {
    padding: 15px 0;
    text-align: center;
    width: 100%;
    display: block;
    margin: auto;
}

.widget-label {
    font-size: 10.5px;
    font-weight: 500;
    font-family: Roboto, 'Helvetica Neue';
    color: rgba(0, 0, 0, 0.36);
}

#scheduleCollectionEditor,
#locationGroupsEditor {
    .multiSelectSearch {
        .open>.dropdown-menu {
            top: auto;
            bottom: 24px;
        }

        .ui-select-choices {
            max-height: 130px;
        }

        .btn-xs {
            background-color: #eee;
        }
    }
}

.multiSelectSearch {
    width: 420px;

    .open>.dropdown-menu {
        display: block;
    }

    .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        display: none;
        float: left;
        min-width: 160px;
        padding: 5px 0;
        margin: 2px 0 0;
        font-size: 14px;
        text-align: left;
        list-style: none;
        background-color: #fff;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        border: 1px solid #ccc;
        border: 1px solid rgba(0, 0, 0, 0.15);
        -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    }

    .dropdown,
    .dropup {
        position: relative;
    }

    .ui-select-container:focus {
        outline: none;
    }

    .ui-select-match:focus {
        outline: none;
    }

    .form-control {
        display: block;
        height: 34px;
        width: 100%;
        font-size: 14px;
        line-height: 1.42857143;
        color: rgba(0, 0, 0, 0.54);
        background-color: transparent;
        border: 0px solid #f8f8f8;
        background-image: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.54);
        -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
        -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    }

    .form-control:focus {
        outline: none;
    }

    .btn>span.ui-select-match-text {
        padding-top: 10px;
    }

    .ui-select-choices.dropdown-menu {
        .ui-select-choices-row.active {
            a {
                color: #333;
                text-decoration: none;
                outline: 0;
                background-color: rgba(0, 0, 0, 0.04);
            }
        }
    }

    .btn-xs {
        padding: 1px 5px;
        font-size: 12px;
        line-height: 1.5;
        border-radius: 3px;
        color: rgba(0, 0, 0, 0.54);
        background-color: #fff;
        border-color: #ccc;
        display: inline-block;
        padding: 3px 5px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.42857143;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-image: none;
        border: 1px solid transparent;
        border-radius: 5px;

        .close {
            float: right;
            font-size: 21px;
            font-weight: 700;
            line-height: 1;
            color: #000;
            text-shadow: 0 1px 0 #fff;
            filter: alpha(opacity=20);
            opacity: 0.2;
        }
    }
}

@mixin smiley-faces-material() {
    .mat-radio-container {
        display: none;
    }

    .mat-radio-label-content {
        width: 40px;
        padding-left: 0px;
        opacity: 0.54;

        &:hover {
            opacity: 0.87;
        }

        .mat-icon {
            font-size: 40px;
        }
    }

    .mat-radio-checked {
        .mat-radio-label-content {
            color: #5cb259;
            opacity: 0.87;
        }
    }
}

#contactUsContainerId {
    .mat-fabIcon-40 {
        box-shadow: none;
        cursor: default;
    }

    .eachContactDetails {
        .icon {
            .mat-fab {
                .mat-button-wrapper {
                    padding: 11px 0;
                }
            }
        }
    }
}

#vault-location-export-dialog,
#daily-summary-export-dialog {
    @include custom-dialog-material();

    .mat-dialog-content {
        width: 700px;
        padding-top: 10px;
    }

    .mat-radio-button {
        padding-left: 8px;
    }

    .timespan-space {
        padding-bottom: 10px;
    }

    .vault-location-export-date,
    .daily-summary-export-dialog {
        ads-date-picker {
            #customDateSelector {
                margin: 0px;
                width: 100%;
            }
        }
    }

    .text-weight {
        margin: 0;
    }

    .btn-txt-trnsform {
        text-transform: capitalize !important;
    }

    .btn-position {
        position: relative;
    }

    .save-setting-dialog {
        position: absolute;
        bottom: 42px;
        left: 22px;
        background: #ffffff;
        min-width: 299px;
        padding: 15px 10px;
        box-shadow: 0px 1px 3px 1px #ddd;

        &:after {
            content: '';
            position: absolute;
            top: 100%;
            left: 10%;
            margin-left: -5px;
            border-width: 9px;
            border-style: solid;
            border-color: #ffffff transparent transparent transparent;
        }
    }

    .save-setting-footer {
        display: flex;
        border-top: 1px solid #ddd;
        padding-top: 10px;
    }

    .content-position {
        position: relative;
    }
}

.recent-export-settings {
    button.mat-menu-item {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;

        &:last-child {
            border-bottom: 0px solid rgba(0, 0, 0, 0.12) !important;
        }
    }
}

#satisficationGroup {
    @include smiley-faces-material();
}

#operationsSatisficationGroup {
    @include smiley-faces-material();
}

#navigationSatisficationGroup {
    @include smiley-faces-material();
}

#customDateSelector,
.customDateRangeSelector {
    font-size: 14px;
    width: 50%;
    padding: 0px;
    color: rgba(0, 0, 0, 0.73);
    border-bottom: 2px solid rgba(0, 0, 0, 0.42);
    line-height: 36px;
    text-align: unset;
    height: 39px;
    margin-bottom: 20px;
    position: relative;
    border-radius: 0px;

    .mat-icon {
        position: absolute;
        right: 0;
        top: 13px;
    }
}

.customDateRangeMenu {
    padding: 15px;
}

#customDateSelector {
    width: 450px;
    width: 100%;
}

ads-date-picker {
    #customDateSelector {
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 0px;
    }
}

.mat-menu-panel.customDateMenu,
.mat-menu-panel.multipleEditorCustomDateMenu,
.mat-menu-panel.customDateMenuNewEntity {
    max-width: unset;

    .mat-menu-content {
        width: 450px;
        padding: 0px;
    }

    .customDateMenuContaint {
        width: 100%;
        display: flex;

        .eachDate {
            padding: 15px 15px 0px 15px;
        }
    }

    .header {
        font-size: 14px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.73);
        margin: 15px 0;
    }

    .dateArea {
        padding: 0px 10px;
        border-right: 1px solid rgba(0, 0, 0, 0.12);

        &>div {
            width: 190px;
        }
    }

    .dateAreaFull {
        padding: 0px 10px;
        width: calc(100%);
    }

    .dateSelectorArea {
        padding: 0px 10px;

        .mat-menu-item {
            font-size: 14px;
            min-width: unset;
            padding: 0 10px;
            color: #2196f3;
            text-transform: capitalize;
            line-height: 36px;
            height: 36px;
        }

        .custom-50p-button {
            width: 215px;
            display: flex;
            flex-wrap: wrap;

            .date-ranges {
                width: 50%;
                padding: 0 5px;
                text-transform: capitalize;
            }
        }
    }

    .customDateRangeAction {
        display: flex;
    }
}

.date-range-content {
    position: absolute;
    position: absolute;
    bottom: 14px;
    font-size: 11px;
    color: #9b9b9b;
    font-weight: normal;
}

.date-value {
    bottom: -5px;
    position: absolute;
}

.mat-menu-panel.customDateMenuNewEntity {
    .mat-menu-content {
        width: 275px;
    }
}

.customDateRangeSelector.mat-button {
    width: 100%;
    padding-left: 0px;
    text-align: left;
    border-bottom: 2px solid rgba(0, 0, 0, 0.42);
}

.multipleEditorSection {
    background: #ececec;
    margin: 0px -15px;
    padding: 15px;

    .filterContent {
        .dateRangeSection {
            display: inline-block;
            width: calc(((100% - 30px) / 3) * 2);

            .eachDateSection {
                padding: 0 15px;
                display: inline-block;
                width: calc(((100% - 60px) / 2));
            }

            .custom-error-message {
                left: 15px;
            }
        }

        .entitySection {
            padding: 0 15px;
            display: inline-block;
            width: calc(((100% - 60px) / 3));

            .custom-error-message {
                left: 15px;
            }
        }
    }

    .multiEditActionOption {
        margin-top: auto;
        margin-bottom: auto;
    }

    .multiEditAction {
        display: flex;
    }
}

.mat-menu-panel.multipleEditorCustomDateMenu {
    .mat-menu-content {
        width: calc(30vw - 30px);
    }
}

.dataCorrectEntityList {
    text-transform: capitalize;
    top: 10px;
    position: relative;
}

.textCapitalize {
    text-transform: capitalize;
}

.textIntial {
    text-transform: initial;
}

#location-dashbord-filter,
#bpHumanReviewId,
#bpDailySummaryReportId,
#showDailySummaryReportId {
    position: relative;
    min-height: 40px;

    .location-info-bar {
        background-color: #fff;
        margin: 0 0 1.2rem 0;
        padding: 0.5rem 0 0;

        .location-info-bar-controls {
            flex-flow: nowrap;
            justify-content: start;

            .filter-item {
                border-style: solid;
                border-color: darken($color: $border-color, $amount: 0.2);
                border-width: 0 0.1rem 0 0;
                padding: 1rem 0.7rem 0.3rem 0.7rem;

                &.titled {
                    padding-top: 0.5rem;

                    .title {
                        color: darken($color: $border-color, $amount: 50);
                        font-size: 0.7rem;
                    }
                }

                &.ellipsable {
                    flex: 8;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                &.worker-icon {
                    padding: 0;
                    display: flex;

                    button {
                        padding: 0;
                    }
                }

                &.editors-button-wrapper {
                    padding-top: 0.5rem;
                }

                .mat-button:not([disabled]) {
                    color: #039be5;
                }

                &.loc-edit-button {
                    padding-top: 0.5rem;
                }

                &.loc-approve-button {
                    & button {
                        font-size: 16px;
                    }

                    padding-top: 0.5rem;
                }

                &.setting-button {
                    padding-top: 0.5rem;
                    padding-left: 0;
                    padding-right: 0;

                    .mat-button {
                        padding-left: 0;
                        padding-right: 0;
                        min-width: 3rem;
                    }
                }

                &.setting-button:last-of-type {
                    border: 0;

                    .mat-button {
                        min-width: 2rem;
                    }
                }
            }
        }

        .mat-accordion {
            .location-info-expansion-panel {
                &.mat-expansion-panel-spacing {
                    padding-top: 1rem;
                }

                &:not(.mat-expanded) {
                    height: 0.4rem;
                    box-shadow: none;
                }

                &.mat-expanded {
                    background-color: darken($color: #fff, $amount: 5);
                    border-top: 1px solid darken($color: #fff, $amount: 15);
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    margin-top: 0.5rem;
                    position: static;
                }

                .mat-expansion-panel-body {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    padding-bottom: 0;
                }
            }
        }

        app-entity-selector,
        app-multi-select-group,
        ads-prism-single-select-group {
            position: static;

            mat-selection-list.multi-select-list {
                width: 100%;
            }
        }
    }

    &.data-edit-mode-filter {
        .location-info-bar {
            background-color: #ffe0b5;
            color: rgba(0, 0, 0, 0.87);

            .location-info-expansion-panel {
                .mat-expansion-panel-body {
                    justify-content: end;
                }

                &:not(.mat-expanded) {
                    background-color: #ffe0b5;
                }
            }

            .location-info-bar-controls {
                .filter-item:first-of-type {
                    img {
                        height: 50px;
                        padding: 0 5px 0 0;
                    }
                }

                .filter-item {
                    border-color: darken($color: $border-color, $amount: 20);
                    border-right-width: 0.05rem;

                    &.location-info {
                        align-items: center;
                        display: flex;
                        padding-top: 0;
                    }

                    .mat-button {
                        color: darken($color: $border-color, $amount: 50);

                        &.editors-button {
                            text-transform: uppercase;
                            color: #039be5;
                        }
                    }
                }
            }
        }
    }

    #entities {
        margin-right: 20px;
        min-width: 200px;
        font-size: 14px;
    }

    #entities.dynamicEntities {
        width: calc(25% - 20px);
    }

    #location-dashbord-date {
        position: relative;
        width: 50%;

        .eachDate {
            width: calc((100% - 20px) / 2);
            margin-right: 20px;
        }

        .fromError {
            left: 0px;
            position: absolute;
            bottom: 0px;
            font-size: 12px;
        }

        .toErrorSection {
            position: relative;
            margin-right: 0px;
        }

        .toError {
            left: 0px;
            position: absolute;
            bottom: 0px;
            font-size: 12px;
        }

        .rangeError {
            left: 0px;
            position: absolute;
            bottom: 0px;
            font-size: 12px;
        }
    }

    .multi-location-select {
        label.label {
            -webkit-transform: translateY(0em) scale(0.75) perspective(100px) translateZ(0.001px);
            transform: translateY(0em) scale(0.75) perspective(100px) translateZ(0.001px);
            -ms-transform: translateY(0em) scale(0.75);
            color: rgba(0, 0, 0, 0.54);
        }
    }

    .data-averaging-options {
        min-width: 215px;
    }
}

.data-edit-accept {
    background-color: #5cb259;
    opacity: 1;
    color: #fff;
    margin-right: 10px !important; // overwrite button property
    border-radius: 10px !important; // overwrite button property
    text-transform: uppercase;

    &.mat-raised-button[disabled] {
        opacity: 0.36;
        background-color: #5cb259;
        color: #fff;
    }
}

.data-edit-cancel {
    margin-right: 10px !important; // overwrite button property
    border-radius: 10px !important; // overwrite button property
    text-transform: uppercase;
}

#locationDashboardAnnotation {
    min-width: 200px;
    background: #fff;
    position: absolute;
    top: 80px;
    right: 10px;
    z-index: 9999;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

    .annotationHeader {
        font-weight: 500;
        display: flex;
        height: 48px;
        padding-left: 16px;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        font-family: Roboto, 'Helvetica Neue';
        font-size: 14px;

        .backIcon {
            @include custom-matIcon();
        }
    }

    .mat-expansion-panel:not([class*='mat-elevation-z']) {
        box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.12);
        border-bottom: 1px solid rgba(0, 0, 0, 0.37);
    }

    .mat-expansion-panel-spacing {
        margin: 0;
    }

    .mat-expansion-panel-header {
        padding: 0 16px 0 24px;
        font-size: 14px;
        font-weight: 500;
        background: #ececec;
    }

    .mat-expansion-panel-body {
        padding: 0 16px 16px 24px;
    }

    .anotationBorder {
        border-top: 1px solid rgba(0, 0, 0, 0.37);

        button {
            height: 48px;
            text-align: left;
        }
    }

    .close {
        color: #2196f3;

        button {
            text-align: right;
        }
    }

    .annotationList {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.annotationList {
    padding-left: 15px;
    padding-right: 15px;

    .mat-slide-toggle {
        display: block;
        height: 40px;

        .mat-slide-toggle-label {
            flex-direction: row-reverse;
            justify-content: space-between;
        }
    }
}

#location-dashbord-date {
    @include my-date-picker(167px);
}

#alarmWidgetFilter {
    @include my-date-picker(190px);
}

#autoReviewWidgetFilter {
    @include my-date-picker(190px);
}

#blockagePredictionWidgetFilter {
    @include my-date-picker(190px);
}

#collectionWidgetFilter {
    @include my-date-picker(190px);
}

#autoScrubSummaryFilter {
    @include my-date-picker(190px);
}

.custom-50p {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;

    .each-Item {
        width: 45% !important;
    }
}

.custom-33p {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;

    .each-Item {
        width: 30%;
    }
}

.errorMsgText {
    font-size: 12px;
    color: red;
}

mat-card-header.reportDetailsCardTitle.mat-card-header {
    background: #fff;
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    color: #7e7e7e;

    .mat-card-header-text {
        margin: 0;
    }
}

.rainfalldurationtable {
    @include custom-table-material(#ffffff,
        #000);

    .mat-table {
        .mat-header-cell:first-child {
            text-align: center;
        }

        .mat-row {
            &:nth-child(2) {
                background-color: #e1f5fe;
            }

            &:last-child {
                border-bottom: none;
            }
        }

        .mat-cell {
            margin-right: 10px;

            .mat-form-field-underline {
                height: 1px !important;
            }

            .mat-form-field-infix {
                width: 100%;
            }

            .mat-select {
                min-width: 45px;
            }
        }
    }
}

#alarmLeaderBoardTable {
    @include custom-table-material(#ffffff,
        #000);

    .fullScreenShow.customLongName {
        width: 400px;
    }

    .mat-icon-button {
        height: 25px;
        width: 25px;
        line-height: 25px;

        &[disabled] {
            opacity: 0.36;
        }
    }
}

#batteryLeaderBoardTable {
    @include custom-table-material(#ffffff,
        #000);

    .fixCellWidth {
        width: 100px;
    }

    .fix-cell-status-width {
        width: 200px;
    }
}

#blockageLeaderBoardTable {
    @include custom-table-material(#ffffff,
        #000);

    .customLongName {
        width: 80px;
    }

    .box-Label {
        padding: 5px 0px 0px 0px;
        font-weight: bold;
        color: #fff;
        border-radius: 5px;
        font-size: 12px;
        width: 40px;
        display: block; // float: right;
        margin: auto;
        position: relative;

        .cross-label {
            color: white;
            background: #f44336 !important;
            border-radius: 5px;
            font-size: 22px !important;
            text-align: center;
            line-height: 24px;
        }

        .warning-label {
            font-size: 22px !important;
        }

        .yellow-label {
            display: inline-block;
            width: 30px;
            height: 30px;
            background-image: url(#{$assetPath}/warning_icon.svg);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    .mat-row {
        .icon {
            padding: 9px 17px;
        }
    }

    .mat-column-bpScore {
        text-align: center;
    }

    .mat-header-cell {
        &.cdk-column-blockStatus {
            .mat-sort-header-container {
                justify-content: center;
            }
        }
    }

    .mat-column-blockStatus {
        text-align: center;
    }
}

#autoReviewLeaderBoardTable {
    @include custom-table-material(#ffffff,
        #000);

    .fullScreenHide.customLongName {
        width: 125px;
    }
}

#collectionLeaderBoardTable {
    @include custom-table-material(#ffffff,
        #000);

    .fullScreenShow.customLongName {
        width: 400px;
    }

    .mat-table {
        .mat-header-row {
            .mat-header-cell {
                padding: 15px 5px;
            }
        }

        .mat-row {
            .mat-cell {
                padding: 9px 5px;
            }
        }
    }

    .fix-width-160 {
        width: 160px;
    }

    .fullScreenHide.customLongName {
        width: 159px;
    }

    .location-name-class {
        @include text-ellipsis(200px);
    }

    .collection-action {
        @include action-column-button();
    }
}

#communication-expand-table {
    @include custom-table-material(#ffffff,
        #000);

    .fixCellWidth {
        width: 90px;
    }

    .collection-action {
        @include action-column-button();
    }
}

#locationGroupEditorTable {
    @include custom-table-material(#ffffff,
        #000);

    .fixCellWidth {
        width: 60px;
    }

    .customLongName {
        width: 410px;
    }
}

#addEvent {
    @include custom-dialog-material();

    .mat-dialog-content {
        width: 550px;
    }
}

#turnOffWidgetsDialog {
    @include custom-dialog-material();
}
#addAnnouncement {
    @include custom-dialog-material();

    .mat-dialog-content {
        width: 550px;
    }
}

#qvsi-group-dialog {
    @include custom-dialog-material();
}

#addschedule {
    @include custom-dialog-material();

    .mat-dialog-content {
        width: 550px;
    }

    .selection-options {
        width: 100%;

        .select-choices {
            display: inline-block;
            width: calc((100% - 20px) / 2);
            line-height: 2.5;
            padding: 5px;

            .mat-checkbox-layout {
                white-space: unset;
                word-break: break-word;
            }
        }
    }

    .location-group-space {
        border: 1px solid #ddd;
        padding: 10px 5px;
        max-height: 250px;
        overflow-y: auto;
        margin-bottom: 10px;
    }
}

#customerGroupEditorTable {
    @include custom-table-material(#ffffff,
        #000);
}

#scheduleCollectionEditorTable {
    @include custom-table-material(#ffffff,
        #000);

    .fixCellWidth {
        width: 60px;
    }

    .customLongName {
        width: 400px;
    }
}

#uptimeOverviewLeaderBoardTable {
    @include custom-table-material(#ffffff,
        #000);

    .mat-table {
        min-width: 700px;
    }

    .mat-header-cell:first-child {
        width: 200px;
    }

    .mat-header-cell {
        text-transform: uppercase;
    }

    .mat-header-cell.defultTextStyle {
        text-transform: unset;
    }

    .mat-paginator {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
}

#percentFullOverviewReportLeaderBoardTable {
    @include custom-table-material(#ffffff,
        #000);

    .mat-sort-header-container {
        justify-content: space-around;
    }

    .mat-header-cell:first-child {
        width: 200px;
    }

    .mat-row {
        .mat-cell {
            border-right: 1px solid rgba(0, 0, 0, 0.12);

            &:last-child {
                border-right: 0px solid rgba(0, 0, 0, 0.12);
            }
        }
    }
}

#sliicerpagetable,
#fmrtable,
#locationpagetable {
    @include custom-table-material(#ffffff,
        #000);

    .fixCellWidth {
        width: 100px;
    }

    .mat-table {
        .mat-header-row {
            border-top: 1px solid rgba(0, 0, 0, 0.12);
        }

        .table-icon {
            flex: 0 0 50px;
        }
    }
}

.search-icon {
    top: 5px;
    position: relative;
    opacity: 0.54;
}

#usersPageTable,
#customersPageTable,
#auditPageTable,
#dataEditingTableId {
    .mat-sort-header-pointer-left {
        transform: rotate(-45deg);
    }

    .mat-sort-header-pointer-right {
        transform: rotate(45deg);
    }
}


#rainfall-distribution-table {
    height: 100% !important;
}

#usersPageTable,
#customersPageTable,
#stormTable,
#auditPageTable,
#dataEditingTableId,
#calculatedEntityPageTable,
#customerDetailLocationsEditorPageTable,
#sliicerVaultTable,
#geometry-table,
#hyetograph,
#dayweekGroupPageTable,
#dataCommunicationTable,
#details-page-table,
#schedulingdatatable,
#rainfall-event-table,
.load-setting-table,
#stormStatsTable,
#monitorConfigurationTable,
#modem-setup-table,
#basinTable {
    @include custom-table-material(#ffffff,
        #000);
    overflow: auto;

    .fixCellWidth {
        width: 60px;
    }

    .fixCellWidth150 {
        width: 150px;
    }

    .fixCellWidth110 {
        width: 110px;
    }

    .fixCellEllipsis {
        width: 300px;
    }

    .fixCellWidth30 {
        width: 30px;
    }
}

@mixin cell() {
    background-color: rgba(0, 0, 0, 0.12);
    border-right: 1px solid #efeaea;
}

@mixin padding() {
    padding-top: 0px;
    padding-bottom: 0px;
}

#geometry-table {
    .text-right {
        text-align: right !important;
        width: 55%;
    }

    .mat-table {

        .mat-cell:nth-child(2),
        .mat-cell:nth-child(3),
        .mat-cell:nth-child(4) {
            .mat-form-field-underline {
                width: 55%;
            }
        }

        .mat-row:last-child {
            .mat-cell {
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            }
        }

        .mat-header-cell:first-child {
            @include cell();
        }

        .mat-cell:first-child {
            @include cell();
        }

        .mat-cell {
            @include padding();
        }
    }
}

#details-page-table {
    overflow: unset;
}

#customerDetailLocationsEditorPageTable {
    .mat-sort-header-indicator {
        transform: translateY(10px);
    }

    .mat-sort-header-pointer-left {
        transform: rotate(45deg);
    }

    .mat-sort-header-pointer-right {
        transform: rotate(-45deg);
    }

    .mat-sort-header-arrow {
        opacity: 0;
    }

    .description-space {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: unset;
        max-width: 150px;
    }
}

#dayweekGroupPageTable {
    .mat-row:last-child {
        .mat-cell {
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        }
    }
}

#schedulingdatatable {
    .mat-row {
        .mat-cell:nth-child(3) {
            padding: 3px 2px;
        }
    }

    .fix-cell-days {
        width: 270px;
    }

    .days-container {
        min-width: 310px;
    }

    .frequency-cell-width {
        min-width: 100px;
    }

    .locations-name-list {
        white-space: nowrap;
        min-width: 100px;
        width: calc(100vw - 970px);
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .schedule-name {
        word-break: break-all;
    }

    .schedule-action {
        width: 60px;
        min-width: 60px;

        button.mat-icon-button {
            width: 28px;
        }
    }
}

#markerCompositeLocationTable {
    @include custom-table-material(#ffffff,
        #000);
    overflow: auto;
    max-width: 360px;
}

#userPreferencesCheckboxTable {
    @include custom-table-material(#ffffff,
        #000);
}

#hyetograph {
    @include custom-table-material(#ffffff,
        #000);
}

#location-flow-balance-table {
    @include custom-table-material(#ffffff,
        #000);
    padding-bottom: 15px;
    padding-top: 25px;

    .mat-row:nth-last-child(-n+4) {
        background: #eeeeee;
        font-weight: bold;
    }
}

@mixin vault-file-type-icon() {
    background-image: url('./../images/vault_icon.png');
    background-repeat: no-repeat;
    height: 30px;
    min-width: 30px;
    padding-right: 5px;
    display: table-cell;
}

@mixin vault-file-type-big-icon() {
    background-image: url('./../images/vault_Bigicon.png');
    background-repeat: no-repeat;
    height: 125px;
    width: 100px;
    text-align: center;
    margin: auto;
}

.header-divider {
    display: flex;

    .header-text {
        margin-right: 15px;
        font-size: 14px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.87);
    }

    @include custom-buttom-material();
}

.vault-folder-file {
    position: relative;

    .vault-create-folder {
        @include custom-buttom-material();
        position: absolute;
        right: 30px;
        top: 8px;
        z-index: 11;
    }

    &.list-view {
        .vault-folder-list {
            .each-file {
                border: 0px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                margin: 0px;
                width: 100%;

                .folder-name {
                    width: calc(100% - 40px);
                    max-width: calc(100% - 40px);
                    text-align: left;
                }

                .mat-button {
                    border-left: 0px solid rgba(0, 0, 0, 0.12);
                }
            }
        }
    }
}

.folder-accordion {
    .mat-expansion-panel {
        margin: 0px;
    }

    .mat-expansion-panel:not([class*='mat-elevation-z']) {
        border-radius: 0px !important; // overwrite the border
        box-shadow: none;
        border: 1px solid rgba(0, 0, 0, 0.12);
    }

    .mat-expansion-panel-header {
        padding: 0 15px;
        font-size: 14px;
        font-weight: 600;
        background: #ececec !important; // overwrite the background
        height: 48px !important; // overwrite the height
    }

    .mat-expansion-panel-body {
        padding: 15px;
    }
}

.vault-file-list {
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 15px;
    margin-top: 15px;
}

.vault-folder-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -7.5px;
    margin-right: -7.5px;

    .each-file {
        border: 1px solid rgba(0, 0, 0, 0.12);
        margin: 7.5px;
        display: flex;
        background-color: white;
        transition: 0.3s;

        &:hover {
            box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        }

        .folder-name {
            margin: auto;
            padding: 0 10px;
            opacity: 0.87;
            width: 160px;
            text-overflow: ellipsis;
            overflow: hidden;
            border-radius: 0px;
            text-align: left;
        }

        .mat-icon {
            margin: auto;
            opacity: 0.54;
        }

        .mat-button {
            min-width: 40px;
            max-width: 40px;
            padding: 0px;
            border-left: 1px solid rgba(0, 0, 0, 0.12);
            opacity: 0.54;

            &:hover {
                opacity: 0.87;
            }

            &.active {
                opacity: 0.87;
            }

            .mat-icon {
                opacity: 1;
                padding-left: 0px;
            }
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

.view-toggle {
    .mat-button-toggle-group {
        border: 0px;

        .mat-button-toggle {
            border-right: 1px solid;
            background: no-repeat;

            &:last-child {
                border-right: 0px solid;
            }
        }

        .mat-button-toggle-appearance-standard {
            color: rgba(0, 0, 0, 0.54);
        }

        .mat-button-toggle-checked {
            .mat-icon {
                color: #5cb259;
            }
        }
    }

    .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
        line-height: 25px;
        padding: 0 5px;
    }
}

.vault-toggle-sidenav {
    width: 250px;
    height: 40px;
    background: #263238;
    cursor: pointer;
    display: flex;
    position: absolute;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    top: 0;

    .mat-button {
        min-width: 40px;
        padding: 0px;
        border-left: 1px solid;
        border-radius: 0px;
        background: #fff;
        color: #000;
    }

    .folders-text {
        margin: auto 10px;
    }

    .backIcon {
        margin: auto;
        @include custom-matIcon();
    }
}

.vault-file-preview {
    display: flex;
    flex-wrap: wrap;
    margin-left: -7.5px;
    margin-right: -7.5px;

    .each-file {
        border: 1px solid rgba(0, 0, 0, 0.12);
        width: 160px;
        margin: 7.5px;
        transition: 0.3s;

        &:hover {
            box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        }

        .file-preview {
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            padding: 10px;

            .file-img-priview {
                height: 125px;
                display: table-cell;
                vertical-align: middle;
                text-align: center;
                width: 160px;

                img {
                    max-height: 117px;
                    max-width: 145px;
                }
            }
        }

        object {
            width: 100px;
            height: 150px;
        }

        .file-name {
            padding: 4px 8px;
            display: flex;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            word-wrap: unset;
            width: calc(100% - 15px);

            .name-text {
                margin: auto 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                word-wrap: unset;
            }
        }

        .file-action {
            display: flex;
            background: rgba(0, 0, 0, 0.04);

            .mat-button {
                min-width: 40px;
                border-left: 1px solid rgba(0, 0, 0, 0.12);
                padding: 0 8px;
                width: 100%;
                opacity: 0.54;

                &:hover {
                    opacity: 0.87;
                }

                &.active {
                    opacity: 0.87;
                }
            }

            .mat-button:first-child {
                border-left: 0px solid rgba(0, 0, 0, 0.12);
            }
        }
    }
}

.vault-file-preview {
    .file-type-big-icon-default {
        @include vault-file-type-big-icon();
        background-position: -812px -250px;
    }

    .file-type-big-icon-pdf {
        @include vault-file-type-big-icon();
        background-position: -89px -81px;
    }

    .file-type-big-icon-csv {
        @include vault-file-type-big-icon();
        background-position: -630px -250px;
    }

    .file-type-big-icon-doc {
        @include vault-file-type-big-icon();
        background-position: -812px -81px;
    }

    .file-type-big-icon-gif {
        @include vault-file-type-big-icon();
        background-position: -266px -81px;
    }

    .file-type-big-icon-jpg {
        @include vault-file-type-big-icon();
        background-position: -629px -81px;
    }

    .file-type-big-icon-ppt {
        @include vault-file-type-big-icon();
        background-position: -90px -250px;
    }

    .file-type-big-icon-tif {
        @include vault-file-type-big-icon();
        background-position: -266px -250px;
    }

    .file-type-big-icon-xlsx,
    .file-type-big-icon-xls {
        @include vault-file-type-big-icon();
        background-position: -443px -81px;
    }

    .file-type-big-icon-png {
        @include vault-file-type-big-icon();
        background-position: -443px -250px;
    }
}

#valutItemDataLeaderBoardTable,
.vault-file-preview {
    @include custom-table-material(#ffffff,
        #000);

    .fileTypeIcon_default {
        @include vault-file-type-icon();
        background-position: -245px -81px;
    }

    .fileTypeIcon_pdf {
        @include vault-file-type-icon();
        background-position: -27px -29px;
    }

    .fileTypeIcon_csv {
        @include vault-file-type-icon();
        background-position: -188px -81px;
    }

    .fileTypeIcon_doc {
        @include vault-file-type-icon();
        background-position: -245px -29px;
    }

    .fileTypeIcon_gif {
        @include vault-file-type-icon();
        background-position: -82px -29px;
    }

    .fileTypeIcon_jpg {
        @include vault-file-type-icon();
        background-position: -188px -29px;
    }

    .fileTypeIcon_ppt {
        @include vault-file-type-icon();
        background-position: -28px -81px;
    }

    .fileTypeIcon_tif {
        @include vault-file-type-icon();
        background-position: -81px -81px;
    }

    .fileTypeIcon_xls {
        @include vault-file-type-icon();
        background-position: -133px -29px;
    }

    .fileTypeIcon_png {
        @include vault-file-type-icon();
        background-position: -136px -81px;
    }

    a.file-link {
        display: table-cell;
        word-break: break-all;
    }

    .vaultActionsColumnHeader {
        width: 190px;
    }

    .vaultActionsColumnCell {
        .mat-button {
            min-width: 10%;
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .mat-row {
        .mat-cell {
            vertical-align: middle;

            img.file-icon {
                vertical-align: middle;
                height: 25px;
            }

            mat-icon {
                vertical-align: middle;
            }

            a {
                vertical-align: middle;
            }
        }
    }
}

.location-files {
    .folder-accordion {
        .mat-expansion-panel {
            margin: 0px;
        }

        .mat-expansion-panel-header {
            padding: 0 15px;
        }

        .mat-expansion-panel-body {
            padding: 15px;
        }
    }

    .breadcrumb {
        color: rgba(0, 0, 0, 0.87);
        word-break: break-all;
        width: calc(100vw - 400px);
        min-width: 345px;

        span {
            color: #2196f3;

            &:last-child {
                color: rgba(0, 0, 0, 0.87);
            }
        }
    }
}

.uptimeDetailsTable {
    table {
        table-layout: fixed;

        thead {
            tr {
                th {
                    width: 100px;
                }
            }
        }
    }
}

.load-setting {
    .load-setting-table {
        .mat-table {
            .mat-header-row {
                .mat-header-cell {
                    &:nth-child(4) {
                        text-align: right;
                    }
                }
            }

            .mat-row {
                >.mat-cell {
                    padding: 5px 13px;

                    &:nth-child(4) {
                        text-align: right;
                    }
                }
            }
        }
    }
}

.endDateErrorMessage {
    position: absolute;
    left: 234px;
}

mat-datepicker-content {

    .mat-calendar-next-button::after,
    .mat-calendar-previous-button::after {
        top: -3px;
        margin: 14.5px;
    }
}

.nowrap-white-space {
    white-space: nowrap;
}

.mat-calendar-table {
    border-collapse: separate !important;
}

// overright global scroll bar
.cdk-global-scrollblock {
    overflow-y: auto;
    position: unset !important;
}

.cdk-overlay-pane.mat-tooltip-panel {
    z-index: 9999;
}

.menuAssistanceCenter {
    button.mat-menu-item {
        font-size: 14px;

        .mat-icon {
            margin-right: unset;
            font-size: 19px;
            height: unset;
        }
    }
}

.auto-margin {
    margin: auto;
}

.auto-margin-t-b {
    margin-top: auto;
    margin-bottom: auto;
}

.mat-dialog-actions {
    padding: 12px 8px;

    .right-align {
        text-align: right;
    }

    .left-align {
        text-align: left;
    }
}

.mat-form-field {
    font-size: 14px;
}

#confirmation-dialog,
#edit-admin-user-dialog {
    @include custom-dialog-material();

    .popupHeader {
        background: unset;
        color: rgba(0, 0, 0, 0.87);
    }

    .mat-dialog-content {
        min-width: unset;
        width: 350px;
        overflow-wrap: break-word;
    }

    .mat-dialog-actions {
        border-top: 0px solid rgba(0, 0, 0, 0.12);
    }
}

.modem-setup {
    @include custom-dialog-material();

    .mat-dialog-content {
        min-width: 660px;

        #modem-setup-table {
            margin-bottom: 27px;

            &:last-child {
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            }
        }
    }

    .mat-fab {
        top: -30px;
        left: 15px;
    }

    .password-icon {
        position: absolute;
        right: 0;
        top: 15px;
    }

    .password-txt-width {
        max-width: 70px;
    }

    .header-with-icon {
        padding-left: 5px !important;
    }
}

.add-modem-setup {
    @include custom-dialog-material();

    .mat-form-field-flex {
        align-items: flex-end;
    }

    .header-with-icon {
        padding-left: 5px !important;
    }
}

#vaultTelemetryDialog {
    @include custom-dialog-material();
    @include custom-conformation();

    .mat-dialog-content {
        width: 550px;

        .mat-header-row {
            min-height: 0px;

            .mat-header-cell {
                padding: 0px;
                border-bottom: 0px;
                border-top: 0px;
            }
        }
    }

    .file-name-header {
        padding-left: 5px !important;
    }

    .uploaded-files {
        background: #263238;
        color: #ffffff;
        text-align: center;
        padding: 15px 10px;
        margin-top: 1px;
        font-size: 16px;
        margin: 0 -15px;
        border-top: 1px solid;

        .file-checked {
            background-color: #5cb259;
            border-radius: 19px;
            padding: 5px;
        }
    }

    .telemetry-validate-section {
        min-width: 500px;

        .mat-dialog-content {
            padding: 0;
        }

        .mat-expansion-panel {
            margin: 0px;
        }

        .mat-expansion-panel:not([class*='mat-elevation-z']) {
            border-radius: 0px !important; // overwrite the border
            box-shadow: none;
            position: relative;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);

            &:last-child {
                border-bottom: 0px solid rgba(0, 0, 0, 0.12);
            }
        }

        .mat-expansion-panel-header {
            padding: 0 15px;
            font-size: 14px;
            font-weight: 600;
            background: #ececec !important; // overwrite the background
            height: 48px !important; // overwrite the height
        }

        .mat-expansion-panel-header-description {
            justify-content: flex-end;
        }

        .file-import-check {
            position: absolute;
        }

        .panel-name {
            padding-left: 30px;
        }

        .mat-expansion-panel-body {
            padding: 15px;
        }

        .panel-error-style {
            display: flex;
            color: #f44336;

            .mat-icon {
                font-size: 18px;
            }
        }
    }
}

#dataDeleteDialog {
    @include custom-dialog-material();
    @include custom-conformation();
}

#wetdryoverflowDialog {
    @include custom-dialog-material();

    .mat-dialog-content {
        overflow: auto;
    }
}

#userPreferencesDialog {
    @include custom-dialog-material();
    @include custom-conformation();

    .mat-dialog-content {
        width: 550px;
        padding: 15px;
        overflow: auto;

        .mat-card-subtitle {
            font-size: 14px;
            margin: unset;
            font-weight: 600;
        }

        .mat-card-title {
            color: rgba(0, 0, 0, 0.87);
        }

        .mat-header-row {
            min-height: 0px;

            .mat-header-cell {
                padding: 0px;
                border-bottom: 0px;
                border-top: 0px;
            }
        }

        .mat-expansion-panel-header {
            padding: 0 15px;
        }

        .mat-expansion-panel-body {
            padding: 0 15px 15px;
        }

        .mat-expansion-panel-header:not([aria-expanded='true']) {
            background: #ececec;
        }

        .mat-expanded {
            .mat-expansion-panel-header {
                font-weight: 600;
            }
        }
    }
}

#WelcomeContactUsDialog {
    @include custom-dialog-material();
}

#customerEditorDialog {
    @include custom-dialog-material();
    @include custom-conformation();

    .mat-dialog-content {
        width: 550px;

        .mat-header-row {
            display: none;
            min-height: 0px;

            .mat-header-cell {
                padding: 0px;
                border-bottom: 0px;
                border-top: 0px;
            }
        }
    }
}

#locationGroupEditorDialog {
    @include custom-dialog-material();
    @include custom-conformation();

    .mat-dialog-content {
        max-width: 552px;
        padding-bottom: 5px;

        .mat-header-row {
            display: none;
            min-height: 0px;

            .mat-header-cell {
                padding: 0px;
                border-bottom: 0px;
                border-top: 0px;
            }
        }
    }
}

#flowMonitorChangesDialog {
    width: 600px;

    .popupHeader {
        padding: 20px 25px;
        box-sizing: border-box;
    }

    .dialogBody {
        padding: 15px 0;
    }

    .mat-dialog-actions {
        padding: 0 25px !important;
    }

    @include custom-dialog-material();
}

#DetailsDialog {
    @include custom-dialog-material();
}

#locationEditorDialog,
#locationEditorTritonDialog {
    width: 800px;
    @include custom-dialog-material();
}

#stormSettingsDialog {
    width: 900px;
    @include custom-dialog-material();
}

#editStormEventsDialog {
    width: 900px;

    .smallerText {
        font-size: 0.8rem;
    }

    .popupHeader {
        padding: 20px 25px;
        box-sizing: border-box;
    }

    .dialogContent {
        position: relative;
    }

    .dialogBody {
        padding: 15px 0;
    }

    .mat-dialog-actions {
        padding: 0 25px !important;
    }

    .mat-row:hover {
        background-color: #eeeeee;
    }

    .mat-cell:nth-child(1),
    .mat-header-cell:nth-child(1) {
        max-width: 30px;
        padding-left: 10px;
    }

    @include custom-dialog-material();
}

.save-close {
    .mat-button {
        margin-right: 0px !important;

        &.options {
            border-left: 1px solid;
            border-radius: 0px;
            padding: 0px !important;
            margin-right: 8px !important;
            margin-left: 0px !important;
        }
    }
}

.options-content {
    .mat-menu-content {
        padding: 0px !important;

        button.mat-menu-item {
            height: 40px;
            line-height: 40px;
            text-transform: uppercase;
            color: #2196f3;
            min-width: 140px;
        }
    }
}

#locationEditorTritonDialog {
    @include custom-conformation();

    .mat-dialog-content {
        margin: 0px -15px;
        padding: 0;
    }

    .mat-expansion-panel-header {
        padding: 0 15px;
    }

    .mat-expansion-panel-body {
        padding: 0 15px 15px;
    }

    .mat-expansion-panel-header:not([aria-expanded='true']) {
        background: #ececec;
    }

    .mat-expanded {
        .mat-expansion-panel-header {
            font-weight: 600;
        }
    }

    .configure-alarms {
        .each-configure-alarm {
            display: flex;

            .each-alarm {
                width: 170px;

                .mat-slide-toggle {
                    width: 100%;

                    .mat-slide-toggle-label {
                        justify-content: space-between;
                    }
                }
            }

            .each-Item {
                width: 50%;
                margin-left: 8px;
            }

            .custom-slide-toggle {
                .mat-slide-toggle-content {
                    overflow: unset;
                    word-break: break-word;
                    text-overflow: unset;
                    white-space: normal;
                }
            }

            &.flow-loss-alaram {
                display: flex;
                margin: 15px 0;

                .each-Item {
                    width: 70px;
                }

                .flow-loss-alaram-toggle {
                    width: 170px;
                }

                .reset-pattern-history-toggle {
                    width: 142px;
                }
            }
        }
    }
}

#DetailsDialog {
    #details-page-table {
        .head-cell {
            font-size: 15px;
        }
    }
}

#map-location-details {
    cursor: default !important;
    background-color: #fff;
    border: 0px solid #ddd;
    padding: 0px;
    z-index: 999;
    position: fixed;
    top: calc(100vh - 600px);
    left: 50px;
    width: 600px;
    box-shadow: 7px 7px 7px -2px rgba(0, 0, 0, 0.2), 0 4px 2px 0 rgba(0, 0, 0, 0.14), 0 -2px 5px 0 rgba(0, 0, 0, 0.12);

    #locationEditorTritonDialog {
        .mat-dialog-title {
            margin: 0;
            width: 100%;
        }

        .mat-dialog-content {
            max-height: 400px;
            margin: 0;
            overflow: auto;
        }

        .mat-dialog-actions {
            display: flex;
            margin-left: 0px;
            margin-right: 0px;

            &:last-child {
                margin-bottom: 0px;
            }
        }
    }
}

#compositeLocationDialog {
    width: 600px;
    @include custom-dialog-material();

    .compositeLocationLocationList {
        display: flex;
        justify-content: space-between;

        .each-Item {
            width: calc((100% - 100px) / 3);
        }

        .mat-button {
            min-width: 40px;
        }
    }
}

#markerLocationClusterId {
    @include custom-dialog-material();
    cursor: default;
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 15px;
    z-index: 1;
    position: absolute;
    right: 80px;
    top: 0px;
    min-width: 300px;
    max-width: 600px;
    height: 599px;

    .mat-dialog-title {
        display: table;
    }

    .mat-dialog-content {
        overflow: auto;
        min-width: auto;
        height: 492px;
        max-height: unset;
    }

    .mat-dialog-actions {
        display: flex;
    }

    .mat-list-item-content {
        padding: 0px;
    }

    #locationListId {
        .locationTypeIcon {
            display: table-cell;
            width: 35px;
        }

        .locationNameMarker {
            display: table-cell;
        }
    }

    .isCollectingSpiner {
        height: 34px;
    }
}

#markerCompositeLocationId {
    @include custom-dialog-material();
    padding: 15px;

    .mat-dialog-content {
        overflow: auto;
        min-width: auto;
        height: 350px;
        max-height: 350px;
        margin: 0 -15px;
        padding: 0 15px;
    }

    .mat-dialog-actions {
        display: flex;
    }
}

#monitorSettingsDialog {
    width: 600px;
    @include custom-dialog-material();
}

#generateApiKeyDialog {
    @include custom-dialog-material();
}

#slicerDialog {
    @include custom-dialog-material();
}

#SliicerFlowMonitorChangesDialog {
    .confirm-changes {
        text-transform: none;
        font-size: 25px;
        font-weight: 400;
        padding: 10px 15px !important;
    }

    width: 500px;
    @include custom-dialog-material();

    .savedata-list {
        padding-left: 18px;
    }
}

.no-text-case {
    text-transform: none !important;
}

#slicerImportVaultStudyDialog {
    @include custom-dialog-material();

    .mat-dialog-content {
        padding: 0;
    }

    #sliicerVaultTable {
        .mat-table {
            .mat-radio-button {
                padding-left: 0;
            }
        }
    }

    .content-space {
        padding: 0 15px;
    }

    .input-text-width {
        width: 45%;
    }

    .text-style {
        font-weight: 600;
    }
}

#CaseStudyDetails {
    .mat-tab-body {
        min-height: calc(100vh - 275px) !important;
        box-sizing: border-box;
    }

    .setUpStudy {
        margin-top: 1px;

        .summary-details {
            margin-top: 0;
            border-radius: 0px;
            box-shadow: none;

            #dropdown {
                option:hover {
                    background-color: green;
                }
            }

            table,
            th,
            td {
                border: 1px solid black;
                border-collapse: collapse;
            }

            th,
            td {
                padding: 5px;
                text-align: left;
            }

            .dayweek {
                width: 35%;
            }

            .add {
                position: relative;
                top: 6px;
                padding-right: 4px;
                padding-left: 47px;
            }

            .list {
                padding: 0 18px;
            }
        }

        .file-upload-dialog {
            padding-top: 15px;
            padding-bottom: 15px;

            .upload-file {
                text-align: center;
            }

            .text-top-space {
                text-align: center;
                margin-top: 15px;
            }

            #importfiles {
                height: 0.1px;
                opacity: 0;
                overflow: hidden;
                position: absolute;
                width: 0.1px;
                z-index: -1;

                &+label {
                    color: #2196f3;
                    cursor: pointer;
                    display: block;
                    font-size: 1.25em;
                    font-weight: 700;
                    height: 80%;

                    .uploadIcon {
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: auto;
                        -webkit-box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.12);
                        -moz-box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.12);
                        box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.12);

                        .mat-icon {
                            height: 50px;
                            width: 50px;
                            font-size: 50px;
                        }
                    }

                    span.smallLink {
                        text-decoration: underline;
                    }
                }
            }

            .vault-button {
                background-color: $green-color;
                color: white;
                border-radius: 7px;
            }

            .vault-button {
                &:hover {
                    background-color: $green-color  !important;
                }
            }
        }
    }

    .custom-vertical-tab {
        background-color: white;

        .mat-tab-body-wrapper {
            .mat-tab-group.mat-primary {
                display: flex;
                flex-direction: column;

                .mat-tab-header {
                    border-right: none;
                    padding-top: 10px;
                }

                .mat-tab-labels {
                    flex-direction: row;
                    border-top: 1px solid #dddd;
                    border-bottom: 1px solid #ddd;
                    background-color: #f1f1f1;

                    .mat-tab-label-active {
                        border-bottom: 2px solid #5cb259;
                        border-left: none;
                    }

                    .mat-tab-label {
                        text-align: center;
                        justify-content: center;
                    }
                }
            }
        }

        @mixin cell() {
            background-color: #f2f2f2 !important;
            border-right: 1px solid #efeaea;
        }

        .data-head {
            border-top: 1px solid rgba(0, 0, 0, 0.12);
            display: flex;
            overflow: hidden;

            .duration {
                @include cell();
                display: inline-block;
                min-width: 163px;
                padding: 9px 0 0 13px;
            }

            .rainfall {
                display: inline-block;
                text-align: center;
                width: calc(100% - 177px);
                background-color: #f2f2f2;
                padding: 9px 13px;
            }
        }

        .mat-header-cell:first-child {
            @include cell();
        }

        .mat-cell:first-child {
            @include cell();
        }

        .rainfall-depth {
            margin: 0px;
            padding: 0px !important;
        }

        .setting-page {
            .mat-form-field {
                .mat-form-field-infix {
                    border: 1px solid #dddd;
                }

                .mat-form-field-underline {
                    position: absolute;
                    height: 0px;
                    width: 0;
                }
            }
        }

        .mat-tab-group {
            margin: 0px;
            width: 100%;
            flex-direction: row;

            .input-text-width {
                width: 30%;
                min-width: 400px;
            }

            .full-width {
                padding-top: 10px;
            }
        }

        .mat-tab-body {
            padding: 10px 20px;
        }

        .mat-tab-header {
            border-right: 1px solid #ddd;
            border-bottom: 0px;
        }

        .mat-tab-labels {
            display: flex;
            flex-direction: column;

            .mat-tab-label-active {
                border-left: 5px solid #5cb259;
            }
        }

        .mat-tab-label {
            text-align: left;
            justify-content: start;
        }

        .mat-tab-body-wrapper {
            flex-grow: 1;
        }

        .ink-bar-none {
            .mat-ink-bar {
                background-color: transparent !important;
            }
        }

        .mat-tab-label.mat-tab-label-active {
            background-color: rgba(184, 201, 207, 0.3);
            border-left: 4px solid green;
        }
    }

    .custom-settings {
        table {
            border: 1px solid rgba(0, 0, 0, 0.12) !important;
        }

        thead {
            border: 1px solid rgba(0, 0, 0, 0.12) !important;
            border-color: rgba(0, 0, 0, 0.12);
        }

        .mat-header-cell {
            background-color: #f2f2f2 !important;
            font-size: 14px;
            font-weight: 500;
            padding: 24px;
            color: black;
            width: 33% !important;
            max-width: 33% !important;
        }

        .mat-cell {
            padding-left: 24px;
            padding-right: 24px;
        }

        .mat-cell:first-child {
            border-right: none;
            background-color: white !important;
        }
    }

    .custom-stepper {
        .mat-horizontal-content-container {
            padding: 0;
        }

        .mat-step-header .mat-step-icon {
            background-color: #5cb259;
        }

        .stepperContent {
            padding: 15px 24px;
        }

        .stepper-action {
            height: 60px;
            padding: 0;
            display: flex;
            border-top: 1px solid rgba(0, 0, 0, 0.12);
            @include custom-buttom-material();

            &.margin-top {
                margin-top: 40px;
            }

            &.border-t {
                border-top: 0px;
            }
        }

        .save-button {
            border-top: none;
        }
    }
}

@mixin storm() {
    .width-84 {
        width: 84%;
    }

    .mat-table {
        .mat-header-row {
            .mat-header-cell:first-child {
                width: 10%;
                padding-top: 0px !important;
            }
        }
    }

    #stormTable {
        overflow: auto;
        max-height: 310px;
        border: 2px solid $border-color;
        min-width: 272px;
        margin: 24px 2px 20px 0px;

        .mat-table>.mat-header-row>.mat-header-cell {
            color: $black-color;
            background-color: $border-color-light;
            padding: 0px !important;
            padding-left: 12px !important;
            position: sticky;
            top: 0;
            font-size: 14px;
            font-weight: 500;
            border: 0px;
            z-index: 1000;
            padding-bottom: 0; // min-width:200px;
        }

        .mat-table>.mat-header-row>.mat-header-cell:not(:first-child) {
            min-width: 200px;
            z-index: 99;
        }

        .mat-table>.mat-header-row>.mat-header-cell:nth-child(2) {
            z-index: 101;
            left: 55px;
        }

        .mat-checkbox-checkmark-path {
            stroke: unset !important;
        }

        .mat-checkbox-checked.mat-accent .mat-checkbox-background {
            background-color: transparent !important;
        }

        .mat-checkbox-checked.mat-accent .mat-checkbox-frame {
            border-color: red;
        }

        .mat-checkbox-checked .mat-checkbox-inner-container.mat-checkbox-inner-container-no-side-margin:after {
            position: absolute;
            z-index: 999;
            content: 'x';
            height: 6px;
            width: 10px;
            color: red;
            top: 7px;
            right: 1px;
            font-size: 13px;
            font-weight: 600;
        }
    }
}

.storm {
    display: flex;
    @include storm();
}

#monitorConfigurationTable {
    margin: 0px 3px 19px 3px;
    max-height: 500px;
    overflow: auto;

    .mat-table>.mat-header-row {
        display: flex;
    }

    .mat-table>.mat-row {
        display: flex;
    }

    .mat-table>.mat-header-row>.mat-header-cell {
        color: $black-color;
        background-color: $border-color;
        padding: 0px !important;
        padding-left: 12px !important;
        font-size: 14px;
        font-weight: 500;
        min-width: 118px;
        text-align: center;
        display: grid;
    }

    .mat-table>.mat-row>.mat-cell {
        text-align: center;
    }
}

#stormStatsTable {
    margin: 0px 3px 19px 3px;
    max-height: 500px;
    overflow: auto;

    .mat-table>.mat-header-row {
        display: flex;
    }

    .mat-table>.mat-row {
        display: flex;
    }

    .mat-table>.mat-header-row>.mat-header-cell {
        color: $black-color;
        background-color: $border-color;
        padding: 0px !important;
        padding-left: 12px !important;
        font-size: 14px;
        font-weight: 500;
        min-width: 118px;
        text-align: center;
        display: grid;
    }

    .mat-table>.mat-row>.mat-cell {
        text-align: center;
    }
}

#qvi-table {
    margin-top: 20px;
    max-height: 500px;
    overflow: auto;

    .mat-table>.mat-header-row {
        display: flex;
    }

    .mat-table>.mat-row {
        display: flex;
    }

    .mat-table>.mat-header-row>.mat-header-cell {
        color: $black-color;
        background-color: $border-color;
        font-size: 14px;
        font-weight: 500;
        padding: 0px 5px !important;
        display: grid;
    }

    .mat-table>.mat-header-row>.mat-header-cell,
    .mat-table>.mat-row>.mat-cell {
        min-width: 100px;
        justify-content: center;
        text-align: center;
        padding: 5px;
    }
}

.export-storm {
    .mat-table {
        .mat-header-row {
            .mat-header-cell:first-child {
                width: 10%;
                padding-top: 0px !important;
            }
        }
    }

    #stormTable {
        margin-left: 23px !important;
        overflow: auto;
        min-width: 344px;
        height: 310px;
        border: 1px solid $border-color;

        .mat-table>.mat-header-row>.mat-header-cell {
            color: $black-color;
            background-color: $border-color;
            padding: 0px !important;
            padding-left: 12px !important;
            position: sticky;
            top: 0;
            font-size: 14px;
            font-weight: 500;
            border: 0px;
            z-index: 1000;
            padding-bottom: 0;
            border-right: 1px solid #ddd;
        }

        .mat-table>.mat-row>.mat-cell {
            border-right: 1px solid #ddd;
        }

        .mat-checkbox-checked.mat-accent .mat-checkbox-background {
            background-color: #263238 !important;
        }

        &.width-90-p {
            width: 90%;
        }
    }

    h3 {
        margin-top: 0px;
        margin-left: 24px;
        padding-top: 11px;
    }

    .min-width {
        min-width: 72px;
    }
}

.constant {
    display: inline-block;
    padding-left: 20px;

    .input {
        width: 75px;
        padding-left: 10px;
        margin-left: 10px;
    }
}

.study-stepper {
    .mat-tab-group {
        .mat-tab-label-container {
            .mat-tab-labels {
                .mat-tab-label:last-child {
                    color: $button-color;
                }
            }
        }
    }
}

#sliicerMarketingDialog {
    width: 513px;
    background: rgba(0, 0, 0, 0.12);
    margin: -15px;

    .mat-dialog-content {
        min-height: 60px;
        max-height: calc(100vh - 215px);
        margin: 0;
        padding: 0 15px;
        padding-bottom: 15px;
        font-size: 14px;
        font-weight: 500;

        ul {
            padding-left: 15px;
        }
    }

    .mat-dialog-actions {
        height: 60px;
        padding: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        @include custom-buttom-material();

        &:last-child {
            margin-bottom: -15px;
        }
    }

    .marketingImg {
        display: flex;
        height: 200px;
        background-image: url('./../images/slicerMarketingScreen.png');
        background-repeat: no-repeat;

        .marketingHeader {
            vertical-align: middle;
            width: 250px;
            margin: auto 0;
            padding: 15px;
            font-weight: 500;

            .big {
                font-size: 25px;
                padding-bottom: 15px;
            }

            .normal {
                font-size: 14px;
            }
        }
    }
}

#advanceHydroGraphId {
    @include custom-conformation();

    .conformationContainer {
        position: fixed;

        .conformationContaint {
            .mat-dialog-actions {
                display: flex;
            }
        }
    }

    .advanceGraph {
        display: flex;
        display: -webkit-flex;
        background: #fff;
    }

    .advanceGraphHeader {
        margin-bottom: 20px;
    }

    .advanceGraphHeader,
    .advanceHydroGraphHeader {
        display: flex;
        min-height: 60px;
        background: #fff;

        .backIcon {
            @include custom-matIcon();
        }

        .headerText {
            font-family: Roboto, 'Helvetica Neue';
            font-size: 16px;
            font-weight: 500;
        }

        .mat-button:not([disabled]) {
            color: #039be5;
        }
    }

    .full-width {
        width: 100% !important;
    }

    .hydroGraphDetailsContainer-hidden {
        display: none;
        position: relative;
        width: 50%;
        border-right: 1px solid rgba(0, 0, 0, 0.12);
    }

    .hydroGraphDetailsContainer {
        display: inline-block;
        position: relative;
        width: 50%;
        border-right: 1px solid rgba(0, 0, 0, 0.12);

        mat-card {
            padding: 0px;
            width: 100%;
            display: inline-block;
            margin: 0;
            box-shadow: none;
        }

        mat-card:last-child() {
            border-bottom: 0px solid #ddd;
        }

        .mat-card.noDataEntities {
            width: 100%;

            >p {
                padding: 1%;
                text-align: center;
            }
        }

        @include active-Widge-Header();

        .activeWidgeHeader {
            font-size: 13px;
            padding: 5px 0;
        }

        .advanceHydroGraphHeader {
            display: flex;
            min-height: 60px;
            background: #fff;

            .backIcon {
                @include custom-matIcon();
            }

            .headerText {
                font-family: Roboto, 'Helvetica Neue';
                font-size: 16px;
                font-weight: 500;
            }
        }
    }

    .scattergraphGraphDetailsContainer-hidden {
        display: none;
        position: relative;
        width: 50%;
        margin-bottom: 20px;
    }

    .scattergraphGraphDetailsContainer {
        display: inline-block;
        position: relative;
        width: 50%;
        margin-bottom: 20px;
    }

    .addNewGraph {
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 9;
    }

    .addNewGraphContainer {
        position: fixed;
        bottom: 56px;
        right: 16px;
        z-index: 9;
        background: #fff;
        width: 300px;
        padding: 15px;
        box-shadow: 0px -1px 10px 9px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 7px 5px 0 rgba(0, 0, 0, 0.12);
    }
}

.small-header-text {
    font-family: Roboto, 'Helvetica Neue';
    font-size: 14px;
    font-weight: 500;
}

.hydrographScale {
    padding-top: 10px;
    background: #f8f8f8;

    .scaleValue {
        display: flex;

        .scaleRange {
            width: 50%;
            max-width: 420px;

            .eachRange {
                padding: 0 15px;
                width: calc(50% - 30px);
            }
        }

        .custom-error {
            .custom-error-message {
                left: 15px;
            }
        }

        .scale-error-message {
            color: #f44336;
            font-size: 12px;
            margin-top: -15px;
            margin-left: 15px;
            max-width: 400px;
        }

        @include custom-buttom-material();
    }
}

.hydrographScaleOptions {
    padding: 10px 0;
    background: #f8f8f8;

    .scaleValue {
        display: flex;

        .eachSection {
            width: calc(100% / 3);
            padding-left: 15px;
            padding-right: 15px;
        }

        @include custom-buttom-material();
    }
}

.edit-graph-filter-section {
    background: #ececec;
    min-height: 60px;

    .bestfit-option {
        flex-wrap: wrap;

        .custom-flag:after {
            content: url('./../images/svgIcon/flag_white.svg');
            top: 5px;
        }

        .custom-unflag:after {
            content: url('./../images/svgIcon/unflag_white.svg');
            top: 5px;
        }

        .custom-magic:after {
            content: url('./../images/svgIcon/magic_white.svg');
            top: 5px;
        }

        .custom-apply:after {
            content: url('./../images/svgIcon/snap_white.svg');
            top: 3.5px;
        }

        .custom-eraser:after {
            content: url('./../images/svgIcon/eraser_white.svg');
            top: 3.5px;
        }

        .custom-drag:after {
            content: url('./../images/svgIcon/drag_white.svg');
            top: 3.5px;
        }
    }

    .bestfit-option {
        background: unset;

        .adjustLine.mat-button[disabled][disabled] {
            color: unset;
            opacity: 0.36;
        }

        .adjustLine {
            position: relative;
            height: 48px;
            margin-top: auto;
            margin-bottom: auto;

            .adjust-line-tool-header {
                position: absolute;
                top: -8px;
                font-size: 11px;
                font-weight: normal;
            }

            .adjust-line-tool-content {
                position: relative;
                top: 4px;
                border-bottom: 2px solid rgba(0, 0, 0, 0.42);
                height: 32px;
                display: flex;
                justify-content: space-between;

                .mat-icon {
                    position: relative;
                    top: 6px;
                }
            }
        }

        .pre-edit-controls {
            button {
                border-left: 0px solid #80808069;
                background: rgba(38, 50, 56, 1);
                border-radius: 50%;
                margin-right: 5px;
                color: rgba(255, 255, 255, 1);
                height: 32px !important;
                line-height: 32px !important;
                min-width: 32px !important;
            }

            .mat-button[disabled][disabled] {
                opacity: 0.36;
            }
        }
    }
}

.mat-icon-button[disabled][disabled],
.mat-button[disabled][disabled] {
    pointer-events: none;
}

app-advance-scattergraph {
    .edit-graph-filter-section {
        .bestfit-option {
            .customFormWidth {
                width: 100px;
                margin-bottom: -15px;
            }
        }

        .pre-edit-controls {
            width: 225px;
            min-width: 225px;
        }
    }
}

.edit-graph-filter-section .bestfit-option button.lightBackGround.mat-button.active {
    opacity: 1;
    box-shadow: 0px 0px 5px 1.5px rgba(0, 0, 0, 0.36);
    background: #263238;
    color: #fff;
}

.hydroGraphDetailsContainer {
    .edit-graph-filter-section {
        display: inline-flex;
        width: 100%;
    }
}

.bestfit-option {
    display: flex;
    padding: 0 15px;
    background: #ececec;
    min-height: 60px;

    .bestFitRange {
        margin: 0;
        height: 60px;
        padding-top: 0 !important;
        width: 110px;
        min-width: 150px;
        max-width: 200px;
        position: relative;

        mat-slider {
            height: 20px;
            top: 26px;
        }

        .mat-slider-horizontal {
            width: 142px;
            min-width: 75px;
        }

        .best-fit-range-label {
            position: absolute;
            top: 10px;
            font-size: 11px;
            font-weight: normal;
            opacity: 0.54;
        }
    }

    .customFormWidth {
        width: 140px;
        margin-bottom: -15px;
    }

    .divider {
        min-height: 60px;
        border-left: 1px solid;
        color: #80808069;
    }

    .adjustLine {
        padding: 0 5px;
        min-width: 150px;
    }

    .pre-edit-controls {
        button {
            border-left: 1px solid #80808069;
            min-width: 37px;
            padding: 0;
        }

        .icon-border {
            border-left: none;
        }

        .icon-rotate {
            transform: rotate(50deg);
        }
    }
}

button.lightBackGround.mat-button.active {
    color: rgba(0, 0, 0, 0.87);
    background: #aaa;
}

.filter-operation {
    background: #ececec;
    padding: 0 10px;

    .filter-action {
        display: flex;

        .mat-button {
            margin-right: 8px;
            color: #2196f3;
            text-transform: uppercase;

            &:hover {
                background: #ddd;
            }
        }
    }
}

#alramGraphDialog {
    @include custom-dialog-material();

    .mat-dialog-content {
        margin: 0px;
    }

    .hideGraphTab {
        .mat-tab-group {
            margin: unset;

            .mat-tab-header {
                display: none;
            }
        }
    }

    .mat-tab-group {
        margin: 0 -15px;

        .mat-tab-header {
            background: #263238;
            text-transform: uppercase;

            .mat-tab-label {
                width: 50%;
                color: #fff;
                font-size: 16px;
                font-weight: bold;
            }

            .mat-tab-label-active {
                opacity: 1;
                background: #263238;
            }

            .mat-ink-bar {
                background-color: #fff;
                height: 3px;
            }
        }
    }

    .graphPopupContainer {
        padding-top: 27px;
        display: table;
        width: 100%;

        .graphContaint {
            display: table-cell;
        }

        .previousButton,
        .nextButton {
            display: table-cell;
            vertical-align: middle;
            width: 60px;

            button.mat-button {
                min-width: 60px;
                padding: 0px;
                height: 300px;

                .mat-icon {
                    font-size: 60px;
                    width: 60px;
                }
            }
        }

        .previousButton {
            left: 0;
        }

        .nextButton {
            right: 0;
        }
    }

    .WidgetFilter {
        width: auto;
        padding-top: 10px;
        margin-left: 0px;
        margin-right: 0px;
        background: #ffffff;
        color: rgba(0, 0, 0, 0.74);

        mat-checkbox {
            margin-right: 10px;
        }

        .mat-card-subtitle {
            font-size: 14px;
            margin: 5px 0;
        }
    }
}

#assistanceCenterDialog {
    width: 600px;
    @include custom-dialog-material();

    textarea.mat-input-element {
        resize: none;
    }
}

#dataCollectionDialog {
    @include custom-dialog-material();

    .mat-dialog-content {
        width: 550px;
    }
}

#vaultTelemetryExportDialog {
    @include custom-dialog-material();
    overflow: unset;

    .mat-dialog-content {
        width: 550px;
    }

    .noFlexWrap {
        position: relative;
    }

    .date-range-section {
        width: 100%;

        .eachDateSection {
            width: 45%;
        }
    }

    app-multi-select-group {
        .multi-select-group-list {
            position: fixed;
        }
    }
}

#vaultTelemetryDialog {
    @include custom-dialog-material();
    overflow: unset;

    .mat-dialog-content {
        width: 100%;
    }
}

#fileViewDialog {
    @include custom-dialog-material();
    overflow: unset;
}

#vaultShareDialog {
    @include custom-dialog-material();
    overflow: unset;
}

#createFolderDialog {
    @include custom-dialog-material();

    .mat-dialog-content {
        width: 550px;
    }
}

#vaultFileUploadDialog,
#vaultLocationUploadDialog {
    @include custom-dialog-material();
    overflow: unset;
}

#customerAssociationDialog {
    @include custom-dialog-material();
}

#editWetDryDialog {
    @include custom-dialog-material();
}

#kml-add-edit-dialog {
    @include custom-dialog-material();

    .file-over {
        background: darkgray;
    }

    .upload-text {
        text-align: center;
        padding: 25px;

        #kmlfiles {
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            width: 0.1px;
            z-index: -1;

            &+label {
                color: #2196f3;
                cursor: pointer;
                display: block;
                font-size: 1.25em;
                font-weight: 700;
                height: 80%;

                .uploadIcon {
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: auto;
                    -webkit-box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.12);
                    -moz-box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.12);
                    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.12);

                    .mat-icon {
                        height: 50px;
                        width: 50px;
                        font-size: 50px;
                    }
                }
            }
        }

        .uploadMessage {
            margin-top: 10px;
            font-size: 1.2rem;
        }
    }

    .layer-details {
        padding: 10px 0;

        .file-name {
            font-weight: 600;
        }
    }
}

#resetPasswordDialog {
    @include custom-dialog-material();

    .mat-dialog-content {
        min-width: 250px;
        width: 310px;
        min-height: 60px;
        padding-bottom: 15px;
    }
}

#flow-balance-report {}

#note-details-dialog,
.load-setting {
    @include custom-dialog-material();
}

#flow-balance-dialog {
    padding: 0 15px;

    .each-flow {
        display: flex;
        justify-content: space-between;

        .each-item {
            width: calc((100% - 90px) / 2);
        }

        .action {
            margin: auto 0;
        }

        &:first-child {
            padding-top: 15px;
        }
    }

    .mat-card-actions {
        display: flex;
        margin: 0;
    }
}

#plotting-confirmation-dialog {
    @include custom-dialog-material();

    .add-more {
        display: flex;
        margin-left: -15px;
        margin-right: -15px;
    }
}

#calculatedEntityDialog {
    padding: 0 15px;
    padding-bottom: 0px;

    .small-text {
        color: #969696;
        font-weight: 500;
    }

    .formula-containt {
        width: 100%;

        .formula-selection {
            min-width: 150px;
            width: calc((100% - 120px) / 3);
            display: inline-block;
        }

        .formula-info {
            color: #64b5f6;
            display: inline-block;
        }
    }

    .validate-button {
        color: #2196f3;
    }

    .add-icon-position {
        position: absolute;
        bottom: -28px;
        right: 2px;
    }

    .formula-top-space {
        margin-top: 30px;
    }

    .entityVariableContainer {
        border: 1px solid #ddd;
        position: relative;

        .eachRow {
            width: 100%;
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: space-between;
            justify-content: space-between;

            .containtCell {
                padding: 0 15px;
                width: calc((100% - 150px) / 3);
            }

            .header {
                padding-top: 15px;
                padding-bottom: 15px;
            }

            .actionCell {
                width: 60px;

                .mat-button {
                    padding: 0 8px;
                    min-width: 40px;
                }
            }
        }

        .eachRow:nth-child(2) {
            padding-top: 10px;
        }

        .top-border {
            border-top: 1px solid #ddd;
        }

        .bottom-border {
            border-bottom: 1px solid #ddd;
        }
    }

    .mat-card-actions {
        display: flex;
        margin-bottom: 0px;
    }
}

.custom-50p-group {
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    flex-wrap: wrap;

    .each-containt {
        padding: 0 15px;
        min-width: 150px;
        width: calc((100% - 60px) / 2);
    }
}

.custom-33p-group {
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    flex-wrap: wrap;

    .each-containt {
        padding: 0 15px;
        min-width: 150px;
        width: calc((100% - 120px) / 3);
    }
}

#customerFeatureDialog {
    @include custom-dialog-material();

    .customefeatureList {
        padding: 15px;

        .eachItem {
            display: inline-block;
            width: 100%;
            line-height: 2.5;

            .mat-checkbox-layout {
                white-space: unset;
            }
        }
    }
}

.assignEachFeature {
    margin: 15px;

    .assignDescription {
        font-size: 12px;
        margin-left: 28px;
    }
}

#note-details-dialog {
    .mat-dialog-content {
        padding-top: 10px;
    }

    .customCheck.mat-option.mat-option-multiple {
        color: #5cb259;
    }
}

#aboutUsDialog {
    background: #fff;

    .mat-dialog-title {
        text-align: center;
        padding-top: 20px;

        .mediumHeaderText {
            font-size: medium;
        }
    }

    .mat-dialog-content {
        min-height: 90px;
        margin: 0 -15px;
        border-top: 1px solid #ddd;

        .buildDetails {
            display: table;
            width: 100%;

            .eachContent {
                display: table-cell;
                text-align: center;
                padding: 24px 0;
                padding-right: 24px;
                border-right: 1px solid #ddd;

                &:last-child {
                    border-right: 0px solid #ddd;
                    padding-right: 0px;
                    padding-left: 24px;
                }

                .buildNumerText {
                    font-weight: bold;
                    font-size: larger;
                }

                .buildTypeText {
                    opacity: 0.5;
                    font-weight: bold;
                }
            }
        }
    }

    .mat-dialog-actions {
        margin-left: -15px;
        margin-right: -15px;

        &:last-child {
            margin-bottom: -15px;
        }

        .mat-button {
            margin-right: 8px;
            color: #2196f3;
            text-transform: uppercase;

            &:hover {
                background: #ddd;
            }
        }
    }
}

// Report Filter
#showDailySummaryReportFilterId,
#percentFullReportFilterId,
#uptimeFilterId,
#auditLogWidgetId {
    padding: 0 15px;
}

form#widgetFiltersForm {
    background: #f8f8f8;
    margin-left: -15px;
    padding: 20px 15px;
}

.absoluteError {
    position: absolute;
    font-size: 12px;
    bottom: 0px;
    left: 0px;
}

input.mat-input-element::-ms-clear {
    display: none;
}

//Css Changes For ipad
@media (max-width: 1200px) {
    .flex-container.layout-row.mapWidgetContainer.no-wrap {
        flex-direction: column;

        mat-card.mapSection {
            width: unset;
        }

        .widgetSection {
            margin-left: 0;
        }
    }
}

.overflow-wrap {
    overflow-wrap: break-word;
}

.word-break {
    word-break: break-all;
}

//ipad Style Changes
@media (max-width: 1199px) {
    .ipadWidgetMapView {
        display: block;

        mat-card.mapSection {
            width: auto;
        }

        mat-card.widgetSection {
            margin-left: 0px;
            width: auto;

            .mat-card-content {
                height: unset;
                min-height: 300px;
            }
        }

        #widgetFilters {
            max-width: calc(100% - 15px);
        }
    }

    .hideOnIpad {
        // overwrite the property for iPad
        display: none !important;
    }

    .customLongName {
        word-break: break-all;
    }

    #locationGroupSelect {
        .mat-form-field-infix {
            width: 140px;
        }
    }

    #topHeaderSection {
        .top-header-each-item {
            padding: 0 10px;
        }

        .hint-action {
            width: unset;
        }
    }
}

button.scroll-to-up-down.mat-raised-button {
    padding: 0;
    min-width: 40px;
    position: fixed;
    bottom: 0;
    right: 0;
    background: #263238;
    color: #fff;
    display: none;
}

// overwrite the property for showing larger graph
.cdk-overlay-pane {
    max-width: calc(100vw - 5px) !important;
}

.cdk-overlay-container {
    .mat-dialog-container {
        max-width: calc(100vw - 5px);
    }
}

@media #{$ipad-screen-and-down} {

    // overwrite the property for iPad
    .cdk-overlay-pane {
        max-width: calc(100vw - 5px) !important;
    }

    .cdk-overlay-container {
        .mat-dialog-container {
            max-width: calc(100vw - 5px);
        }
    }

    button.scroll-to-up-down.mat-raised-button {
        display: block;
    }

    #location-dashboard {
        .graphContent.iPadView {
            display: block;

            .mat-card {
                // overwrite the property for iPad
                width: auto !important;
                margin-left: 0 !important;
                margin-right: 0 !important;
            }
        }
    }
}

.dynamicGraph {
    @include full-width-height();
}

.newHydro {
    height: 600px;
}

.scattergraphGraphDetailsContainer {
    .dynamicGraph {
        height: 600px;
    }
}

#location-dashboard {
    app-dynamic-highchart {
        width: 100%;
        display: inline-block;
        margin-right: 10px;

        .dynamicContent {
            width: 100%;
            background: #fff;

            .dynamicContentHeader {
                display: flex;
                padding: 15px;
                font-family: Roboto, 'Helvetica Neue';
                font-size: 16px;
                font-weight: 500;
                text-transform: uppercase;

                span {
                    margin-right: 10px;
                }

                span:last-child {
                    margin-right: 0px;
                    width: 65px;
                    text-align: right;
                }

                mat-icon {
                    cursor: pointer;
                    opacity: 0.54;

                    &:hover {
                        opacity: 0.87;
                    }

                    &.active {
                        opacity: 0.87;
                    }
                }
            }

            .dynamicgraphContent {
                .mat-card {
                    width: 100%;
                    margin: 0px;
                    padding: 0px;
                    display: block;
                }

                .hydroGraphDetailsContainer {
                    width: 100%;
                    border-right: 0px solid #ddd;
                }

                .scattergraphGraphDetailsContainer {
                    width: 50%;
                }
            }
        }
    }
}

div#location-dashboard.fullWidth app-dynamic-highchart {
    width: 100%;
    margin: 0px;
}

.dynamic-dashboard-label {
    text-align: center;
    vertical-align: middle;
    line-height: 90px;
}

.highcharts-contextmenu,
.highcharts-menu {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.csvHeader {
    display: flex;
    justify-content: flex-end;
}

.exportCSVButton {
    &.mat-button {
        margin-right: 8px;
        font-size: 14px;
        min-width: unset;
        padding: 0 10px;
        color: #2196f3;
        text-transform: uppercase;
        line-height: 36px;
        height: 36px;
        margin-bottom: 20px;
    }

    &.mat-button[disabled][disabled] {
        color: rgba(0, 0, 0, 0.38);
    }
}

.right-side-button {
    display: flex;
    @include custom-buttom-material();
}

.sub-header-section {
    display: flex;
    min-height: 60px;
    @include custom-buttom-material();
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.error {
    color: #f44336;
    padding: 0px;
    font-size: 12px;
    margin: 0;
}

.horizontal-tab-container {
    .mat-tab-group {
        &.mat-primary {
            .mat-tab-labels {
                .mat-tab-label-active {
                    background-color: rgba(184, 201, 207, 0.3);
                    border-bottom: 2px solid #5cb259;
                    border-left: none;
                }
            }
        }
    }

    .mat-ink-bar {
        background-color: transparent !important;
    }
}

#DetailsDialog {
    p {
        font-size: 14px;
        padding-left: 13px;
    }

    .dialog-body {
        max-height: 440px;
        overflow: auto;
    }

    .details-thead {
        border: 1px solid #ddd;
        padding: 10px;
        border-left: 0;
        border-right: 0;

        &.border-right:after {
            width: 1px;
            height: 22px;
            position: relative;
            background: #bcb4b4;
            content: '';
            float: right;
        }
    }
}

.mat-primary .mat-pseudo-checkbox-checked {
    background: #5cb258 !important;
}

.mat-primary .mat-pseudo-checkbox-disabled {
    background: #b0b0b0 !important;
}

.mat-button.black {
    color: #000;
}

.green-icon {
    color: #5cb85c;
    font-size: 26px !important;
}

.grey-icon {
    color: #999999;
    font-size: 26px !important;
    padding-top: 5px;
}

.yellow-icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-image: url(#{$assetPath}/warning_icon.svg);
}

.red-icon {
    color: #fff;
    background: #f44336;
    border-radius: 5px;
    text-align: center;
    line-height: 24px;
}

.width-250 {
    width: 250px;
}

ul.list {
    color: rgba(0, 0, 0, 0.71);
}

#customer-edit-ddf-graph {
    .highcharts-data-labels .highcharts-label:last-child {
        display: block;
    }
}

@media (max-height: 800px) {
    .basin-wrapper .tab {
        min-height: 330px;
        max-height: calc(100vh - 190px);
    }
}

#location-flow-balance-graph {
    .highcharts-scrollbar {
        display: none !important;
    }
}

.move-icon {
    height: 18px;
    cursor: pointer;
}

mcc-color-picker-option {
    button {
        border: none;
    }
}
