mat-card {
    margin-bottom: 20px;

    &.card-toolbar {
        padding: 0;

        > mat-card-content {
            padding: 24px;
        }
    }

    &.full-card {
        width: 100%;
        height: 100%;
        padding: 0;
        > mat-nav-list {
            padding: 0;
        }
    }
}

/*.mat-card:not([class*=mat-elevation-z]), mat-dialog-container .mat-tab-body .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: none !important;
  margin-bottom: 0;

  &:hover {
    box-shadow: none;
  }
}*/

.card .card-content.widget {
    padding: 10px 0 10px 15px;
    & .widget-icon {
        position: absolute;
        right: 30px;
        font-size: 42px;
        top: 30px;
        overflow: hidden;
        vertical-align: middle;
        height: 80px;
        width: 80px;
        text-align: center;
        line-height: 80px;
        border-radius: 50%;
        border: 2px solid;
    }
}

mat-card.widget {
    margin: 8px;
    padding: 16px;

    @media #{$medium-only} {
        min-width: 33%;
    }

    .widget-icon {
        height: auto;
        line-height: 1;
        font-size: 5rem;
    }

    p.counter {
        font-weight: 100;
        font-size: 55px;
        line-height: 56px;
        margin: 0;
    }
    p.description {
        margin: 0;
        text-align: right;
    }
}

//Card header on reports' details pages
.reportDetailsCardTitle {
    font-size: 1rem;
    align-items: center;
    padding: 10px 0;
}

.reportDetailsCardTitle .mat-icon {
    cursor: pointer;
    padding-right: 10px;
}

//Bold text in the card title on report details pages
.reportDetailsCardTitle .strong {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.87);
}
