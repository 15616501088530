// Utility Color Classes

//.success {
//
//}

// Google Color Palette defined: http://www.google.com/design/spec/style/color.html

$primary: (
    'base': $primary-color,
);

$colors: (
    'primary': $primary,
);

// Color Classes

@each $color_name, $color in $colors {
    @each $color_type, $color_value in $color {
        @if $color_type == 'base' {
            .#{$color_name} {
                background-color: $color_value !important;
            }
            .#{$color_name}-text {
                color: $color_value !important;
            }
        } @else if $color_name != 'shades' {
            .#{$color_name}.#{$color_type} {
                background-color: $color_value !important;
            }
            .#{$color_name}-text.text-#{$color_type} {
                color: $color_value !important;
            }
        }
    }
}

// usage: color("name_of_color", "type_of_color")
// to avoid to repeating map-get($colors, ...)

@function color($color, $type) {
    @if map-has-key($colors, $color) {
        $curr_color: map-get($colors, $color);
        @if map-has-key($curr_color, $type) {
            @return map-get($curr_color, $type);
        }
    }
    @warn "Unknown `#{name}` in $colors.";
    @return null;
}
