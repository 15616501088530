.customDateTimePickerIcon {
    position: absolute;
    right: 0;
}
.owl-dt-container-buttons {
    color: #2196f3 !important;
}
.owl-dt-popup-container .owl-dt-timer-content .owl-dt-timer-input {
    width: 2.7em;
    font-size: 0.8em;
    padding: 0.5em;
}
.owl-dt-popup-container .owl-dt-timer-hour12 .owl-dt-timer-hour12-box .owl-dt-control-button-content {
    padding: 0.5em;
    font-size: 0.8em;
}
.owl-dt-inline-container .owl-dt-calendar,
.owl-dt-popup-container .owl-dt-calendar {
    height: auto !important;
}
.owl-dt-popup-container .owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-calendar-table-divider {
    padding: 0;
}
.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
    background-color: #114b5f !important;
}
