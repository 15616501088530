.analyticSvgIcon:after {
    content: url('./../images/svgIcon/analytics.svg');
    width: 19px;
    height: 25px;
    display: inline-block;
    position: relative;
    top: 5px;
    padding-right: 4px;
}
.custom-in:before {
    content: url('./../images/svgIcon/in.svg');
    width: 22px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: 5px;
}
.custom-out:before {
    content: url('./../images/svgIcon/out.svg');
    width: 22px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: 5px;
}
.custom-magic:after {
    content: url('./../images/svgIcon/magic.svg');
    width: 22px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: 8px;
}
.custom-up:before {
    content: url('./../images/svgIcon/up.svg');
    width: 22px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: 5px;
}
.custom-down:before {
    content: url('./../images/svgIcon/down.svg');
    width: 22px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: 5px;
}
.custom-flag:after {
    content: url('./../images/svgIcon/flag.svg');
    width: 22px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: 8px;
}
.custom-unflag:after {
    content: url('./../images/svgIcon/unflag.svg');
    width: 22px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: 8px;
}
.custom-apply:after {
    content: url('./../images/svgIcon/snap.svg');
    width: 18px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: 8px;
}
.custom-strom:before {
    content: url('./../images/svgIcon/icon_remove_storm.svg');
    width: 28px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: 1px;
    border-right: 2px solid white;
    padding-top: 5px;
    padding-right: 13px;
    vertical-align: middle;
}
.custom-eraser:after {
    content: url('./../images/svgIcon/eraser.svg');
    width: 18px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: 8px;
}
.custom-eraser-white:after {
    content: url('./../images/svgIcon/eraser_white.svg');
    width: 18px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: 4px;
}
.custom-drag:after {
    content: url('./../images/svgIcon/drag.svg');
    width: 18px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: 8px;
}
.customer-edit-svg:after {
    content: url('./../images/svgIcon/customer_edit.svg');
    width: 28px;
    display: inline-block;
    vertical-align: middle;
    text-align: right;
    padding-right: 2px;
    position: relative;
}
.location-header-svg:after {
    content: url('./../images/svgIcon/location_header.svg');
    width: 28px;
    display: inline-block;
    vertical-align: middle;
    text-align: right;
    padding-right: 2px;
    position: relative;
}

.mat-button[disabled][disabled].custom-flag:after {
    opacity: 1;
}
.mat-button[disabled][disabled].custom-unflag:after {
    opacity: 1;
}
.mat-button[disabled][disabled].custom-apply:after {
    opacity: 1;
}
.mat-button[disabled][disabled].custom-eraser:after {
    opacity: 1;
}
