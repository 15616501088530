table,
td,
th {
    border: none;
}

table {
    width: 100%;
    display: table;
}

table.bordered > tbody > tr,
table.bordered > thead > tr {
    border-bottom: 1px solid #d0d0d0;
}

table.striped > tbody > tr:nth-child(odd) {
    background-color: #f2f2f2;
}

table.striped > tbody > tr > td {
    border-radius: 0;
}

table.highlight > tbody > tr {
    transition: background-color 0.25s ease;
}

table.highlight > tbody > tr:hover {
    background-color: #f2f2f2;
}

table.centered tbody tr td,
table.centered thead tr th {
    text-align: center;
}

thead {
    border-bottom: 1px solid #d0d0d0;
}

td,
th {
    padding: 9px 13px;
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    border-radius: 2px;
}

@media only screen and (max-width: 992px) {
    table {
        overflow-x: scroll;
    }

    table.responsive-table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        display: block;
        position: relative;
    }

    table.responsive-table td:empty:before {
        content: '\A0';
    }

    table.responsive-table td,
    table.responsive-table th {
        margin: 0;
        vertical-align: top;
    }

    table.responsive-table th {
        text-align: left;
    }

    table.responsive-table thead {
        display: block;
        float: left;
    }

    table.responsive-table thead tr {
        display: block;
        padding: 0 10px 0 0;
    }

    table.responsive-table thead tr th:before {
        content: '\A0';
    }

    table.responsive-table tbody {
        display: block;
        width: auto;
        position: relative;
        overflow-x: auto;
        white-space: nowrap;
    }

    table.responsive-table tbody tr {
        display: inline-block;
        vertical-align: top;
    }

    table.responsive-table th {
        display: block;
        text-align: right;
    }

    table.responsive-table td {
        display: block;
        min-height: 1.25em;
        text-align: left;
    }

    table.responsive-table tr {
        padding: 0 10px;
    }

    table.responsive-table thead {
        border: 0;
        border-right: 1px solid #d0d0d0;
    }

    table.responsive-table.bordered th {
        border-bottom: 0;
        border-left: 0;
    }

    table.responsive-table.bordered td {
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
    }

    table.responsive-table.bordered tr {
        border: 0;
    }

    table.responsive-table.bordered tbody tr {
        border-right: 1px solid #d0d0d0;
    }
}
.tableScrollWraper {
    overflow-y: auto;
    width: calc(100% + 1px);
    padding-right: 1px;
}
