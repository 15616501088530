#dashboard {
    //border: 1px solid rgba(0, 0, 0, 0.16);
}

#dashboard thead {
    font-weight: bold;
    font-size: 12px;
}

#dashboard .mat-chip {
    display: inline-block;
    border-radius: 36px;
    height: 36px;
    //border-radius: 42px;
    padding: 0;
    //height: 42px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    -webkit-transition: 0.2s ease-out;
    transition: 0.2s ease-out;
}

#dashboard .mat-chip:hover {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;
}

#dashboard .mat-chip-img {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    //width: 42px;
    //height: 42px;
    overflow: hidden;
    float: left;
    //box-shadow: 2px 0px rgba(0,0,0,.3);
}

#dashboard .mat-chip-img .mat-chip-span {
    display: block;
    //height: 42px;
    height: 36px;
    color: #fff;
    padding-top: 12px;
    font-size: 11px;
    text-align: center;
}

#dashboard .mat-chip-img img {
    -webkit-transform: translate(-50%);
    margin-left: 21px;
    //height: 42px;
    height: 36px;
}

#dashboard .mat-chip-text {
    display: inline-block;
    font-weight: 400;
    //font-size: 16px;
    //height: 42px;
    float: left;
    padding: 10px 12px 12px 10px;
    //padding: 12px 18px 12px 10px;
    color: rgba(255, 255, 255, 0.87);
}

/* Colors */
#dashboard {
    .pink {
        background-color: #e91e63;
    }

    .orange {
        background-color: #ff9800;
    }

    .indigo {
        background-color: #3f51b5;
    }

    .teal {
        background-color: #009688;
    }

    .purple {
        background-color: #9c27b0;
    }

    .deep-purple {
        background-color: #512da8;
    }

    .white {
        background-color: #fff;
    }

    .white .mat-chip-text {
        color: rgba(0, 0, 0, 0.87);
    }

    .red {
        background-color: #f44336;
    }

    .blue {
        background-color: #2196f3;
    }

    .green {
        background-color: #43a047;
    }

    .darkgreen {
        background-color: #1b5e20;
    }

    .darkred {
        background-color: #b71c1c;
    }

    .darkorange {
        background-color: #e65100;
    }

    #dashboard .collapsed {
        width: 85%;
    }
}

#dashboard .expanded {
    height: 380px;
    width: 100%;
    display: block;
    overflow-y: scroll;
}

#dashboard ::-webkit-scrollbar {
    width: 10px;
}

#dashboard ::-webkit-scrollbar-track {
    background-color: #e5e5e5;
} /* the new scrollbar will have a flat appearance with the set background color */

#dashboard ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
} /* this will style the thumb, ignoring the track */

/*#dashboard ::-webkit-scrollbar-button {
      background-color: #cccccc;
} */ /* optionally, you can style the top and the bottom buttons (left and right for horizontal bars) */

#dashboard ::-webkit-scrollbar-corner {
    background-color: black;
}

#dashboard .noVerticalPadding td {
    padding: 0 5px;
}

.jump-scroll-btn {
    .highcharts-button-box {
        fill: none;
        height: 32px;
    }
    text {
        font-weight: bold !important;
        font-size: 1.5rem;
    }
    &.disabled {
        text {
            cursor: default !important;
            fill: rgba(0, 0, 0, 0.36) !important;
        }
    }
}
.tooltip-btn-off {
    .highcharts-button-box {
        fill: #e0e0e0;
    }
    text {
        fill: #0e0e0e !important;
        font-weight: bold !important;
    }
}
.tooltip-btn-on {
    .highcharts-button-box {
        fill: #5cb259;
    }
    text {
        fill: #ffffff !important;
        font-weight: bold !important;
    }
}
.tooltip-lbl {
    .highcharts-button-box {
        fill: none;
    }
    text {
        cursor: none !important;
    }
}
